/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-company-form",
  formField: {
    company_name: {
      name: "company_name",
      label: "Company Name",
      type: "text",
      errorMsg: "The name is required.",
    },
    contact_name: {
      name: "contact_name",
      label: "Contact Name",
      type: "text",
    },
    contact_email: {
      name: "contact_email",
      label: "Contact Email",
      type: "email",
      invalidMsg: "The email is invalid"
    },
    contact_phone: {
      name: "contact_phone",
      label: "Contact Phone",
      type: "text",
    },
    address: {
      name: "address",
      label: "Street Address",
      type: "text",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
    },
    zip_code: {
      name: "zip_code",
      label: "ZIP Code",
      type: "text",
    },
  },
};

export default form;
