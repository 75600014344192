import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid";
import Footer from "../../../examples/Footer";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import MDTypography from "../../../components/MDTypography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Icon from "@mui/material/Icon";

const Care = () => {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <MDBox mb={3}>
                    <MDTypography variant="h4">Wefix Care</MDTypography>
                    <MDTypography variant="body2">Frequently Asked Questions</MDTypography>
                </MDBox>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <MDTypography variant="h6">What solar energy rebates and incentives are available? </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MDTypography variant="body2">
                                    Solar rebates and incentives vary depending on where you live. The most significant is the 30
                                    percent federal investment tax credit (ITC), which allows you to deduct 30 percent of the cost of
                                    your solar energy system from your taxes. Some states offer additional tax credits, and certain
                                    municipalities and utilities also offer cash rebates or other incentives.
                                </MDTypography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item sm={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <MDTypography variant="h6">How do I fill out the Federal Investment Tax Credit (ITC) Form-5695?</MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MDTypography variant="body2">
                                    The Residential Clean Energy Credit is a big incentive for people to adopt any number of
                                    energy-efficient systems on their property. Claiming the solar energy tax credit can offset
                                    some of the expenses of making energy-efficient improvements to your home.
                                    Review the IRS Form 5695 Instructions at <a href="https://www.irs.gov/forms-pubs/about-form5695">https://www.irs.gov/forms-pubs/about-form5695</a> to confirm your eligibility. You can then complete the form and include it on Form
                                    1040.
                                </MDTypography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item sm={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <MDTypography variant="h6">What is a Permission to Operate (PTO)?</MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MDTypography variant="body2">
                                    This is a form that is filled out with your transmission and distribution provider [TDSP]
                                    (CenterPoint, Oncor, AEP, TNMP) and allows you to sell back any excess energy to a retail electric
                                    provider.
                                </MDTypography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item sm={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <MDTypography variant="h6">I am getting 2 bills. How do I fix that? </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MDTypography variant="body2">
                                    If you are getting two bills, it means that you probably have not switched your electric plan to a
                                    solar plan. Solar buyback electric plans can be beneficial for solar panel owners as they provide a
                                    financial incentive for generating renewable energy and can help offset the initial investment in
                                    solar panels. However, it's important to review the terms and rates of the specific plan offered by
                                    your utility company to assess its suitability and potential savings for your situation.
                                </MDTypography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Care;
