/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {type, folio, client_name, service_address, terms, due_at, company_id, invoice_items},
} = checkout;

const validations = Yup.object().shape({
    [type.name]: Yup.number().required(type.errorMsg).positive(type.errorMsg),
    [folio.name]: Yup.string().required(folio.errorMsg),
    [client_name.name]: Yup.string().required(client_name.errorMsg),
    [service_address.name]: Yup.string().required(service_address.errorMsg),
    [terms.name]: Yup.string().required(terms.errorMsg),
    [due_at.name]: Yup.date().required(due_at.errorMsg),
    [company_id.name]: Yup.number().required(company_id.errorMsg).positive(company_id.invalidMsg),
    [invoice_items.name]: Yup.array().of(
        Yup.object()
            .shape({
                description: Yup.string().required(invoice_items.formField.description.errorMsg),
                unit_price: Yup.number().required(invoice_items.formField.unit_price.errorMsg).moreThan(0, invoice_items.formField.unit_price.invalidMsg),
                amount: Yup.number().required(invoice_items.formField.amount.errorMsg).moreThan(0, invoice_items.formField.amount.invalidMsg),
                invoice_item_id: Yup.number().required(invoice_items.formField.invoice_item_id.errorMsg).positive(invoice_items.formField.invoice_item_id.invalidMsg)
            })
            .required(invoice_items.errorMsg)
    )
});

export default validations;
