/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";

const {
    formField: {lead_id, module_quantity, module_size, total, has_secondary_seller, user_id, down_payment, utility_rebate, ad_bonus, finance_fee, bank_id, apr_rate, total_with_adders, adder_1, adder_2, adder_3, adder_4, invoice_item_id},
} = checkout;

const validations = Yup.object().shape({
    [lead_id.name]: Yup.string().nullable().required(lead_id.errorMsg),
    [module_size.name]: Yup.number().required(module_size.errorMsg).moreThan(0, module_size.invalidMsg),
    [module_quantity.name]: Yup.number().required(module_quantity.errorMsg).moreThan(0, module_quantity.invalidMsg),
    [total.name]: Yup.number().required(total.errorMsg).moreThan(0, total.invalidMsg),
    [has_secondary_seller.name]: Yup.boolean(),
    [user_id.name]: Yup.number().when("has_secondary_seller", {
        is: true,
        then: Yup.number().positive(user_id.errorMsg)
    }),
    [down_payment.name]: Yup.number().required(down_payment.errorMsg),
    [utility_rebate.name]: Yup.number().required(utility_rebate.errorMsg),
    [ad_bonus.name]: Yup.number().required(ad_bonus.errorMsg),
    [bank_id.name]: Yup.number().required(bank_id.errorMsg).positive(bank_id.errorMsg),
    [finance_fee.name]: Yup.number().required(finance_fee.errorMsg).moreThan(0, finance_fee.invalidMsg),
    [apr_rate.name]: Yup.number().required(apr_rate.errorMsg).moreThan(0, apr_rate.invalidMsg),
    [total_with_adders.name]: Yup.number().required(total_with_adders.errorMsg).moreThan(0, total_with_adders.invalidMsg),
    [adder_1.name]: Yup.number().required(adder_1.errorMsg),
    [adder_2.name]: Yup.number().required(adder_2.errorMsg),
    [adder_3.name]: Yup.number().required(adder_3.errorMsg),
});

export default validations;
