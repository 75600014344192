/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// @mui icons
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// Images
import Header from "./components/Header";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../config";
import moment from "moment";
import {useParams} from 'react-router-dom';
import {renderAvatarInitials} from "../../../../helpers/helpers";
import MDAvatar from "../../../../components/MDAvatar";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import NewEventForm from "./components/NewEventForm/NewEventForm";
import {Form, Formik} from "formik";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import form from "./schemas/form";
import {useSelector} from "react-redux";
import MDSnackbar from "../../../../components/MDSnackbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import Overview from "./components/Overview/Overview";
import Comissions from "./components/Comissions/Comissions";

const EventItem = ({user, content, date}) => {

    return <MDBox>
        <MDBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <MDAvatar bgColor="primary" alt="profile-image" size="md"
                      shadow="sm">{renderAvatarInitials(user)}</MDAvatar>
            <MDBox ml={2}>
                <MDTypography variant="h6" mb={-1}>{user}</MDTypography>
                <MDTypography variant="button" fontWeight="regular">{date.fromNow()}</MDTypography>
            </MDBox>
        </MDBox>
        <MDTypography variant="button" ml={8}>{content}</MDTypography>
        <Divider/>
    </MDBox>
};

function SaleDetails() {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const {id} = useParams();

    const [sale, setSale] = useState(null);
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [shouldShowNoteForm, setShouldShowNoteForm] = useState(false);

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    const {formId, formField} = form;
    const currentValidation = validations;

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const init = async () => {
        setIsLoading(true);
        await getSaleData(`${API_URL}/sales/${id}`);
        await getSaleEvents(`${API_URL}/events`);
        setIsLoading(false);
    };

    useEffect(() => {

        init();

    }, []);

    const loadSaleData = async (url) => {

        const response = await axios.get(url);

        return {
            data: response.data
        };

    };

    const getSaleData = async (url) => {


        const response = await loadSaleData(url);

        setSale(response.data);


    };

    const getSaleEvents = async (url) => {


        const response = await axios.get(url, {
            params: {
                id: id,
                type: 'sale'
            }
        });

        setEvents(response.data);

    };

    const handleEventSubmit = async (values, actions) => {

        try {

            let newValues = {...values};
            newValues.type = 'sale';
            newValues.id = id;

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/events`,
                newValues
            );

            actions.resetForm();

            let newEvents = [...events];
            newEvents.unshift(response.data);
            setEvents(newEvents);

        } catch (error) {
            console.log(error);

        }

        actions.setSubmitting(false);

    };

    const renderEvents = () => {
        return events.map(event => <EventItem user={event.user ? event.user.name : 'System'} content={event.description}
                                              date={moment(event.created_at)}/>)
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            {
                isLoading ?
                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                           alignItems="center">
                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                    </MDBox> :
                <>
                    <Grid container>
                        <Grid item xs={12} sm={8} lg={4}>
                            <AppBar position="static">
                                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                                    <Tab label="Overview"/>
                                    <Tab label="Comissions"/>
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                    {
                        tabValue === 0 &&
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={12} md={12}>
                                <Overview sale={sale} isLoading={isLoading} handleLoadingChange={setIsLoading} handleReload={init}/>
                            </Grid>
                        </Grid>
                    }
                    {
                        tabValue === 1 &&
                        <Grid container justifyContent="center" mt={2}>
                            <Grid item xs={12} sm={12} md={8}>
                                <Comissions sale={sale} />
                            </Grid>
                        </Grid>
                    }
                    <MDBox sx={{
                        position: "relative",
                        mt: 0,
                        mx: 3,
                        mb: 5,
                        py: 0,
                        px: 2,
                    }}>
                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <MDTypography variant="h5" mb={2}>Timeline</MDTypography>
                        </MDBox>
                        <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleEventSubmit}
                            >
                                {({values, errors, touched, isSubmitting}) => (
                                    <Form id={formId} autoComplete="off">
                                        <MDBox>
                                            <NewEventForm formData={{values, touched, formField, errors}} />
                                            <MDBox mt={2} width="100%" display="flex"
                                                   justifyContent="flex-end">
                                                <MDBox>
                                                    <MDButton
                                                        disabled={isSubmitting}
                                                        variant="gradient"
                                                        color="secondary"
                                                        type="submit"
                                                    >
                                                        {isSubmitting &&
                                                        <CircularProgress size={18} color="white"
                                                                          sx={{marginRight: 1}}/>}
                                                        Add Note
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>
                        {
                            renderEvents()
                        }
                    </MDBox>
                </>
            }
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
}

export default SaleDetails;
