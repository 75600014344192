/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import moment from "moment";

const NextEvents = ({data}) => {

    const renderEvents = () => {
        return data.map((event, index) => (
            <MDBox mb={index !== data.length - 1 ? 3.5 : 0} key={event.id}>
                <DefaultItem
                    color="primary"
                    icon="event"
                    title={`Meeting with ${event.lead.name}`}
                    description={moment(event.due_at, 'YYYY-MM-DD HH:mm:ss').format('MMM D [at] HH:mm A')}
                />
            </MDBox>
        ));
    };

    return (
        <Card sx={{height: "100%"}}>
            <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Upcoming appointments
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                {
                    renderEvents()
                }
            </MDBox>
        </Card>
    );
}

export default NextEvents;
