/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";


function NewVideoForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {title, excerp, content, youtube_id} = formField;
    const {
        title: titleV,
        excerp: excerpV,
        content: contentV,
        youtube_id: youtubeIdV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={title.type}
                        label={title.label}
                        name={title.name}
                        value={titleV}
                        error={errors.title && touched.title}
                        success={titleV.length > 0 && !errors.title}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={excerp.type}
                        label={excerp.label}
                        name={excerp.name}
                        value={excerpV}
                        error={errors.excerp && touched.excerp}
                        success={excerpV.length > 0 && !errors.excerp}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={youtube_id.type}
                        label={youtube_id.label}
                        name={youtube_id.name}
                        value={youtubeIdV}
                        error={errors.youtube_id && touched.youtube_id}
                        success={youtubeIdV.length > 0 && !errors.youtube_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={content.type}
                        label={content.label}
                        name={content.name}
                        value={contentV}
                        error={errors.content && touched.content}
                        success={contentV.length > 0 && !errors.content}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewVideoForm;
