/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-lead-form",
  formField: {
    name: {
      name: "name",
      label: "Full Name",
      type: "text",
      errorMsg: "Full name is required.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      placeholder: "john@email.com",
      invalidMsg: "Email address is invalid",
    },
    address: {
      name: "address",
      label: "Address",
      type: "text",
      placeholder: "4320 Southmost Blvd",
      errorMsg: "Address is required.",
    },
    city_id: {
      name: "city_id",
      label: "City",
      type: "select",
      errorMsg: "City is required.",
      invalidMsg: "City is required."
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "text",
      placeholder: "9560000000",
      errorMsg: "Phone is required.",
      invalidMsg: "Phone must have 10 digits"
    },
    power_company: {
      name: "power_company",
      label: "Power Company",
      type: "text",
      errorMsg: "Power company is required."
    },
    years_in_property: {
      name: "years_in_property",
      label: "Years Living in Property",
      type: "number",
      errorMsg: "Years living in property is required.",
      invalidMsg: "Must be greater than 0."
    },
    highest_monthly_bill: {
      name: "highest_monthly_bill",
      label: "Highest Bill",
      type: "number",
      errorMsg: "Highest bill is required.",
      invalidMsg: "Must be greater than or equal to 0."
    },
    lowest_monthly_bill: {
      name: "lowest_monthly_bill",
      label: "Lowest Bill",
      type: "number",
      errorMsg: "Lowest bill is required.",
      invalidMsg: "Must be greater than or equal to 0."
    },
    credit_score: {
      name: "credit_score",
      label: "Credit Score (optional)",
      type: "number",
      invalidMsg: "Must be a number between 300 and 850."
    },

    is_home_owner: {
      name: "is_home_owner",
      label: "Is the lead a home owner?",
      type: "checkbox",
    },
    due_at: {
      name: "due_at",
      label: "Appointment Date And Time",
      type: "date",
      errorMsg: "Appointment date is required, you can skip creating the appointment by checking the box below.",
      invalidMsg: "Must be a date in the future."
    },
    notes: {
      name: "notes",
      label: "Notes",
      type: "text",
    },
    without_appointment: {
      name: "without_appointment",
      label: "I will review the appointment details later",
      type: "checkbox",
    }
  },
};

export default form;
