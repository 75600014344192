import {Form, Formik} from "formik";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField/TextField";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import MDButton from "../../../../../../components/MDButton";
import Dialog from "@mui/material/Dialog/Dialog";
import React, {useEffect} from "react";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validations from "./schemas/validations";
import RescheduleAppointmentForm from "./components/RescheduleAppointmentForm";
import MDBox from "../../../../../../components/MDBox";

const RescheduleDialog = ({open, onClose, onSubmit}) => {

    const {formId, formField} = form;
    const currentValidation = validations;

    return <Dialog open={open} onClose={onClose}>
        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={onSubmit}
        >
            {({values, errors, touched, isSubmitting}) => (
                <Form id={formId} autoComplete="off">
                    <DialogTitle>Reschedule Appointment</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To reschedule the appointment, enter the new date and time of the appointment.
                        </DialogContentText>
                        <MDBox mt={2}>
                            <RescheduleAppointmentForm formData={{values, touched, formField, errors}} />
                        </MDBox>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={onClose}>Cancel</MDButton>
                        <MDButton disabled={isSubmitting} variant="gradient" type="submit" color="secondary">Reschedule</MDButton>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    </Dialog>;
};

export default RescheduleDialog;
