import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import Footer from "../../../../examples/Footer";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {API_URL} from "../../../../config";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import SupportCard from "../../../../examples/Cards/SupportCard";
import moment from "moment";

const SalesNotices = () => {

    const [endpoint, setEndpoint] = useState(`${API_URL}/notices`);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData(endpoint);
    }, []);

    const getData = async (url) => {

        setIsLoading(true);

        const response = await loadData(url);

        setData(response.data);

        setIsLoading(false);
    };

    const loadData = async (url) => {

        const options = {
            params: {
                sort: 'created_at',
                direction: 'desc',
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data,
        };

    };

    const renderNotices = () => {
        return data.map(notice => <Grid key={notice.id} item xs={12} md={12} xl={12}>
            <SupportCard
                label={moment(notice.created_at).format('YYYY-MM-DD')}
                title={notice.title}
                description={notice.summary}
                action={null}
            />
        </Grid>)
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {
                    isLoading ?
                        <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                               alignItems="center">
                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                        </MDBox> :
                        <Grid container spacing={6}>
                            { renderNotices() }
                        </Grid>
                }
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default SalesNotices;
