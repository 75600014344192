/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../../../components/FormField";
import React from "react";


function RescheduleAppointmentForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {due_at} = formField;
    const {
        due_at: dueAtV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={due_at.type}
                        label={due_at.label}
                        name={due_at.name}
                        options={{enableTime: true, dateFormat: 'Y-m-d H:i', disableMobile: true, minDate: new Date(), position: 'above'}}
                        value={dueAtV}
                        error={errors.due_at && touched.due_at}
                        success={dueAtV.length > 0 && !errors.due_at}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default RescheduleAppointmentForm;
