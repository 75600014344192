/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import {useState} from "react";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import FormField from "../../../../../../components/FormField";


function EditSaleForm({leads, sellers, formData, epcRate, banks, invoiceItems}) {

    const {formField, values, errors, touched} = formData;
    const {lead_id, module_size, module_quantity, total, has_secondary_seller, user_id, down_payment, utility_rebate, bank_id, finance_fee, apr_rate, ad_bonus, total_with_adders, adder_1, adder_2, adder_3, adder_4, invoice_item_id} = formField;
    const {
        lead_id: leadIdV,
        module_size: moduleSizeV,
        module_quantity: moduleQuantityV,
        total: totalV,
        has_secondary_seller: hasSecondarySellerV,
        user_id: userIdV,
        down_payment: downPaymentV,
        utility_rebate: utilityRebateV,
        finance_fee: financeFeeV,
        apr_rate: aprRateV,
        bank_id: bankIdV,
        invoice_item_id: invoiceItemIdV,
        ad_bonus: adBonusV,
        total_with_adders: totalWithAddersV,
        adder_1: adder1V,
        adder_2: adder2V,
        adder_3: adder3V,
        adder_4: adder4V
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={lead_id.type}
                        label={lead_id.label}
                        name={lead_id.name}
                        value={leadIdV}
                        options={leads}
                        error={errors.lead_id && touched.lead_id}
                        success={leadIdV && leadIdV.length > 0 && !errors.lead_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={module_size.type}
                        label={module_size.label}
                        name={module_size.name}
                        value={moduleSizeV}
                        error={errors.module_size && touched.module_size}
                        success={moduleSizeV && moduleSizeV.length > 0 && !errors.module_size}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={module_quantity.type}
                        label={module_quantity.label}
                        name={module_quantity.name}
                        value={moduleQuantityV}
                        error={errors.module_quantity && touched.module_quantity}
                        success={moduleQuantityV && moduleQuantityV.length > 0 && !errors.module_quantity}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={total.type}
                        label={total.label}
                        name={total.name}
                        value={totalV}
                        error={errors.total && touched.total}
                        success={totalV && totalV.length > 0 && !errors.total}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={has_secondary_seller.type}
                        label={has_secondary_seller.label}
                        name={has_secondary_seller.name}
                        value={hasSecondarySellerV}
                        error={errors.has_secondary_seller && touched.has_secondary_seller}
                        success={hasSecondarySellerV && !errors.has_secondary_seller}
                    />
                </Grid>
                {
                    hasSecondarySellerV &&
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={user_id.type}
                            label={user_id.label}
                            name={user_id.name}
                            value={userIdV}
                            options={sellers}
                            error={errors.user_id && touched.user_id}
                            success={userIdV && userIdV.length > 0 && !errors.user_id}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={down_payment.type}
                        label={down_payment.label}
                        name={down_payment.name}
                        value={downPaymentV}
                        error={errors.down_payment && touched.down_payment}
                        success={downPaymentV && downPaymentV.length > 0 && !errors.down_payment}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={utility_rebate.type}
                        label={utility_rebate.label}
                        name={utility_rebate.name}
                        value={utilityRebateV}
                        error={errors.utility_rebate && touched.utility_rebate}
                        success={utilityRebateV && utilityRebateV.length > 0 && !errors.utility_rebate}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={bank_id.type}
                        label={bank_id.label}
                        name={bank_id.name}
                        value={bankIdV}
                        options={banks}
                        error={errors.bank_id && touched.bank_id}
                        success={bankIdV && bankIdV.length > 0 && !errors.bank_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={finance_fee.type}
                        label={finance_fee.label}
                        name={finance_fee.name}
                        value={financeFeeV}
                        error={errors.finance_fee && touched.finance_fee}
                        success={financeFeeV && financeFeeV.length > 0 && !errors.finance_fee}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={apr_rate.type}
                        label={apr_rate.label}
                        name={apr_rate.name}
                        value={aprRateV}
                        error={errors.apr_rate && touched.apr_rate}
                        success={aprRateV && aprRateV.length > 0 && !errors.apr_rate}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={ad_bonus.type}
                        label={ad_bonus.label}
                        name={ad_bonus.name}
                        value={adBonusV}
                        error={errors.ad_bonus && touched.ad_bonus}
                        success={adBonusV && adBonusV.length > 0 && !errors.ad_bonus}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={total_with_adders.type}
                        label={total_with_adders.label}
                        name={total_with_adders.name}
                        value={totalWithAddersV}
                        error={errors.total_with_adders && touched.total_with_adders}
                        success={totalWithAddersV && totalWithAddersV.length > 0 && !errors.total_with_adders}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={adder_1.type}
                        label={adder_1.label}
                        name={adder_1.name}
                        value={adder1V}
                        error={errors.adder_1 && touched.adder_1}
                        success={adder1V && adder1V.length > 0 && !errors.adder_1}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={adder_2.type}
                        label={adder_2.label}
                        name={adder_2.name}
                        value={adder2V}
                        error={errors.adder_2 && touched.adder_2}
                        success={adder2V && adder2V.length > 0 && !errors.adder_2}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={adder_3.type}
                        label={adder_3.label}
                        name={adder_3.name}
                        value={adder3V}
                        error={errors.adder_3 && touched.adder_3}
                        success={adder3V && adder3V.length > 0 && !errors.adder_3}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={invoice_item_id.type}
                        label={invoice_item_id.label}
                        name={invoice_item_id.name}
                        value={invoiceItemIdV}
                        options={invoiceItems}
                        error={errors.invoice_item_id && touched.invoice_item_id}
                        success={invoiceItemIdV && invoiceItemIdV.length > 0 && !errors.invoice_item_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={adder_4.type}
                        label={adder_4.label}
                        name={adder_4.name}
                        value={adder4V}
                        error={errors.adder_4 && touched.adder_4}
                        success={adder4V && adder4V.length > 0 && !errors.adder_4}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditSaleForm;
