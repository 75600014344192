/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { adders_comission, adder_1_used, adder_2_used, adder_3_used, adder_4_used },
} = checkout;

const validations = Yup.object().shape({
  [adders_comission.name]: Yup.number().required(adders_comission.errorMsg).moreThan(-1, adders_comission.invalidMsg),
  [adder_1_used.name]: Yup.number().required(adder_1_used.errorMsg).moreThan(-1, adder_1_used.invalidMsg),
  [adder_2_used.name]: Yup.number().required(adder_2_used.errorMsg).moreThan(-1, adder_2_used.invalidMsg),
  [adder_3_used.name]: Yup.number().required(adder_3_used.errorMsg).moreThan(-1, adder_3_used.invalidMsg),
  [adder_4_used.name]: Yup.number().required(adder_4_used.errorMsg).moreThan(-1, adder_4_used.invalidMsg)
});

export default validations;
