/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../components/FormField";


function EditBankAccountForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {bank, account_number, routing_number} = formField;
    const {
        bank: bankV,
        account_number: accountNumberV,
        routing_number: routingNumberV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={bank.type}
                        label={bank.label}
                        name={bank.name}
                        value={bankV}
                        error={errors.bank && touched.bank}
                        success={bankV.length > 0 && !errors.bank}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={account_number.type}
                        label={account_number.label}
                        name={account_number.name}
                        value={accountNumberV}
                        error={errors.account_number && touched.account_number}
                        success={accountNumberV.length > 0 && !errors.account_number}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={routing_number.type}
                        label={routing_number.label}
                        name={routing_number.name}
                        value={routingNumberV}
                        error={errors.routing_number && touched.routing_number}
                        success={routingNumberV.length > 0 && !errors.routing_number}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditBankAccountForm;
