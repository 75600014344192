/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";


function ContactInfo({formData, cities}) {

    const {formField, values, errors, touched} = formData;
    const {name, email, address, city_id, phone} = formField;
    const {
        name: nameV,
        email: emailV,
        address: addressV,
        city_id: cityIdV,
        phone: phoneV
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Contact Information</MDTypography>
            <MDTypography variant="body2">Let's get started! Enter your new lead's basic contact information to proceed
                to the next step.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV.length > 0 && !errors.name}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={address.type}
                            label={address.label}
                            name={address.name}
                            value={addressV}
                            placeholder={address.placeholder}
                            error={errors.address && touched.address}
                            success={addressV.length > 0 && !errors.address}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={city_id.type}
                            label={city_id.label}
                            name={city_id.name}
                            value={cityIdV}
                            options={cities}
                            error={errors.city_id && touched.city_id}
                            success={cityIdV.length > 0 && !errors.city_id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={email.type}
                            label={email.label}
                            name={email.name}
                            value={emailV}
                            placeholder={email.placeholder}
                            error={errors.email && touched.email}
                            success={emailV.length > 0 && !errors.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={phone.type}
                            label={phone.label}
                            name={phone.name}
                            value={phoneV}
                            placeholder={phone.placeholder}
                            error={errors.phone && touched.phone}
                            success={phoneV.length > 0 && !errors.phone}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default ContactInfo;
