/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// Material Dashboard 2 PRO React components
// @mui icons
import Icon from "@mui/material/Icon";
// Images
import React from "react";
import Leads from "./layouts/pages/leads";
import Calendar from "./layouts/pages/appointments/calendar";
import Appointments from "./layouts/pages/appointments";
import Profile from "./layouts/pages/profile";
import Cities from "./layouts/pages/cities";
import Videos from "./layouts/pages/videos";
import Files from "./layouts/pages/files";
import Users from "./layouts/pages/users";
import VideosLibrary from "./layouts/pages/library/videos";
import FilesLibrary from "./layouts/pages/library/files";
import Tree from "./layouts/pages/team/tree";
import Members from "./layouts/pages/team/members";
import Sales from "./layouts/pages/sales";
import Earnings from "./layouts/pages/earnings";
import Notices from "./layouts/pages/notices";
import SalesDashboard from "./layouts/dashboards/sales";
import AdminDashboard from "./layouts/dashboards/admin";
import Tickets from "./layouts/pages/tickets";
import NewTicket from "./layouts/pages/tickets/new-ticket";
import References from "./layouts/pages/references";
import Invoices from "./layouts/pages/invoices/view/Invoices";
import Companies from "./layouts/pages/companies/view/Companies";
import InvoiceItems from "./layouts/pages/invoice_items/view/InvoiceItems";
import Payments from "./layouts/pages/payments/view/Payments";
import Countries from "./layouts/pages/countries";
import Banks from "./layouts/pages/banks";
import SalesNotices from "./layouts/pages/notices/sales";
import Care from "./layouts/pages/care";

export const salesRoutes = [
    {
        type: "collapse",
        name: null,
        key: "avatar",
        icon: null,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/profile",
                component: <Profile/>,
            }
        ],
    },
    {type: "divider", key: "divider-0"},
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        component: <SalesDashboard />,
        icon: <Icon fontSize="medium">dashboard</Icon>,
        noCollapse: true,
    },
    {type: "title", title: "Modules", key: "title-pages"},
    {
        type: "collapse",
        name: "CRM",
        key: "crm",
        icon: <Icon fontSize="medium">contacts</Icon>,
        collapse: [
            {
                name: "Leads",
                key: "leads",
                route: '/leads',
                component: <Leads/>
            },
            {
                name: "Sales",
                key: "sales",
                route: '/sales',
                component: <Sales/>
            },
            {
                name: "Appointments",
                key: "appointments",
                route: '/appointments',
                component: <Appointments/>
            },
            {
                name: "Calendar",
                key: "calendar",
                route: '/appointments/calendar',
                component: <Calendar/>,
                icon: <Icon fontSize="medium">calendar</Icon>,
            },
        ],
    },
    {
        type: "collapse",
        name: "Team",
        key: "team",
        icon: <Icon fontSize="medium">hub</Icon>,
        collapse: [
            {
                name: "Members",
                key: "members",
                route: 'team/members',
                component: <Members/>
            },
            {
                name: "Tree",
                key: "tree",
                route: 'team/tree',
                component: <Tree/>
            }
        ]
    },
    {
        type: "collapse",
        name: "Reports",
        key: "reports",
        icon: <Icon fontSize="medium">assessment</Icon>,
        collapse: [
            {
                name: "Earnings",
                key: "earnings",
                route: '/earnings',
                component: <Earnings/>
            }
        ]
    },
    {type: "divider", key: "divider-1"},
    {type: "title", title: "Support", key: "title-support"},
    {
        type: "collapse",
        name: "Notices",
        key: "notices",
        route: 'notices',
        noCollapse: true,
        component: <SalesNotices/>,
        icon: <Icon fontSize="medium">newspaper</Icon>,
    },
    {
        type: "collapse",
        name: "Resource Library",
        key: "resource-library",
        icon: <Icon fontSize="medium">video_library</Icon>,
        collapse: [
            {
                name: 'Videos',
                key: 'videos',
                route: 'library/videos',
                component: <VideosLibrary/>,
            },
            {
                name: 'Documents',
                key: 'documents',
                route: 'library/files',
                component: <FilesLibrary/>,
            }
        ]
    },
    {
        type: "collapse",
        name: "Support",
        key: "support",
        route: '/support',
        component: <NewTicket/>,
        icon: <Icon fontSize="medium">help</Icon>,
        noCollapse: true
    }
];

export const recruitmentRoutes = [
    {
        type: "collapse",
        name: null,
        key: "avatar",
        icon: null,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/profile",
                component: <Profile/>,
            }
        ],
    },
    {type: "divider", key: "divider-0"},
    // {
    //     type: "collapse",
    //     name: "Dashboard",
    //     key: "dashboard",
    //     route: "/dashboard",
    //     component: <SalesDashboard />,
    //     icon: <Icon fontSize="medium">dashboard</Icon>,
    //     noCollapse: true,
    // },
    {type: "title", title: "Modules", key: "title-pages"},
    {
        type: "collapse",
        name: "CRM",
        key: "crm",
        icon: <Icon fontSize="medium">contacts</Icon>,
        collapse: [
            {
                name: "References",
                key: "references",
                route: '/references',
                component: <References/>
            },
        ],
    },
    {
        type: "collapse",
        name: "Team",
        key: "team",
        icon: <Icon fontSize="medium">hub</Icon>,
        collapse: [
            {
                name: "Members",
                key: "members",
                route: 'team/members',
                component: <Members/>
            },
            {
                name: "Tree",
                key: "tree",
                route: 'team/tree',
                component: <Tree/>
            }
        ]
    },
    {
        type: "collapse",
        name: "Reports",
        key: "reports",
        icon: <Icon fontSize="medium">assessment</Icon>,
        collapse: [
            {
                name: "Earnings",
                key: "earnings",
                route: '/earnings',
                component: <Earnings/>
            }
        ]
    },
    {type: "divider", key: "divider-1"},
    {type: "title", title: "Support", key: "title-support"},
    {
        type: "collapse",
        name: "Notices",
        key: "notices",
        route: 'notices',
        component: <SalesNotices/>,
        icon: <Icon fontSize="medium">newspaper</Icon>,
    },
    {
        type: "collapse",
        name: "Wefix Care",
        key: "care",
        route: 'care',
        noCollapse: true,
        component: <Care/>,
        icon: <Icon fontSize="medium">volunteer_activism</Icon>,
    },
    {
        type: "collapse",
        name: "Resource Library",
        key: "resource-library",
        icon: <Icon fontSize="medium">video_library</Icon>,
        collapse: [
            {
                name: 'Videos',
                key: 'videos',
                route: 'library/videos',
                component: <VideosLibrary/>,
            },
            {
                name: 'Documents',
                key: 'documents',
                route: 'library/files',
                component: <FilesLibrary/>,
            }
        ]
    },
    {
        type: "collapse",
        name: "Support",
        key: "support",
        route: '/support',
        component: <NewTicket/>,
        icon: <Icon fontSize="medium">help</Icon>,
        noCollapse: true
    }
];

export const adminRoutes = [
    {
        type: "collapse",
        name: null,
        key: "avatar",
        icon: null,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/pages/profile",
                component: <Profile/>,
            }
        ],
    },
    {type: "divider", key: "divider-0"},
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        component: <AdminDashboard />,
        icon: <Icon fontSize="medium">dashboard</Icon>,
        noCollapse: true,
    },
    {type: "title", title: "Modules", key: "title-pages"},
    {
        type: "collapse",
        name: "CRM",
        key: "crm",
        icon: <Icon fontSize="medium">contacts</Icon>,
        collapse: [
            {
                name: "Leads",
                key: "leads",
                route: '/leads',
                component: <Leads/>
            },
            {
                name: "Sales",
                key: "sales",
                route: '/sales',
                component: <Sales/>
            },
            {
                name: "Appointments",
                key: "appointments",
                route: '/appointments',
                component: <Appointments/>
            },
            {
                name: "Calendar",
                key: "calendar",
                route: '/appointments/calendar',
                component: <Calendar/>,
                icon: <Icon fontSize="medium">calendar</Icon>,
            }
        ],
    },
    {
        type: "collapse",
        name: "Users",
        key: "users",
        icon: <Icon fontSize="medium">people</Icon>,
        collapse: [
            {
                name: "Users",
                key: "users",
                route: '/users',
                component: <Users/>
            },
            {
                name: "Tree",
                key: "tree",
                route: '/tree',
                component: <Tree/>
            }
        ]
    },
    {
        type: "collapse",
        name: "Invoices",
        key: "invoices",
        icon: <Icon fontSize="medium">receipt</Icon>,
        collapse: [
            {
                name: "Invoices",
                key: "invoices",
                route: "/invoices",
                component: <Invoices/>
            },
            {
                name: "Companies",
                key: "companies",
                route: "/companies",
                component: <Companies/>
            },
            {
                name: "Items",
                key: "invoice-items",
                route: "/invoice-items",
                component: <InvoiceItems/>
            },
            {
                name: "Payments",
                key: "payments",
                route: "/payments",
                component: <Payments />
            },
        ]
    },
    {
        type: "collapse",
        name: "Data",
        key: "data",
        icon: <Icon fontSize="medium">source</Icon>,
        collapse: [
            {
                name: "Banks",
                key: "banks",
                route: '/banks',
                component: <Banks/>
            },
            {
                name: "Cities",
                key: "cities",
                route: '/cities',
                component: <Cities/>
            },
            {
                name: "Countries",
                key: "countries",
                route: '/countries',
                component: <Countries/>
            },
            {
                name: "Notices",
                key: "notices",
                route: '/notices',
                component: <Notices/>
            },
        ]
    },
    {
        type: "collapse",
        name: "Reports",
        key: "reports",
        icon: <Icon fontSize="medium">assessment</Icon>,
        collapse: [
            {
                name: "Payroll",
                key: "earnings",
                route: '/earnings',
                component: <Earnings/>
            }
        ]
    },
    {
        type: "collapse",
        name: "Resources",
        key: "resources",
        icon: <Icon fontSize="medium">video_library</Icon>,
        collapse: [
            {
                name: 'Videos',
                key: 'videos',
                route: '/videos',
                component: <Videos/>,
            },
            {
                name: 'Documents',
                key: 'documents',
                route: '/files',
                component: <Files/>,
            }
        ]
    },
    {type: "divider", key: "divider-1"},
    {
        type: "collapse",
        name: "Wefix Care",
        key: "care",
        route: 'care',
        noCollapse: true,
        component: <Care/>,
        icon: <Icon fontSize="medium">volunteer_activism</Icon>,
    },
    {type: "title", title: "Support", key: "title-support"},
    {
        type: "collapse",
        name: "Support",
        key: "support",
        route: '/support',
        component: <Tickets/>,
        icon: <Icon fontSize="medium">help</Icon>,
        noCollapse: true
    }
];

export const accountingRoutes = [
    {
        type: "collapse",
        name: null,
        key: "avatar",
        icon: null,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/pages/profile",
                component: <Profile/>,
            }
        ],
    },
    {type: "divider", key: "divider-0"},
    {type: "title", title: "Modules", key: "title-pages"},
    {
        type: "collapse",
        name: "CRM",
        key: "crm",
        icon: <Icon fontSize="medium">contacts</Icon>,
        collapse: [
            {
                name: "Sales",
                key: "sales",
                route: '/sales',
                component: <Sales/>
            },
        ],
    },
    {
        type: "collapse",
        name: "Reports",
        key: "reports",
        icon: <Icon fontSize="medium">assessment</Icon>,
        collapse: [
            {
                name: "Earnings",
                key: "earnings",
                route: '/earnings',
                component: <Earnings/>
            }
        ]
    }
];

export const installerRoutes = [
    {
        type: "collapse",
        name: null,
        key: "avatar",
        icon: null,
        collapse: [
            {
                name: "My Profile",
                key: "my-profile",
                route: "/profile",
                component: <Profile/>,
            }
        ],
    },
    {type: "divider", key: "divider-0"},
    {type: "title", title: "Modules", key: "title-pages"},
    {
        type: "collapse",
        name: "CRM",
        key: "crm",
        icon: <Icon fontSize="medium">contacts</Icon>,
        collapse: [
            {
                name: "Sales",
                key: "sales",
                route: '/sales',
                component: <Sales/>
            },
        ],
    },
    {type: "divider", key: "divider-1"},
    {type: "title", title: "Support", key: "title-support"},
    {
        type: "collapse",
        name: "Resource Library",
        key: "resource-library",
        icon: <Icon fontSize="medium">video_library</Icon>,
        collapse: [
            {
                name: 'Videos',
                key: 'videos',
                route: 'library/videos',
                component: <VideosLibrary/>,
            },
            {
                name: 'Documents',
                key: 'documents',
                route: 'library/files',
                component: <FilesLibrary/>,
            }
        ]
    },
    {
        type: "collapse",
        name: "Support",
        key: "support",
        route: '/support',
        component: <NewTicket/>,
        icon: <Icon fontSize="medium">help</Icon>,
        noCollapse: true
    }
];
