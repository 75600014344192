/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import checkout from "./form";

const {
    formField: {
        name,
        email,
        phone,
        social_security_number,
        address,
        id_number,
        city_id
    }
} = checkout;

const initialValues = {
    [name.name]: "",
    [email.name]: "",
    [phone.name]: "",
    [social_security_number.name]: "",
    [address.name]: "",
    [id_number.name]: "",
    [city_id.name]: ""
};

export default initialValues;
