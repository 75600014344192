/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";


function AppointmentInfo({ formData }) {

    const {formField, values, errors, touched} = formData;
    const {due_at, notes, without_appointment} = formField;
    const {
        due_at: due_atV,
        notes: notesV,
        without_appointment: without_appointmentV
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Appointment Information</MDTypography>
            <MDTypography variant="body2">Book a follow up appointment or skip and submit your new lead.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={due_at.type}
                            label={due_at.label}
                            name={due_at.name}
                            value={due_atV}
                            options={{enableTime: true, disableMobile: true, dateFormat: 'Y-m-d H:i', minDate: new Date()}}
                            placeholder={due_at.placeholder}
                            error={errors.due_at && touched.due_at}
                            success={due_atV && !errors.due_at}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={notes.type}
                            label={notes.label}
                            name={notes.name}
                            value={notesV}
                            error={errors.notes && touched.notes}
                            success={notesV && !errors.notes}
                            multiline={true}
                            rows={3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={without_appointment.type}
                            label={without_appointment.label}
                            name={without_appointment.name}
                            value={without_appointmentV}
                            error={errors.without_appointment && touched.without_appointment}
                            success={without_appointmentV && !errors.without_appointment}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default AppointmentInfo;
