/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, email, phone, address, social_security_number, id_number, city_id },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(name.errorMsg),
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  [phone.name]: Yup.string().nullable().required(phone.errorMsg).min(10, phone.invalidMsg).max(10, phone.invalidMsg),
  [address.name]: Yup.string().nullable().required(address.errorMsg),
  [social_security_number.name]: Yup.string().nullable().required(social_security_number.errorMsg),
  [id_number.name]: Yup.string().nullable().required(id_number.errorMsg),
  [city_id.name]: Yup.string().nullable().required(city_id.errorMsg),
});

export default validations;
