/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";
import moment from "moment";

const {
  formField: {
    folio,
      type,
    client_name,
    service_address,
    loan_id,
      sales_person,
      fob_point,
      terms,
      due_at,
      company_id,
      invoice_items
  },
} = checkout;

const initialValues = {
  [folio.name]: "",
  [type.name]: -1,
  [client_name.name]: "",
  [service_address.name]: "",
  [loan_id.name]: "",
  [sales_person.name]: "",
  [fob_point.name]: "",
  [terms.name]: "",
  [due_at.name]: moment().format('YYYY-MM-DD'),
  [company_id.name]: -1,
  [invoice_items.name]: [
    {
      invoice_item_id: -1,
      description: '',
      unit_price: '',
      amount: "1",
    }
  ]
};

export default initialValues;
