/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";

const validations = (id) => {

    const {
        formField: {payment_method, amount, paid_at, invoice_id},
    } = checkout(id);

    if(id){
        return Yup.object().shape({
            [payment_method.name]: Yup.string().required(payment_method.errorMsg),
            [amount.name]: Yup.number().required(amount.errorMsg).moreThan(0, amount.invalidMsg),
            [paid_at.name]: Yup.date().required(paid_at.errorMsg),
        });
    } else {
        return Yup.object().shape({
            [payment_method.name]: Yup.string().required(payment_method.errorMsg),
            [amount.name]: Yup.number().required(amount.errorMsg).moreThan(0, amount.invalidMsg),
            [paid_at.name]: Yup.date().required(paid_at.errorMsg),
            [invoice_id.name]: Yup.number().required(invoice_id.errorMsg).positive(invoice_id.invalidMsg)
        });
    }
};

export default validations;
