import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import {API_URL} from "../../../../config";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import Footer from "../../../../examples/Footer";
import OrganizationChart from "@dabeng/react-orgchart";
import Grid from "@mui/material/Grid";
import {renderAvatarInitials} from "../../../../helpers/helpers";
import MDAvatar from "../../../../components/MDAvatar";
import MDTypography from "../../../../components/MDTypography";
import moment from "moment";
import './styles.css';
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import MDButton from "../../../../components/MDButton";

const TreeNode = ({nodeData}) => {
    return <Card>
        <MDBox p={1}>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item>
                    <MDAvatar bgColor={nodeData.role_id == 2 ? 'primary' : 'secondary'} alt="profile-image" size="sm" shadow="sm">{renderAvatarInitials(nodeData.name)}</MDAvatar>
                </Grid>
                <Grid item>
                    <MDBox display="flex" flexDirection="column" mt={0.5} lineHeight={1}>
                        <MDTypography variant="button" fontWeight="medium">
                            {nodeData.name}
                        </MDTypography>
                        <MDTypography variant="caption" color="text">
                            {nodeData.city && nodeData.city.name}
                        </MDTypography>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    </Card>
};

const Tree = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState([]);

    useEffect(() => {
        getData(`${API_URL}/members/tree`);
    }, []);

    const getData = async (url) => {

        setIsLoading(true);

        const response = await loadData(url);

        setData(response.data);

        setIsLoading(false);
    };

    const loadData = async (url) => {

        const response = await axios.get(url);

        return {
            data: response.data[0]
        };

    };

    const renderTree = (users) => {

        return users.map(user => (
            <div style={{marginLeft: 20}}>
                {user.name} - {user.id}

                {renderTree(user.children)}
            </div>
        ));

    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <MDBox mb={3}>
                    <Card>
                        <MDBox p={3} lineHeight={1}>
                            <Grid container>
                                <Grid item xl={9} xs={12}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Your Network
                                    </MDTypography>
                                    <MDTypography variant="button" color="text">
                                        Visualize your sales network
                                    </MDTypography>
                                </Grid>
                                <Grid xl={3} xs={12}>

                                </Grid>
                            </Grid>
                        </MDBox>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                       alignItems="center">
                                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                <OrganizationChart datasource={data} zoom={true} pan={true} NodeTemplate={TreeNode} />
                        }
                    </Card>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Tree;
