import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from 'react-router-dom';
import breakpoints from "assets/theme/base/breakpoints";
import {useMaterialUIController} from "../../../../context";
import {API_URL} from "../../../../config";

const useInvoiceDetails = () => {

    const {id} = useParams();

    const [controller] = useMaterialUIController();
    const {darkMode} = controller;

    const [invoice, setInvoice] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    useEffect(() => {

        const init = async () => {

            setIsLoading(true);

            await loadRecord();

            setIsLoading(false);
        };

        init();

    }, []);

    const loadRecord = async () => {

        try {

            const response = await axios.get(`${API_URL}/invoices/${id}`);

            let invoiceData = response.data;

            invoiceData['invoice_items'] = invoiceData.invoice_items.map(invoiceItem => invoiceItem.pivot);

            setInvoice(invoiceData);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the invoice",
                icon: 'cancel',
                title: 'Invoices',
                color: 'warning'
            });

            setShow(true);

        }

    };

    return {
        invoice,
        tabsOrientation,
        tabValue,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        handleSetTabValue,
    };
};

export default useInvoiceDetails;
