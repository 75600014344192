/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

function SupportCard({ label, title, description, action = null }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox mt={1} mx={0.5} p={2}>
        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {label}
        </MDTypography>
        {
          action ?
          <MDBox mb={1}>
            {action.type === "internal" ? (
                <MDTypography
                    component={Link}
                    to={action.route}
                    variant="h5"
                    textTransform="capitalize"
                >
                  {title}
                </MDTypography>
            ) : (
                <MDTypography
                    component="a"
                    href={action.route}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    textTransform="capitalize"
                >
                  {title}
                </MDTypography>
            )}
          </MDBox>
              :
              <MDBox mb={1}>
                <MDTypography
                    variant="h5"
                    textTransform="capitalize"
                >
                  {title}
                </MDTypography>
              </MDBox>
        }
        <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
        {
          action &&
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            {action.type === "internal" ? (
                <MDButton
                    component={Link}
                    to={action.route}
                    variant="outlined"
                    size="small"
                    color={action.color}
                >
                  {action.label}
                </MDButton>
            ) : (
                <MDButton
                    component="a"
                    href={action.route}
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    size="small"
                    color={action.color}
                >
                  {action.label}
                </MDButton>
            )}
          </MDBox>
        }
      </MDBox>
    </Card>
  );
}

export default SupportCard;
