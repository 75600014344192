/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from "axios";
import {API_URL} from "../../../../../config";
import {signOut} from "../../../../../store/actions/auth";
import {useDispatch} from "react-redux";

function DeleteAccount() {

  const dispatch = useDispatch();

  const handleLogout = async () => {

    try {
      await axios.post(API_URL + "/logout");
      dispatch(signOut());
    } catch (error) {

    }

  };

  return (
    <Card id="delete-account">
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Sign Out Of Account</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            End your current session.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" p={2} flexDirection={{ xs: "column", sm: "row" }}>
          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <MDButton variant="gradient" color="error" onClick={handleLogout} sx={{ height: "100%" }}>
              sign out
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DeleteAccount;
