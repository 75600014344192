/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../../components/FormField";


function NewMemberForm({formData}) {

    const {formField, values, errors, touched} = formData;
    const {name, email, phone} = formField;
    const {
        name: nameV,
        email: emailV,
        phone: phoneV
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Account Information</MDTypography>
            <MDTypography variant="body2">Invite a new member to join. After you submit the form, the new member will receive an email with the instructions to activate his account.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV.length > 0 && !errors.name}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={email.type}
                            label={email.label}
                            name={email.name}
                            value={emailV}
                            placeholder={email.placeholder}
                            error={errors.email && touched.email}
                            success={emailV.length > 0 && !errors.email}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default NewMemberForm;
