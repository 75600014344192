import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatFilters, isEmpty} from "../../helpers/helpers";
import CustomToolbar from "../CustomToolbar";
import CustomToolbarSelect from "../CustomToolbarSelect";
import MDButton from "../../components/MDButton";

const useDataTable = ({endpoint, title, description, colData, sortBy, sortDirection, parentFilters, actions, filter, canSearch, loadAtStart, onSortChange = null}) => {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState({
        name: sortBy,
        direction: sortDirection,
    });

    const [isLoading, setIsLoading] = useState(loadAtStart);

    const [data, setData] = useState([]);

    const [columns, setColumns] = useState(colData);

    useEffect(() => {
        if(loadAtStart){
            getData(endpoint);
        }
    }, []);

    useEffect(() => {
        if(!isEmpty(parentFilters)){
            handleFilterSubmit(() => (parentFilters));
        }
    }, [parentFilters]);

    const getData = async (url) => {

        setIsLoading(true);

        const response = await loadData(url);

        setData(response.data);
        setCount(response.total);
        setPage(response.page);

        setIsLoading(false);
    };

    const loadData = async (url, config = {}) => {

        let fullConfig = {
            page,
            sortOrder,
            rowsPerPage,
            search,
            filters
        };

        if(config.page)
            fullConfig.page = config.page;

        if(config.sortOrder)
            fullConfig.sortOrder = config.sortOrder;

        if(config.rowsPerPage)
            fullConfig.rowsPerPage = config.rowsPerPage;

        if(config.search !== undefined)
            fullConfig.search = config.search;

        if(config.filters)
            fullConfig.filters = config.filters;

        const options = {
            params: {
                sort: fullConfig.sortOrder.name,
                direction: fullConfig.sortOrder.direction,
                page: fullConfig.page,
                per_page: fullConfig.rowsPerPage,
                search: fullConfig.search ? fullConfig.search : undefined,
                filters: fullConfig.filters ? fullConfig.filters : undefined,
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data.data,
            total: response.data.total,
            page: response.data.current_page,
        };

    };

    const sort = async (sortOrder) => {
        setIsLoading(true);

        let options = {
            sortOrder,
        };

        const response = await loadData(endpoint, options);

        if(onSortChange)
            onSortChange(sortOrder);

        setData(response.data);
        setSortOrder(sortOrder);

        setIsLoading(false);
    };

    const changePage = async (page) => {
        setIsLoading(true);

        let options = {
            page: page + 1
        };

        const response = await loadData(endpoint, options);

        setPage(response.page);
        setData(response.data);

        setIsLoading(false);
    };

    const changeRowsPerPage = async (rowsPerPage) => {
        setIsLoading(true);

        let options = {
            rowsPerPage
        };

        const response = await loadData(endpoint, options);

        setRowsPerPage(rowsPerPage);
        setData(response.data);

        setIsLoading(false);
    };

    const handleSearch = async (search) => {

        setIsLoading(true);

        let options = {
            search
        };

        const response = await loadData(endpoint, options);

        setSearch(search);
        setCount(response.total);
        setPage(response.page);
        setData(response.data);

        setIsLoading(false);

    };

    const handleFilterSubmit = async (applyFilters) => {

        let filterList = formatFilters(applyFilters(), columns);

        setIsLoading(true);

        let options = {
            filters: filterList,
        };

        const response = await loadData(endpoint, options);

        setCount(response.total);
        setPage(response.page);
        setData(response.data);
        setFilters(filterList);

        setIsLoading(false);

    };

    const handleDeleteClick = async (ids) => {

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = ids.length;

            // Por cada registro seleccionado
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${endpoint}/${ids[i]}`);

            }

            setSnackbarConfig({
                message: count === 1 ? `1 record updated` : `${count} records updated`,
                icon: 'notifications',
                title: title,
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if(error.response.data.message){
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: title,
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'Something went wrong activating or deactivating a record.',
                    icon: 'cancel',
                    title: title,
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const options = {
        elevation: 0,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 25, 50],
        sortOrder: sortOrder,
        download: false,
        print: false,
        enableNestedDataAccess: '.',
        search: canSearch,
        filter: filter,
        filterType: 'textField',
        confirmFilters: true,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{marginTop: '40px'}}>
                    <MDButton variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Apply Filters</MDButton>
                </div>
            );
        },
        customToolbar: () => {
            return (
                <CustomToolbar canAdd={actions.add !== false} handleNewClick={actions.add} customActions={actions.custom && actions.custom.filter(action => action.type !== 'select')} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
                selectedRows={selectedRows}
                displayData={displayData}
                setSelectedRows={setSelectedRows}
                canEdit={actions.edit !== false}
                handleEditClick={actions.edit}
                canDelete={actions.delete}
                handleDeleteClick={actions.delete === true ? handleDeleteClick : actions.delete}
            />
        ),
        onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
                let newFilters = () => (filterList);
                handleFilterSubmit(newFilters);
            }
        },
        onSearchChange: handleSearch,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    break;
                case 'sort':
                    sort(tableState.sortOrder);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    break;
            }
        }
    };

    const renderTitle = () => {
        return title;
    };

    const renderIntro = () => {
        return description;
    };

    return {
        isLoading,
        data,
        columns,
        options,
        snackbarConfig,
        show,
        toggleSnackbar,
        renderIntro,
        renderTitle,
        handleFilterSubmit
    }

};

export default useDataTable;
