/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";


function NewNoticeForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {title, summary} = formField;
    const {
        title: titleV,
        summary: summaryV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={title.type}
                        label={title.label}
                        name={title.name}
                        value={titleV}
                        error={errors.title && touched.title}
                        success={titleV.length > 0 && !errors.title}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={summary.type}
                        label={summary.label}
                        name={summary.name}
                        value={summaryV}
                        error={errors.summary && touched.summary}
                        success={summaryV.length > 0 && !errors.summary}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewNoticeForm;
