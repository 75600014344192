/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";
import {useState} from "react";


function FinanceInfo({formData, epcRate, banks}) {

    const {formField, values, errors, touched} = formData;
    const {ad_bonus, down_payment, utility_rebate, bank_id, finance_fee, apr_rate} = formField;
    const {
        down_payment: downPaymentV,
        utility_rebate: utilityRebateV,
        finance_fee: financeFeeV,
        ad_bonus: adBonusV,
        apr_rate: aprRateV,
        bank_id: bankIdV,
        total: totalV,
        module_size: moduleSizeV,
        module_quantity: moduleQuantityV,
    } = values;

    const getPPW = () => {
        return (totalV / (moduleSizeV * moduleQuantityV));
    };

    const getFinanceFee = () => {
        return ((totalV - downPaymentV - utilityRebateV) * (financeFeeV/100));
    };

    const getNetPPW = () => {
        return getPPW() - (getFinanceFee() / (moduleQuantityV * moduleSizeV));
    };

    const getAdBonusWatts = () => {
        return adBonusV / (moduleQuantityV * moduleSizeV);
    };

    const getComissionBeforeAddersWatts = () => {
        return getNetPPW() - epcRate - getAdBonusWatts()
    };

    const getComission = () => {
        return (getComissionBeforeAddersWatts() * 1000) * ((moduleQuantityV * moduleSizeV) / 1000) - 750;
    };

    return (
        <MDBox>
            <MDTypography variant="h5">Finance Information</MDTypography>
            <MDTypography variant="body2">Enter the financial information of the sale.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={down_payment.type}
                            label={down_payment.label}
                            name={down_payment.name}
                            value={downPaymentV}
                            error={errors.down_payment && touched.down_payment}
                            success={downPaymentV && downPaymentV.length > 0 && !errors.down_payment}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={utility_rebate.type}
                            label={utility_rebate.label}
                            name={utility_rebate.name}
                            value={utilityRebateV}
                            error={errors.utility_rebate && touched.utility_rebate}
                            success={utilityRebateV && utilityRebateV.length > 0 && !errors.utility_rebate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={bank_id.type}
                            label={bank_id.label}
                            name={bank_id.name}
                            options={banks}
                            value={bankIdV}
                            error={errors.bank_id && touched.bank_id}
                            success={bankIdV && bankIdV.length > 0 && !errors.bank_id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={finance_fee.type}
                            label={finance_fee.label}
                            name={finance_fee.name}
                            value={financeFeeV}
                            error={errors.finance_fee && touched.finance_fee}
                            success={financeFeeV && financeFeeV.length > 0 && !errors.finance_fee}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={apr_rate.type}
                            label={apr_rate.label}
                            name={apr_rate.name}
                            value={aprRateV}
                            error={errors.apr_rate && touched.apr_rate}
                            success={aprRateV && aprRateV.length > 0 && !errors.apr_rate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={ad_bonus.type}
                            label={ad_bonus.label}
                            name={ad_bonus.name}
                            value={adBonusV}
                            error={errors.ad_bonus && touched.ad_bonus}
                            success={adBonusV && adBonusV.length > 0 && !errors.ad_bonus}
                        />
                    </Grid>
                </Grid>
                {/*<MDBox mt={3} bgColor="#f0f2f5" p={2} borderRadius={3} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">*/}
                {/*    <MDBox>*/}
                {/*        <MDTypography variant="h5">Net PPW</MDTypography>*/}
                {/*        {*/}
                {/*            (totalV && financeFeeV > 0 && downPaymentV >= 0 && utilityRebateV >= 0) ?*/}
                {/*                <MDTypography variant="caption" color="text">(${totalV} - ${downPaymentV} - ${utilityRebateV}) x {financeFeeV}%</MDTypography> :*/}
                {/*                <MDTypography variant="caption" color="text">Enter the fields above to calculate the*/}
                {/*                    net ppw</MDTypography>*/}
                {/*        }*/}
                {/*    </MDBox>*/}
                {/*    {*/}
                {/*        (totalV && financeFeeV > 0 && downPaymentV >= 0 && utilityRebateV >= 0) &&*/}
                {/*        <MDTypography color="secondary" variant="h3">${getNetPPW().toFixed(2)}</MDTypography>*/}
                {/*    }*/}
                {/*</MDBox>*/}
                <MDBox mt={3} bgColor="#f0f2f5" p={2} borderRadius={3} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <MDBox>
                        <MDTypography variant="h5">Comission Total</MDTypography>
                        <MDTypography variant="caption" color="text">Enter the fields above to calculate the
                            comission total</MDTypography>
                    </MDBox>
                    {
                        (totalV && financeFeeV > 0 && downPaymentV >= 0 && utilityRebateV >= 0) &&
                        <MDTypography color="secondary" variant="h3">${getComission().toFixed(2)}</MDTypography>
                    }
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default FinanceInfo;
