/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, email, address, city_id, phone, power_company, years_in_property, highest_monthly_bill, lowest_monthly_bill, credit_score },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(name.errorMsg),
  [email.name]: Yup.string().notRequired().email(email.invalidMsg),
  [address.name]: Yup.string().required(address.errorMsg),
  [city_id.name]: Yup.number().required(city_id.errorMsg).positive(city_id.invalidMsg),
  [phone.name]: Yup.string().required(phone.errorMsg).min(10, phone.invalidMsg).max(10, phone.invalidMsg),
  [power_company.name]: Yup.string().required(power_company.errorMsg),
  [years_in_property.name]: Yup.number().required(years_in_property.errorMsg).moreThan(0, years_in_property.invalidMsg),
  [highest_monthly_bill.name]: Yup.number().required(highest_monthly_bill.errorMsg).moreThan(0, highest_monthly_bill.invalidMsg),
  [lowest_monthly_bill.name]: Yup.number().required(lowest_monthly_bill.errorMsg).moreThan(0, lowest_monthly_bill.invalidMsg),
  [credit_score.name]: Yup.number().nullable().min(300, credit_score.invalidMsg).max(850, credit_score.invalidMsg),
});

export default validations;
