/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
// Sales dashboard components
// Data
import axios from "axios";
import {API_URL} from "../../../config";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";

function AdminDashboard() {

    const [salesByCityDropdownValue, setSalesByCityDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [salesByCityDropdown, setSalesByCityDropdown] = useState(null);

    const [isSalesTotalLoading, setIsSalesTotalLoading] = useState(true);
    const [salesTotalStats, setSalesTotalStats] = useState(null);
    const [salesTotalDropdownValue, setSalesTotalDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [salesTotalDropdown, setSalesTotalDropdown] = useState(null);

    const [isLeadsLoading, setIsLeadsLoading] = useState(true);
    const [leadsStats, setLeadsStats] = useState(null);
    const [leadsDropdownValue, setLeadsDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [leadsDropdown, setLeadsDropdown] = useState(null);

    const [isSalesLoading, setIsSalesLoading] = useState(true);
    const [salesStats, setSalesStats] = useState(null);
    const [salesDropdownValue, setSalesDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [salesDropdown, setSalesDropdown] = useState(null);

    const [cityChartData, setCityChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Sales by city",
                color: "dark",
                data: [],
            },
        ],
    });


    useEffect(() => {

        const init = async () => {

            let to = moment();
            let from = moment().subtract(7, 'days');

            await loadSalesTotal(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
            await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
            await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
            await loadSalesByCity(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
        };

        init();
    }, []);

    const loadSalesTotal = async (from, to) => {

        setIsSalesTotalLoading(true);

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/users/sales/total`, options);

        setSalesTotalStats(response.data);

        setIsSalesTotalLoading(false);

    };

    const loadSalesByCity = async (from, to) => {

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/sales/city`, options);

        setCityChartData({
            labels: response.data.sales.map(sale => sale.name === null ? 'Undefined' : sale.name),
            datasets: [
                {
                    label: "Current period",
                    color: "dark",
                    data: response.data.sales.map(sale => sale.sum),
                }
            ],
        });

    };

    const loadLeads = async (from, to) => {

        setIsLeadsLoading(true);

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/users/leads`, options);

        setLeadsStats(response.data);

        setIsLeadsLoading(false);

    };

    const loadSales = async (from, to) => {

        setIsSalesLoading(true);

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/users/sales`, options);

        setSalesStats(response.data);

        setIsSalesLoading(false);

    };

    const openSalesByCityDropdown = ({currentTarget}) => setSalesByCityDropdown(currentTarget);

    const closeSalesByCityDropdown = async (option) => {
        setSalesByCityDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setSalesByCityDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSalesByCity(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setSalesByCityDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSalesByCity(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setSalesByCityDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadSalesByCity(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }

    };

    // DefaultStatisticsCard handler for the dropdown action
    const openSalesTotalDropdown = ({currentTarget}) => setSalesTotalDropdown(currentTarget);

    const closeSalesTotalDropdown = async (option) => {
        setSalesTotalDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setSalesTotalDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSalesTotal(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setSalesTotalDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSalesTotal(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setSalesTotalDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadSalesTotal(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }

    };

    const openLeadsDropdown = ({currentTarget}) => setLeadsDropdown(currentTarget);

    const closeLeadsDropdown = async (option) => {
        setLeadsDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setLeadsDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setLeadsDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setLeadsDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }

    };

    const openSalesDropdown = ({currentTarget}) => setSalesDropdown(currentTarget);

    const closeSalesDropdown = async (option) => {
        setSalesDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setSalesDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setSalesDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setSalesDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }
    };

    // Dropdown menu template for the DefaultStatisticsCard
    const renderMenu = (state, close) => (
        <Menu
            anchorEl={state}
            transformOrigin={{vertical: "top", horizontal: "center"}}
            open={Boolean(state)}
            onClose={close}
            keepMounted
            disableAutoFocusItem
        >
            <MenuItem onClick={() => close(1)}>Last 7 days</MenuItem>
            <MenuItem onClick={() => close(2)}>Last 30 days</MenuItem>
            <MenuItem onClick={() => close(3)}>Last 365 days</MenuItem>
        </Menu>
    );

    const calculatePercentage = (newValue, previousValue) => {


        let percentage = (newValue - previousValue) / previousValue * 100;

        return {
            color: newValue > previousValue ? "success" : "error",
            value: previousValue && `${percentage.toFixed(2)}%`,
            label: previousValue ? "since last week" : "Nothing to compare",
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={3}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            {
                                isSalesTotalLoading ?
                                    <Card>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card> :
                                    <DefaultStatisticsCard
                                        title="Sales Total"
                                        count={`$${salesTotalStats.sales}`}
                                        percentage={calculatePercentage(salesTotalStats.sales, salesTotalStats.previous_sales)}
                                        dropdown={{
                                            action: openSalesTotalDropdown,
                                            menu: renderMenu(salesTotalDropdown, closeSalesTotalDropdown),
                                            value: salesTotalDropdownValue,
                                        }}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                isLeadsLoading ?
                                    <Card>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card> :
                                    <DefaultStatisticsCard
                                        title="Leads"
                                        count={leadsStats.leads}
                                        percentage={calculatePercentage(leadsStats.leads, leadsStats.previous_leads)}
                                        dropdown={{
                                            action: openLeadsDropdown,
                                            menu: renderMenu(leadsDropdown, closeLeadsDropdown),
                                            value: leadsDropdownValue,
                                        }}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                isSalesLoading ?
                                    <Card>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card> :
                                    <DefaultStatisticsCard
                                        title="Sales"
                                        count={salesStats.sales}
                                        percentage={calculatePercentage(salesStats.sales, salesStats.previous_sales)}
                                        dropdown={{
                                            action: openSalesDropdown,
                                            menu: renderMenu(salesDropdown, closeSalesDropdown),
                                            value: salesDropdownValue,
                                        }}
                                    />
                            }
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <HorizontalBarChart
                                title="Sales by city"
                                chart={cityChartData}
                                dropdown={{
                                    action: openSalesByCityDropdown,
                                    menu: renderMenu(salesByCityDropdown, closeSalesByCityDropdown),
                                    value: salesByCityDropdownValue,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>

                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default AdminDashboard;
