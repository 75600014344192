
export const generateApiUrl = (baseUrl, query, filtersData = null) => {

    // Generar URL base y agregar opciones de paginación
    let url = baseUrl + '?';
    url += 'per_page=' + query.pageSize;
    url += '&page=' + (query.page + 1);

    // Si se seleccionaron opciones de orden, agregar a URL
    if(query.orderBy && query.orderDirection){
        url += '&sort=' + query.orderBy.field;
        url += '&direction=' + query.orderDirection;
    }

    // Si se seleccionaron filtros, agregar a URL
    if(query.filters){
        for(let i = 0; i < query.filters.length; i++){
            if(query.filters[i].column.field === 'is_active' || query.filters[i].column.field === 'is_spicy')
                url += `&${query.filters[i].column.field}=${query.filters[i].value === 'checked' ? 1 : 0}`;
            else
                url += `&${query.filters[i].column.field}=${query.filters[i].value}`;
        }
    }

    if(filtersData){
        for(let i = 0; i < filtersData.length; i++){
            if(filtersData[i].value !== null){

                switch(filtersData[i].control.type){
                    case 'date':
                        if(filtersData[i].value[0] !== null || filtersData[i].value[1] !== null){
                            url += `&${filtersData[i].field.column}=true`;
                            if(filtersData[i].value[0])
                                url += `&${filtersData[i].field.column}_start=${filtersData[i].value[0].format('YYYY-MM-DD')}`;
                            if(filtersData[i].value[1])
                                url += `&${filtersData[i].field.column}_end=${filtersData[i].value[1].format('YYYY-MM-DD')}`;
                        }
                        break;
                    case 'datetime':
                        if(filtersData[i].value[0] !== null || filtersData[i].value[1] !== null){
                            url += `&${filtersData[i].field.column}=true`;
                            if(filtersData[i].value[0])
                                url += `&${filtersData[i].field.column}_start=${filtersData[i].value[0].format('YYYY-MM-DD')}`;
                            if(filtersData[i].value[1]) {
                                let datetime = filtersData[i].value[1].clone();
                                url += `&${filtersData[i].field.column}_end=${datetime.add(1, 'days').format('YYYY-MM-DD')}`;
                            }
                        }
                        break;
                    case 'text':
                        url += `&${filtersData[i].field.column}=${filtersData[i].value}`;
                        break;
                    case 'select':
                        url += `&${filtersData[i].field.column}=${filtersData[i].value}`;
                        break;
                    case 'checkbox':

                        if(filtersData[i].value !== null) {
                            url += `&${filtersData[i].field.column}=${filtersData[i].value ? 1 : 0}`;
                        }
                        break;
                }
            }
        }
    }

    // Si se llenó el campo de búsqueda, agregar a filtros
    if(query.search)
        url += '&search=' + query.search;

    return url;
};

export const formatFilters = (list, columns) => {
    let formattedData = {};

    for(let i = 0; i < columns.length; i++){
        if(list[i].length > 0){
            formattedData[columns[i].name] = list[i];
        }
    }

    return formattedData;
};

export const renderAvatarInitials = (name) => {

    const segments = name.split(' ');

    if(segments.length >= 2)
        return segments[0][0].toUpperCase() + segments[1][0].toUpperCase();
    else
        return segments[0][0].toUpperCase();
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEmpty = (array) => {
    return Array.isArray(array) && array.every(isEmpty);
};

export const renderPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
        case '1':
            return 'Check';
        case '2':
            return 'Cash';
        case '3':
            return 'Credit Card';
        case '4':
            return 'Debit Card';
        case '5':
            return 'Electronic Transfer';
    }
};
