/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";
import {useState} from "react";


function SaleInfo({leads, sellers, formData}) {

    const {formField, values, errors, touched} = formData;
    const {lead_id, module_size, module_quantity, total, has_secondary_seller, user_id} = formField;
    const {
        lead_id: leadIdV,
        module_size: moduleSizeV,
        module_quantity: moduleQuantityV,
        total: totalV,
        has_secondary_seller: hasSecondarySellerV,
        user_id: userIdV
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Sale Information</MDTypography>
            <MDTypography variant="body2">Select the lead and enter the sale details to save the sale.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={lead_id.type}
                            label={lead_id.label}
                            name={lead_id.name}
                            value={leadIdV}
                            options={leads}
                            error={errors.lead_id && touched.lead_id}
                            success={leadIdV && leadIdV.length > 0 && !errors.lead_id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={module_size.type}
                            label={module_size.label}
                            name={module_size.name}
                            value={moduleSizeV}
                            error={errors.module_size && touched.module_size}
                            success={moduleSizeV && moduleSizeV.length > 0 && !errors.module_size}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={module_quantity.type}
                            label={module_quantity.label}
                            name={module_quantity.name}
                            value={moduleQuantityV}
                            error={errors.module_quantity && touched.module_quantity}
                            success={moduleQuantityV && moduleQuantityV.length > 0 && !errors.module_quantity}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={total.type}
                            label={total.label}
                            name={total.name}
                            value={totalV}
                            error={errors.total && touched.total}
                            success={totalV && totalV.length > 0 && !errors.total}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={has_secondary_seller.type}
                            label={has_secondary_seller.label}
                            name={has_secondary_seller.name}
                            value={hasSecondarySellerV}
                            error={errors.has_secondary_seller && touched.has_secondary_seller}
                            success={hasSecondarySellerV && !errors.has_secondary_seller}
                        />
                    </Grid>
                    {
                        hasSecondarySellerV &&
                        <Grid item xs={12} sm={12}>
                            <FormField
                                type={user_id.type}
                                label={user_id.label}
                                name={user_id.name}
                                value={userIdV}
                                options={sellers}
                                error={errors.user_id && touched.user_id}
                                success={userIdV && userIdV.length > 0 && !errors.user_id}
                            />
                        </Grid>
                    }
                </Grid>
                <MDBox mt={3} bgColor="#f0f2f5" p={2} borderRadius={3} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <MDBox>
                        <MDTypography variant="h5">PPW</MDTypography>
                        {
                            (totalV > 0 && moduleSizeV > 0 && moduleQuantityV > 0) ?
                                <MDTypography variant="caption" color="text">${totalV} / ({moduleSizeV} size x {moduleQuantityV} modules)</MDTypography> :
                                <MDTypography variant="caption" color="text">Enter the fields above to calculate the
                                    ppw</MDTypography>
                        }
                    </MDBox>
                    {
                        (totalV > 0 && moduleSizeV > 0 && moduleQuantityV) > 0 &&
                        <MDTypography color="secondary" variant="h3">${(totalV / (moduleSizeV * moduleQuantityV)).toFixed(2)}</MDTypography>
                    }
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default SaleInfo;
