/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import checkout from "./form";

const {
    formField: {
        name,
        email,
        address,
        city_id,
        phone,
        power_company,
        years_in_property,
        highest_monthly_bill,
        lowest_monthly_bill,
        credit_score,
    }
} = checkout;

const initialValues = {
    [name.name]: "",
    [email.name]: "",
    [address.name]: "",
    [city_id.name]: -1,
    [phone.name]: "",
    [power_company.name]: "",
    [years_in_property.name]: "",
    [highest_monthly_bill.name]: "",
    [lowest_monthly_bill.name]: "",
    [credit_score.name]: "",
};

export default initialValues;
