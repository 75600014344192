/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-reference-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    address: {
      name: "address",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      placeholder: "john@email.com",
      invalidMsg: "Email address is invalid",
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "text",
      placeholder: "9560000000",
      errorMsg: "Phone is required.",
      invalidMsg: "Phone must have 10 digits"
    }
  },
};

export default form;
