import React, {useState} from "react";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useInvoices = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/invoices/new-invoice"),
        edit: (id) => navigate(`/invoices/${id}/edit-invoice`),
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 'Open':
                return <Chip size="small" color="primary" label="Open"
                             variant="outlined"/>;
            case 'Pending':
                return <Chip size="small" color="warning" label="Pending"
                             variant="outlined"/>;
            case 'Paid':
                return <Chip size="small" color="secondary" label="Paid"
                             variant="outlined"/>;
            case 'Cancelled':
                return <Chip size="small" color="warning" label="Cancelled"
                             variant="outlined"/>;
        }
    };

    const renderType = (type) => {
        switch (type) {
            case '1':
                return 'Type A';
            case '2':
                return 'Type B';
            case '3':
                return 'Type C';
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip, renderType
    }
};

export default useInvoices;
