/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import React, {useState} from "react";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validations from "./schemas/validations";
import MDTypography from "../../../../components/MDTypography";
import NewTicketForm from "./components/NewTicketForm";
import {API_URL} from "../../../../config";
import axios from 'axios';
import BaseLayout from "../components/BaseLayout";

// Settings page components

function NewTicket() {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/tickets`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Support Ticket saved successfully',
                icon: 'notifications',
                title: 'Support Tickets',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Support Tickets',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return (
        <BaseLayout>
            {/*<MDBox mt={4}>*/}
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <MDBox mb={3}>
                            <Card id="new-user" sx={{overflow: "visible"}}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={currentValidation}
                                    onSubmit={handleSubmit}
                                >
                                    {({values, errors, touched, isSubmitting}) => (
                                        <Form id={formId} autoComplete="off">
                                            <MDBox p={3}>
                                                <MDTypography variant="h5">New Support Ticket</MDTypography>
                                                <MDTypography variant="body2">How can we help you? .</MDTypography>
                                            </MDBox>
                                            <MDBox pb={3} px={3}>
                                                <NewTicketForm formData={{values, touched, formField, errors}}/>
                                                <MDBox mt={2} width="100%" display="flex"
                                                       justifyContent="flex-end">
                                                    <MDBox>
                                                        <MDButton
                                                            disabled={isSubmitting}
                                                            variant="gradient"
                                                            color="secondary"
                                                            type="submit"
                                                        >
                                                            {isSubmitting &&
                                                            <CircularProgress size={18} color="white"
                                                                              sx={{marginRight: 1}}/>}
                                                            Create
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>

                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </MDBox>
                    </Grid>
                </Grid>
            {/*</MDBox>*/}
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
}

export default NewTicket;
