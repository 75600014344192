/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import checkout from "./form";

const {
    formField: {
        lead_id,
        module_size,
        module_quantity,
        total,
        has_secondary_seller,
        user_id,
        down_payment,
        utility_rebate,
        ad_bonus,
        finance_fee,
        apr_rate,
        bank_id,
        invoice_item_id,
        total_with_adders,
        adder_1,
        adder_2,
        adder_3,
        adder_4
    }
} = checkout;

const initialValues = {
    [lead_id.name]: null,
    [module_size.name]: 410,
    [module_quantity.name]: 0,
    [total.name]: 0,
    [has_secondary_seller.name]: false,
    [user_id.name]: -1,
    [down_payment.name]: 0,
    [utility_rebate.name]: 0,
    [ad_bonus.name]: 0,
    [bank_id.name]: -1,
    [finance_fee.name]: 0,
    [apr_rate.name]: 0,
    [total_with_adders.name]: 0,
    [adder_1.name]: 0,
    [adder_2.name]: 0,
    [adder_3.name]: 0,
    [adder_4.name]: 0,
    [invoice_item_id.name]: -1,
};

export default initialValues;
