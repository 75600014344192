/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "edit-adders-form",
  formField: {
    adders_comission: {
      name: "adders_comission",
      label: "Adders Commission",
      type: "number",
      errorMsg: "Adder's comission is required.",
      invalidMsg: "Must be greater than 0."
    },
    adder_1_used: {
      name: "adder_1_used",
      label: "MPU Used Amount",
      type: "number",
      errorMsg: "Adder 1 used amount is required.",
      invalidMsg: "Must be greater than 0."
    },
    adder_2_used: {
      name: "adder_2_used",
      label: "City Permit Used Amount",
      type: "number",
      errorMsg: "Adder 2 used amount is required.",
      invalidMsg: "Must be greater than 0."
    },
    adder_3_used: {
      name: "adder_3_used",
      label: "Microinverter Used Amount",
      type: "number",
      errorMsg: "Adder 3 used amount is required.",
      invalidMsg: "Must be greater than 0."
    },
    adder_4_used: {
      name: "adder_4_used",
      label: "Adder 4 Used Amount",
      type: "number",
      errorMsg: "Adder 4 used amount is required.",
      invalidMsg: "Must be greater than 0."
    },
  },
};

export default form;
