/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// NewProduct page components
import {Form, Formik} from "formik";

import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import CircularProgress from "@mui/material/CircularProgress";
import NewSaleForm from "./components/SaleInfo";
import axios from "axios";
import {API_URL} from "../../../../config";
import MDSnackbar from "../../../../components/MDSnackbar";
import moment from "moment";
import Stepper from "@mui/material/Stepper/Stepper";
import Step from "@mui/material/Step/Step";
import StepLabel from "@mui/material/StepLabel";
import SaleInfo from "./components/SaleInfo";
import FinanceInfo from "./components/FinanceInfo";
import AddersInfo from "./components/AddersInfo";
import {useSelector} from "react-redux";

function getSteps() {
    return ["1. Sale Info", "2. Finance Info", "3. Adders"];
}

function getStepContent(stepIndex, formData, leads, epc_rate, sellers, banks, invoiceItems) {
    switch (stepIndex) {
        case 0:
            return <SaleInfo formData={formData} leads={leads} sellers={sellers}/>;
        case 1:
            return <FinanceInfo formData={formData} epcRate={epc_rate} banks={banks}/>;
        case 2:
            return <AddersInfo formData={formData} epcRate={epc_rate} invoiceItems={invoiceItems}/>;
        default:
            return null;
    }
}

function NewSale() {

    const authState = useSelector((state) => state.authReducer);

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const {formId, formField} = form;
    const currentValidation = validations[activeStep];

    const isLastStep = activeStep === steps.length - 1;

    const [leads, setLeads] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [banks, setBanks] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);

    const [user, setUser] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadLeads();
            await loadUser();
            await loadSellers();
            await loadBanks();
            await loadInvoiceItems();
            setIsLoading(false);
        };

        init();

    }, []);

    const loadInvoiceItems = async () => {

        try {

            const response = await axios.get(`${API_URL}/invoice_items`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true,
                    selectable: true
                }
            });

            if(response.data.length > 0)
                setInvoiceItems([{id: -1, label: 'Choose an adder'}, ...response.data.map(item => ({id: item.id, label: item.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the adder items",
                icon: 'cancel',
                title: 'Adders',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadBanks = async () => {

        try {

            const response = await axios.get(`${API_URL}/banks`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setBanks([{id: -1, label: 'Choose a bank'}, ...response.data.map(bank => ({id: bank.id, label: bank.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the cities",
                icon: 'cancel',
                title: 'Banks',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadLeads = async () => {

        try {

            const response = await axios.get(`${API_URL}/leads`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setLeads(response.data.map(lead => ({id: lead.id, label: lead.name})));

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading your leads",
                icon: 'cancel',
                title: 'Leads',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadUser = async () => {

        try {

            const response = await axios.get(`${API_URL}/users/profile`);

            setUser(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading your profile",
                icon: 'cancel',
                title: 'Authentication',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadSellers = async () => {

        try {

            const response = await axios.get(`${API_URL}/users`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true,
                    filters: {
                        role_id: [2],
                    },
                    except: {
                        id: [authState.userId]
                    }
                }
            });

            if(response.data.length > 0)
                setSellers([{id: -1, label: 'Choose an option'}, ...response.data.map(lead => ({id: lead.id, label: lead.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the sellers",
                icon: 'cancel',
                title: 'Sellers',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const submitForm = async (values, actions) => {

        let newValues = {...values};

        try {

            newValues.epc_rate = user.epc_rate;

            if(!newValues.has_secondary_seller) {
                delete newValues.user_id;
            }

            if(newValues.invoice_item_id == -1 && newValues.adder_4 == 0){
                delete newValues.invoice_item_id;
            }

            // Enviar datos a API
            await axios.post(
                `${API_URL}/sales`,
                {...newValues}
            );

            actions.resetForm();

            setActiveStep(0);

            setSnackbarConfig({
                message: 'New sale saved successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'notifications',
                title: 'Sales',
                color: 'warning'
            });

            setShow(true);
        }

        actions.setSubmitting(false);
    };

    const handleBack = () => setActiveStep(activeStep - 1);

    const handleSubmit = (values, actions) => {

        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={3} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={currentValidation}
                                    onSubmit={handleSubmit}
                                >
                                    {({values, errors, touched, isSubmitting}) => (
                                        <Form id={formId} autoComplete="off">
                                            <MDBox mt={6} mb={8} textAlign="center">
                                                <MDBox mb={1}>
                                                    <MDTypography variant="h3" fontWeight="bold">
                                                        New Sale
                                                    </MDTypography>
                                                </MDBox>
                                                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                                                    Enter the new sale details
                                                </MDTypography>
                                            </MDBox>
                                            <Card>
                                                <MDBox mt={-3} mb={3} mx={2}>
                                                    <Stepper activeStep={activeStep} alternativeLabel>
                                                        {steps.map((label) => (
                                                            <Step key={label}>
                                                                <StepLabel>{label}</StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </MDBox>
                                                <MDBox p={2}>
                                                    <MDBox>
                                                        {getStepContent(activeStep, {
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors,
                                                        }, leads, user.epc_rate, sellers, banks, invoiceItems)}
                                                        <MDBox mt={3} width="100%" display="flex"
                                                               justifyContent="space-between">
                                                            {activeStep === 0 ? (
                                                                <MDBox/>
                                                            ) : (
                                                                <MDButton variant="gradient" color="light" onClick={handleBack}>
                                                                    back
                                                                </MDButton>
                                                            )}
                                                            <MDBox>
                                                                <MDButton
                                                                    disabled={isSubmitting}
                                                                    variant="gradient"
                                                                    color="secondary"
                                                                    type="submit"
                                                                >
                                                                    { isSubmitting && <CircularProgress size={18} color="white" sx={{marginRight: 1}} /> }
                                                                    {isLastStep ? "submit" : "next"}
                                                                </MDButton>
                                                            </MDBox>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </Card>
                                        </Form>
                                    )}
                                </Formik>
                        }
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
}

export default NewSale;
