/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import checkout from "./form";

const {
    formField: {
        adders_comission,
        adder_1_used,
        adder_2_used,
        adder_3_used,
        adder_4_used
    },
} = checkout;

const initialValues = {
    [adders_comission.name]: "",
    [adder_1_used.name]: "",
    [adder_2_used.name]: "",
    [adder_3_used.name]: "",
    [adder_4_used.name]: "",
};

export default initialValues;
