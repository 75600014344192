/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import FormField from "../../../../../../components/FormField";
import MDBox from "../../../../../../components/MDBox";

// Material Dashboard 2 PRO React components


function NewCompanyForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {company_name, contact_name, contact_email, contact_phone, address, state, city, zip_code} = formField;
    const {
        company_name: companyNameV,
        contact_name: contactNameV,
        contact_email: contactEmailV,
        contact_phone: contactPhoneV,
        address: addressV,
        city: cityV,
        state: stateV,
        zip_code: zipCodeV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={company_name.type}
                        label={company_name.label}
                        name={company_name.name}
                        value={companyNameV}
                        error={errors.company_name && touched.company_name}
                        success={companyNameV.length > 0 && !errors.company_name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={contact_name.type}
                        label={contact_name.label}
                        name={contact_name.name}
                        value={contactNameV}
                        error={errors.contact_name && touched.contact_name}
                        success={contactNameV.length > 0 && !errors.contact_name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={contact_email.type}
                        label={contact_email.label}
                        name={contact_email.name}
                        value={contactEmailV}
                        error={errors.contact_email && touched.contact_email}
                        success={contactEmailV.length > 0 && !errors.contact_email}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={contact_phone.type}
                        label={contact_phone.label}
                        name={contact_phone.name}
                        value={contactPhoneV}
                        error={errors.contact_phone && touched.contact_phone}
                        success={contactPhoneV.length > 0 && !errors.contact_phone}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={address.type}
                        label={address.label}
                        name={address.name}
                        value={addressV}
                        error={errors.address && touched.address}
                        success={addressV.length > 0 && !errors.address}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={city.type}
                        label={city.label}
                        name={city.name}
                        value={cityV}
                        error={errors.city && touched.city}
                        success={cityV.length > 0 && !errors.city}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={state.type}
                        label={state.label}
                        name={state.name}
                        value={stateV}
                        error={errors.state && touched.state}
                        success={stateV.length > 0 && !errors.state}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={zip_code.type}
                        label={zip_code.label}
                        name={zip_code.name}
                        value={zipCodeV}
                        error={errors.zip_code && touched.zip_code}
                        success={zipCodeV.length > 0 && !errors.zip_code}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewCompanyForm;
