/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-deduction-form",
  formField: {
    motive: {
      name: "motive",
      label: "Motive",
      type: "text",
      errorMsg: "Motive is required.",
    },
    amount: {
      name: "amount",
      label: "Amount",
      type: "number",
      errorMsg: "Amount is required.",
    },
    seller_id: {
      name: "seller_id",
      label: "Seller",
      type: "select",
      errorMsg: "Seller is required.",
    }
  },
};

export default form;
