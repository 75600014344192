/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../../components/FormField";


function BasicInfoForm({formData, cities}) {

    const {formField, values, errors, touched} = formData;

    const {name, email, phone, address, social_security_number, id_number, city_id} = formField;
    const {
        name: nameV,
        email: emailV,
        phone: phoneV,
        address: addressV,
        social_security_number: socialSecurityNumberV,
        id_number: idNumberV,
        city_id: cityIdV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        placeholder={email.placeholder}
                        error={errors.email && touched.email}
                        success={emailV && emailV.length > 0 && !errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={phone.type}
                        label={phone.label}
                        name={phone.name}
                        value={phoneV}
                        placeholder={phone.placeholder}
                        error={errors.phone && touched.phone}
                        success={phoneV && phoneV.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={address.type}
                        label={address.label}
                        name={address.name}
                        value={addressV}
                        placeholder={address.placeholder}
                        error={errors.address && touched.address}
                        success={addressV && addressV.length > 0 && !errors.address}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={city_id.type}
                        label={city_id.label}
                        name={city_id.name}
                        value={cityIdV}
                        options={cities}
                        error={errors.city_id && touched.city_id}
                        success={cityIdV && cityIdV.length > 0 && !errors.city_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={social_security_number.type}
                        label={social_security_number.label}
                        name={social_security_number.name}
                        value={socialSecurityNumberV}
                        placeholder={social_security_number.placeholder}
                        error={errors.social_security_number && touched.social_security_number}
                        success={ socialSecurityNumberV && socialSecurityNumberV.length > 0 && !errors.social_security_number}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={id_number.type}
                        label={id_number.label}
                        name={id_number.name}
                        value={idNumberV}
                        placeholder={id_number.placeholder}
                        error={errors.id_number && touched.id_number}
                        success={ idNumberV && idNumberV.length > 0 && !errors.id_number}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default BasicInfoForm;
