/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";


function EnergyConsumptionInfo({formData}) {

    const {formField, values, errors, touched} = formData;
    const {power_company, years_in_property, highest_monthly_bill, lowest_monthly_bill} = formField;
    const {
        power_company: power_companyV,
        years_in_property: years_in_propertyV,
        highest_monthly_bill: highest_monthly_billV,
        lowest_monthly_bill: lowest_monthly_billV
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Energy Consumption Information</MDTypography>
            <MDTypography variant="body2">Lets add some basic energy consumption information in order to better
                understand the lead's needs.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={power_company.type}
                            label={power_company.label}
                            name={power_company.name}
                            value={power_companyV}
                            placeholder={power_company.placeholder}
                            error={errors.power_company && touched.power_company}
                            success={power_companyV.length > 0 && !errors.power_company}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={years_in_property.type}
                            label={years_in_property.label}
                            name={years_in_property.name}
                            value={years_in_propertyV}
                            placeholder={years_in_property.placeholder}
                            error={errors.years_in_property && touched.years_in_property}
                            success={years_in_propertyV.length > 0 && !errors.years_in_property}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={lowest_monthly_bill.type}
                            label={lowest_monthly_bill.label}
                            name={lowest_monthly_bill.name}
                            value={lowest_monthly_billV}
                            placeholder={lowest_monthly_bill.placeholder}
                            error={errors.lowest_monthly_bill && touched.lowest_monthly_bill}
                            success={lowest_monthly_billV.length > 0 && !errors.lowest_monthly_bill}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={highest_monthly_bill.type}
                            label={highest_monthly_bill.label}
                            name={highest_monthly_bill.name}
                            value={highest_monthly_billV}
                            placeholder={highest_monthly_bill.placeholder}
                            error={errors.highest_monthly_bill && touched.highest_monthly_bill}
                            success={highest_monthly_billV.length > 0 && !errors.highest_monthly_bill}
                        />
                    </Grid>
                </Grid>
                <MDBox mt={3} bgColor="#f0f2f5" p={2} borderRadius={3} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <MDBox>
                        <MDTypography variant="h5">Amount Spent</MDTypography>
                        {
                            (years_in_propertyV && highest_monthly_billV && lowest_monthly_billV) ?
                                <MDTypography variant="caption" color="text">({years_in_propertyV} years x 12 months) x
                                    ((${highest_monthly_billV} + ${lowest_monthly_billV}) / 2)</MDTypography> :
                                <MDTypography variant="caption" color="text">Enter the fields above to calculate the
                                    amount spent</MDTypography>
                        }
                    </MDBox>
                    {
                        (years_in_propertyV && highest_monthly_billV && lowest_monthly_billV) &&
                        <MDTypography color="secondary" variant="h3">${((years_in_propertyV * 12) * ((highest_monthly_billV + lowest_monthly_billV) / 2)).toFixed(2)}</MDTypography>
                    }
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default EnergyConsumptionInfo;
