import React, {useState} from "react";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useCompanies = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/companies/new-company"),
        edit: (id) => navigate(`/companies/${id}/edit-company`),
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 1:
                return <Chip size="small" color="primary" label="Active"
                             variant="outlined"/>;
            case 0:
                return <Chip size="small" color="warning" label="Inactive"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip
    }
};

export default useCompanies;
