
import {API_URL, APP_URL} from "../config";
import axios from "axios";

axios.defaults.withCredentials = true;

// TODO authaxios
export const storeLead = async (token, data) => {
    await axios.get(`${APP_URL}/sanctum/csrf-cookie`);

    return axios.post(`${API_URL}/leads`, data);
};

export const getLeads = async (token, options) => {
    await axios.get(`${APP_URL}/sanctum/csrf-cookie`);

    return axios.get(`${API_URL}/leads`, options);
};
