import React, {useState} from "react";
import Card from "@mui/material/Card";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import moment from "moment";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import MDButton from "../../../../../../components/MDButton";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from "@mui/material/ListItemIcon";
import ClearIcon from '@mui/icons-material/Clear';
import UpdateIcon from '@mui/icons-material/Update';
import ListItemText from "@mui/material/ListItemText";


const AppointmentDetails = ({data, isSubmitting, onCancelClick, onDoneClick, onRescheduleClick}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderPhone = (phone) => {

        if(!phone)
            return '';

        return `(${phone.substring(0,3)}) ${phone.substring(3,6)}-${phone.substring(6,10)}`
    };

    return <Card sx={{height: "100%"}}>
        <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
                {data.title}
            </MDTypography>
            <MDTypography variant="body2">
                {moment(data.start).format('dddd, MMM DD [at] h:mm A')}
            </MDTypography>
        </MDBox>
        {
            data.notes &&
            <MDBox px={2} py={0}>
                <MDTypography variant="button" style={{fontStyle: 'italic'}} fontWeight="medium">
                    "{data.notes}"
                </MDTypography>
            </MDBox>
        }
        <MDBox pr={2} pl={2} pt={1} pb={data.status !== 'pending' && 2}>
            <MDBox display="flex" flexDirection="row" alignItems="flex-start" mb={1}>
                <Icon color="secondary" style={{marginTop: 1}}>email</Icon>
                <MDTypography variant="button" ml={1}>
                    {data.email}
                </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" alignItems="flex-start" mb={1}>
                <Icon color="secondary" style={{marginTop: 1}}>phone</Icon>
                <MDTypography variant="button" ml={1}>
                    {renderPhone(data.phone)}
                </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="row" alignItems="flex-start">
                <Icon color="secondary" style={{marginTop: 1}}>home</Icon>
                <MDTypography variant="button" ml={1}>
                    {data.address}
                </MDTypography>
            </MDBox>
        </MDBox>
        <Divider/>
        {
            data.status === 'pending' ?
                <MDBox pb={2} pl={2} pr={2}>
                    <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
                        <MDBox ml={1} p={0} mr={2} flex={1}>
                            <MDButton variant="outlined" size="small" color="success" ml={2} onClick={onDoneClick} fullWidth
                                      disabled={isSubmitting}>
                                <Icon>check</Icon>&nbsp;
                                Done
                            </MDButton>
                        </MDBox>
                        <IconButton
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>
                    </MDBox>
                </MDBox> :
                <MDBox pb={2} pl={2} pr={2}>
                    <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        {
                            data.status === 'done' ?
                                <>
                                    <Icon color="success">check_circle</Icon>
                                    <MDTypography variant="h6" ml={1} color="success">
                                        Done
                                    </MDTypography>
                                </> :
                                <>
                                    <Icon color="warning">cancel</Icon>
                                    <MDTypography variant="h6" ml={1} color="warning">
                                        Cancelled
                                    </MDTypography>
                                </>
                        }
                    </MDBox>
                </MDBox>
        }
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={onRescheduleClick}>
                <ListItemIcon>
                    <UpdateIcon fontSize="small" />
                </ListItemIcon>
                <MDTypography variant="button" fontWeight="regular">Reschedule Appointment</MDTypography>
            </MenuItem>
            <MenuItem onClick={onCancelClick}>
                <ListItemIcon>
                    <ClearIcon fontSize="small" color="error" />
                </ListItemIcon>
                <MDTypography variant="button" fontWeight="regular" color="error">Cancel Appointment</MDTypography>
            </MenuItem>
        </Menu>
    </Card>
};

export default AppointmentDetails;
