import React, {useState} from "react";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useInvoiceItems = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/invoice-items/new-invoice-item"),
        edit: (id) => navigate(`/invoice-items/${id}/edit-invoice-item`),
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 1:
                return <Chip size="small" color="primary" label="Active"
                             variant="outlined"/>;
            case 0:
                return <Chip size="small" color="warning" label="Inactive"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip
    }
};

export default useInvoiceItems;
