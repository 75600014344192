/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import DefaultItem from "examples/Items/DefaultItem";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import {capitalizeFirstLetter} from "../../../../../../helpers/helpers";
import MDButton from "../../../../../../components/MDButton";

const Status = ({isCurrent, value}) => {
    return <MDTypography variant="button" color={isCurrent ? "secondary" : "text"} fontWeight={isCurrent ? 'medium' : 'regular'}>
        {capitalizeFirstLetter(value)}
    </MDTypography>
};

function Header({ name, createdAt, status, handleNextClick, statusButtonLabel, children, statusButtonDisabled, secondarySeller }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          position: "relative",
          mt: 2,
          mx: 3,
          px: 2,
        }}
      >
          {
              status !== 'cancelled' &&
              <Grid container spacing={3} alignItems="center" pt={2}>
                  <Grid display="flex" item md={1.5}>
                      <Status value="draft" isCurrent={status === 'draft'} />
                      <Icon color="text" style={{marginLeft: 8}}>arrow_forward</Icon>
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      <Status value="agreement" isCurrent={status === 'agreement'} />
                      <Icon color="text" style={{marginLeft: 8}}>arrow_forward</Icon>
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      <Status value="reviewed" isCurrent={status === 'reviewed'} />
                      <Icon color="text" style={{marginLeft: 8}}>arrow_forward</Icon>
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      <Status value="financed" isCurrent={status === 'financed'} />
                      <Icon color="text" style={{marginLeft: 8}}>arrow_forward</Icon>
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      <Status value="site visit" isCurrent={status === 'site visit'} />
                      <Icon color="text" style={{marginLeft: 8}}>arrow_forward</Icon>
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      <Status value="installing" isCurrent={status === 'installing'} />
                      <Icon color="text" style={{marginLeft: 8}}>arrow_forward</Icon>
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      <Status value="done" isCurrent={status === 'done'} />
                  </Grid>
                  <Grid display="flex" item md={1.5}>
                      {
                          status !== 'done' &&
                          <MDButton disabled={statusButtonDisabled} size="small" fullWidth color="secondary" onClick={handleNextClick}>{statusButtonLabel}</MDButton>
                      }
                  </Grid>
              </Grid>
          }
          <Divider/>
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={12} md={9}>
            <MDBox height="100%" mt={0.5} lineHeight={1} ml={2}>
              <MDTypography variant="h5" fontWeight="medium">
                  {name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                  {createdAt}
              </MDTypography>
            </MDBox>
          </Grid>
            <Grid item sm={12} md={3}>
                <MDBox mt={0.5} lineHeight={1} ml={2}>
                    {
                        secondarySeller &&
                        <DefaultItem icon="person_add" color="secondary" title={secondarySeller} description="Secondary Seller"/>
                    }
                </MDBox>
            </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
