/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import {useFormikContext} from "formik";
import {Icon} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "../../../../../../components/MDBox";
import FormField from "../../../../../../components/FormField";

// Material Dashboard 2 PRO React components


function NewPaymentForm({formData, invoices}) {

    const formikProps = useFormikContext();

    const {formField, values, errors, touched} = formData;

    const {payment_method, amount, paid_at, invoice_id} = formField;
    const {
        payment_method: paymentMethodV,
        amount: amountV,
        paid_at: paidAtV,
        invoice_id: invoiceIdV,
    } = values;

    const handleSaleBlur = (id) => {

        let invoice = invoices.find(invoice => invoice.id === id);

        formikProps.setFieldValue(`amount`, invoice.pending);
    };

    return (
        <MDBox>
            <Grid container spacing={3}>
                {
                    formField.invoice_id &&
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={invoice_id.type}
                            label={invoice_id.label}
                            name={invoice_id.name}
                            value={invoiceIdV}
                            options={invoices}
                            error={errors.invoice_id && touched.invoice_id}
                            success={invoiceIdV && invoiceIdV.length > 0 && !errors.invoice_id}
                            onBlur={() => handleSaleBlur(invoiceIdV)}
                        />
                    </Grid>
                }

                <Grid item xs={12} sm={12}>
                    <FormField
                        type={payment_method.type}
                        label={payment_method.label}
                        name={payment_method.name}
                        value={paymentMethodV}
                        options={payment_method.options}
                        error={errors.payment_method && touched.payment_method}
                        success={paymentMethodV.length > 0 && !errors.payment_method}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={amount.type}
                        label={amount.label}
                        name={amount.name}
                        value={amountV}
                        error={errors.amount && touched.amount}
                        success={amountV && amountV.length > 0 && !errors.amount}
                        InputProps={{startAdornment: <InputAdornment disableTypography position="start">$</InputAdornment>}}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={paid_at.type}
                        label={paid_at.label}
                        name={paid_at.name}
                        value={paidAtV}
                        options={{disableMobile: true, dateFormat: 'Y-m-d'}}
                        error={errors.paid_at && touched.paid_at}
                        success={paidAtV.length > 0 && !errors.paid_at}
                        InputProps={{startAdornment: <InputAdornment disableTypography position="start"><Icon>calendar_today</Icon></InputAdornment>}}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewPaymentForm;
