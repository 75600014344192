import React from "react";
import Card from "@mui/material/Card";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Icon from "@mui/material/Icon";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import DataTable from "../../../../examples/DataTable/DataTable";
import {API_URL} from "../../../../config";
import {renderPaymentMethod} from "../../../../helpers/helpers";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import Footer from "../../../../examples/Footer";
import usePayments from "./usePayments";

const Payments = () => {

    const {actions, filters, setFilters, renderStatusChip} = usePayments();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        <DataTable
                            endpoint={`${API_URL}/payments`}
                            title="Payments"
                            description="View and manage all the invoices payments."
                            sortBy="paid_at"
                            sortDirection="desc"
                            filters={filters}
                            actions={actions}
                            colData={[
                                {
                                    name: 'id',
                                    label: 'ID',
                                    options: {
                                        display: 'hidden'
                                    }
                                },
                                {
                                    name: 'invoice.folio',
                                    label: 'Invoice',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <span
                                                     style={{fontWeight: 500}}
                                            >
                                                {value}
                                            </span>
                                        )
                                    }
                                },
                                {
                                    name: 'invoice.company.company_name',
                                    label: 'Company',
                                },
                                {
                                    name: 'amount',
                                    label: 'Amount',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return <Chip icon={<Icon style={{marginLeft: 8}}>paid</Icon>} size="small" color="secondary" label={`$${value}`}
                                                         variant="outlined"/>
                                        }
                                    }
                                },
                                {
                                    name: 'payment_method',
                                    label: 'Payment Method',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => renderPaymentMethod(value),
                                        filterType: 'dropdown',
                                        filterOptions: {
                                            names: [1, 2, 3, 4, 5],
                                            renderValue: value => {
                                               switch(value){
                                                   case '1':
                                                       return 'Check';
                                                   case '2':
                                                       return 'Cash';
                                                   case '3':
                                                       return 'Credit Card';
                                                   case '4':
                                                       return 'Debit Card';
                                                   case '5':
                                                       return 'Electronic Transfer';
                                               }
                                            }
                                        },
                                        customFilterListOptions: {
                                            render: value => {
                                                switch(value){
                                                    case '1':
                                                        return 'Check';
                                                    case '2':
                                                        return 'Cash';
                                                    case '3':
                                                        return 'Credit Card';
                                                    case '4':
                                                        return 'Debit Card';
                                                    case '5':
                                                        return 'Electronic Transfer';
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    name: 'paid_at',
                                    label: 'Paid At',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                                        filter: true,
                                        filterType: 'custom',
                                        customFilterListOptions: {
                                            render: v => {
                                                if (v[0] && v[1]) {
                                                    return `From: ${v[0]}, To: ${v[1]}`;
                                                } else if (v[0]) {
                                                    return `From: ${v[0]}`;
                                                } else if (v[1]) {
                                                    return `To: ${v[1]}`;
                                                }
                                                return false;
                                            },
                                            update: (filterList, filterPos, index) => {

                                                if (filterPos === 0) {
                                                    filterList[index].splice(filterPos, 1, '');
                                                } else if (filterPos === 1) {
                                                    filterList[index].splice(filterPos, 1);
                                                } else if (filterPos === -1) {
                                                    filterList[index] = [];
                                                }

                                                let newFilters = () => (filterList);
                                                setFilters(newFilters);

                                                // return filterList;
                                            },
                                        },
                                        filterOptions: {
                                            names: [],
                                            logic(date, filters) {
                                                if (filters[0] && filters[1]) {
                                                    return date < filters[0] || date > filters[1];
                                                } else if (filters[0]) {
                                                    return date < filters[0];
                                                } else if (filters[1]) {
                                                    return date > filters[1];
                                                }
                                                return false;
                                            },
                                            display: (filterList, onChange, index, column) => (
                                                <div>
                                                    <MDTypography variant="button"
                                                                  fontWeight="regular">Paid At</MDTypography>
                                                    <FormGroup row style={{marginTop: 10}}>
                                                        <MDInput
                                                            label="From"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][0] || ''}
                                                            onChange={event => {
                                                                filterList[index][0] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '45%', marginRight: '5%'}}
                                                        />
                                                        <MDInput
                                                            label="To"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][1] || ''}
                                                            onChange={event => {
                                                                filterList[index][1] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '50%'}}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ),
                                        },
                                    }
                                },
                                {
                                    name: 'status',
                                    label: 'Status',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return renderStatusChip(value)
                                        },
                                        filterType: 'dropdown',
                                        filterOptions: {
                                            names: ['Paid', 'Cancelled'],
                                        },
                                    }
                                }
                            ]}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
};

export default Payments;
