import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import React, {useRef, useState} from "react";

import logoCT from "assets/images/logo.png";
import logoCTDark from "assets/images/logo.png";
import Chip from "@mui/material/Chip";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import MDButton from "../../../../../../components/MDButton";
import html2canvas from "html2canvas";
import {jsPDF} from 'jspdf';
import axios from "axios";
import {API_URL} from "../../../../../../config";
import MDSnackbar from "../../../../../../components/MDSnackbar";

const Overview = ({invoice}) => {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const printRef = useRef();

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(invoice.folio + '.pdf');
    };

    const handleSendPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');


        try {
            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/invoices/${invoice.id}/send`,
                {
                    pdf: data
                }
            );

            setSnackbarConfig({
                message: 'Invoice sent successfully',
                icon: 'notifications',
                title: 'Invoices',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Invoices',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const borderBottom = {
        borderBottom: ({borders: {borderWidth}, palette: {light}}) =>
            `${borderWidth[1]} solid ${light.main}`,
    };

    const renderInvoiceItems = () => {
        return invoice.invoice_items.map(invoiceItem => (
            <TableRow>
                <MDBox component="td" textAlign="left" p={1}
                       sx={borderBottom}>
                    <MDTypography variant="body2" color="text"
                                  fontWeight="regular">
                        {invoiceItem.amount}
                    </MDTypography>
                </MDBox>
                <MDBox
                    component="td"
                    textAlign="left"
                    py={1}
                    px={1}
                    sx={borderBottom}
                >
                    <MDTypography variant="body2" color="text"
                                  fontWeight="regular">
                        {invoiceItem.description}
                    </MDTypography>
                </MDBox>
                <MDBox
                    component="td"
                    textAlign="left"
                    py={1}
                    px={1}
                    sx={borderBottom}
                >
                    <MDTypography variant="body2" color="text"
                                  fontWeight="regular">
                        ${invoiceItem.unit_price}
                    </MDTypography>
                </MDBox>
                <MDBox
                    component="td"
                    textAlign="left"
                    py={1}
                    px={1}
                    sx={borderBottom}
                >
                    <MDTypography variant="body2" color="text"
                                  fontWeight="regular">
                        ${invoiceItem.total}
                    </MDTypography>
                </MDBox>
            </TableRow>
        ));
    };

    return <Card>
        <MDBox ref={printRef}>
            {/* SaleDetails header */}
            <MDBox p={3}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={6}>
                        <MDTypography variant="h6" fontWeight="medium">
                            {invoice.company.company_name}
                        </MDTypography>
                        <MDTypography variant="h6" fontWeight="regular">
                            Invoice: {invoice.folio}
                        </MDTypography>
                        <MDTypography variant="h5" fontWeight="medium" mt={4}>
                            WEFIX POWER LLC
                        </MDTypography>
                        <MDTypography
                            display="block"
                            variant="body2"
                            color={"secondary"}
                        >
                            Save thousands, saving our future!
                        </MDTypography>
                        <MDBox mb={1} mt={2}>
                            <MDTypography variant="button" color={"text"} style={{display: 'block'}}>4855 Michelle
                                Dr.</MDTypography>
                            <MDTypography variant="button" color={"text"} style={{display: 'block'}}>Brownsville, Tx,
                                78526</MDTypography>
                            <MDTypography variant="button" color={"text"} style={{display: 'block'}}>Phone: (956)
                                336-4243</MDTypography>
                        </MDBox>
                        <MDBox mt={3} mb={2}>
                            <MDTypography
                                display="block"
                                variant="h6"
                                color={"text"}
                            >
                                To: {invoice.company.company_name}
                            </MDTypography>
                            <MDTypography
                                display="block"
                                variant="button"
                                color={"secondary"}
                            >
                                Address: {invoice.company.address}. {invoice.company.city}, {invoice.company.state} {invoice.company.zip_code}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox width="100%" textAlign={{xs: "left", md: "right"}} mt={1}>
                            <MDBox
                                component="img"
                                src={logoCT}
                                width="50%"
                                p={1}
                                mb={1}
                            />
                            <MDTypography variant="h5" fontWeight="medium" mt={2}>
                                INVOICE
                            </MDTypography>
                            <MDBox mb={1} mt={4}>
                                <MDTypography variant="button" color={"text"} style={{display: 'block'}}>Invoice
                                    #: {invoice.folio}</MDTypography>
                                <MDTypography variant="button" color={"text"} style={{display: 'block'}}>Packing Slip
                                    #: {invoice.folio}</MDTypography>
                                <MDTypography variant="button" color={"text"}
                                              style={{display: 'block'}}>Date: {invoice.due_at}</MDTypography>
                            </MDBox>
                            <MDBox mt={3} mb={2}>
                                <MDTypography
                                    display="block"
                                    variant="h6"
                                    color={"text"}
                                >
                                    Client: {invoice.client_name}
                                </MDTypography>
                                <MDTypography
                                    display="block"
                                    variant="button"
                                    color={"secondary"}
                                >
                                    Service Address: {invoice.service_address}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
                <MDBox mt={2}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={2}>
                            <MDTypography variant="button" color={"text"} fontWeight="medium"
                                          style={{display: 'block'}}>SALESPERSON</MDTypography>
                            <MDTypography variant="caption" color={"text"}
                                          style={{display: 'block'}}>{invoice.sales_person}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <MDTypography variant="button" color={"text"} fontWeight="medium"
                                          style={{display: 'block'}}>LOAN ID</MDTypography>
                            <MDTypography variant="caption" color={"text"}
                                          style={{display: 'block'}}>{invoice.loan_id}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <MDTypography variant="button" color={"text"} fontWeight="medium"
                                          style={{display: 'block'}}>REQUISITIONER</MDTypography>
                            <MDTypography variant="caption" color={"text"}
                                          style={{display: 'block'}}>{invoice.requisitioner}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <MDTypography variant="button" color={"text"} fontWeight="medium"
                                          style={{display: 'block'}}>DESTINATION</MDTypography>
                            <MDTypography variant="caption" color={"text"}
                                          style={{display: 'block'}}>{invoice.fob_point}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <MDTypography variant="button" color={"text"} fontWeight="medium"
                                          style={{display: 'block'}}>TERMS</MDTypography>
                            <MDTypography variant="caption" color={"text"}
                                          style={{display: 'block'}}>{invoice.terms}</MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>

            {/* Sale table */}
            <MDBox p={3}>
                <MDBox width="100%" overflow="auto">
                    <Table sx={{minWidth: "32rem"}}>
                        <MDBox component="thead">
                            <TableRow>
                                <MDBox
                                    component="th"
                                    px={1}
                                    py={1.5}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="button" color="text"
                                                  fontWeight="medium">
                                        Amount
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="th"
                                    width={{xs: "45%", md: "50%"}}
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="button" color="text"
                                                  fontWeight="medium">
                                        Item
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="th"
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="button" color="text"
                                                  fontWeight="medium">
                                        Unit Price
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="th"
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="button" color="text"
                                                  fontWeight="medium">
                                        Total
                                    </MDTypography>
                                </MDBox>
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {
                                renderInvoiceItems()
                            }
                            <TableRow>
                                <MDBox component="td" textAlign="left" p={1}
                                       sx={borderBottom}/>
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={1}
                                    sx={borderBottom}
                                />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={1}
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5">Total</MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={1}
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5">${invoice.total}</MDTypography>
                                </MDBox>
                            </TableRow>
                        </TableBody>
                    </Table>
                </MDBox>
                <MDTypography mt={4} variant="button" color={"text"} style={{display: 'block', textAlign: 'center'}}>If
                    you have any questions concerning this invoice, contact us through
                    wefixpowerllc@gmail.com</MDTypography>
            </MDBox>

        </MDBox>
        {/* SaleDetails footer */}
        <MDBox p={3} mt={1}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <MDBox
                        width="100%"
                        height={{xs: "auto", md: "100%"}}
                        display="flex"
                        justifyContent={{xs: "flex-start", md: "flex-end"}}
                        alignItems="flex-end"
                        mt={{xs: 2, md: 0}}
                    >
                        <MDButton fullWidth variant="gradient" color="primary"
                                  onClick={() => handleDownloadPdf()}>
                            <Icon style={{marginRight: 8}}>print</Icon>
                            print
                        </MDButton>
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MDBox
                        width="100%"
                        height={{xs: "auto", md: "100%"}}
                        display="flex"
                        justifyContent={{xs: "flex-start", md: "flex-end"}}
                        alignItems="flex-end"
                        mt={{xs: 2, md: 0}}
                    >
                        <MDButton fullWidth variant="gradient" color="secondary"
                                  onClick={() => handleSendPdf()}>
                            <Icon style={{marginRight: 8}}>email</Icon>
                            send
                        </MDButton>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </Card>;
};

export default Overview;
