/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    name,
    email,
      parent_id,
      city_id,
      country_id,
    password,
  }
} = checkout;

const initialValues = {
  [name.name]: "",
  [email.name]: "",
  [parent_id.name]: -1,
  [city_id.name]: -1,
  [country_id.name]: -1,
  [password.name]: "",
};

export default initialValues;
