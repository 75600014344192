import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Footer from "../../../examples/Footer";
import MUIDataTable from "mui-datatables";
import {API_URL} from "../../../config";
import axios from "axios";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Icon from "@mui/material/Icon";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import {NavLink, useNavigate} from "react-router-dom";
import {capitalizeFirstLetter, formatFilters} from "../../../helpers/helpers";
import MDButton from "../../../components/MDButton";
import CustomToolbar from "../../../components/CustomToolbar";
import CustomToolbarSelect from "./components/CustomToolbarSelect";
import MDSnackbar from "../../../components/MDSnackbar";
import {useSelector} from "react-redux";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import MDInput from "../../../components/MDInput";

const Sales = () => {

    const authState = useSelector((state) => state.authReducer);

    let navigate = useNavigate();

    const [endpoint, setEndpoint] = useState(`${API_URL}/sales`);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(null);
    const [count, setCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState({
        name: 'created_at',
        direction: 'desc',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([['Loading data...']]);
    const [columns, setColumns] = useState([
        {
            name: 'id',
            label: 'ID',
        },
        {
            name: 'lead.name',
            label: 'Lead',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <NavLink to={`/sales/${tableMeta.rowData[0]}`} key={`sale-${tableMeta.rowData[0]}`}
                                    style={{fontWeight: 500}}>{value}</NavLink>
                }
            }
        },
        {
            name: 'lead.user.name',
            label: 'Leader',
            options: {
                display: (authState.roleId === 1 || authState.roleId === 4) ? true : 'excluded'
            }
        },
        {
            name: 'total',
            label: 'Total',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (`$${value}`)
            }
        },
        {
            name: 'full_comission',
            label: 'System Comission',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (`$${parseFloat(value).toFixed(2)}`),
                display: (authState.roleId !== 5) ? true : 'excluded'
            }
        },
        {
            name: 'business_comission',
            label: 'Wefix Winnings',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (`$${parseFloat(value).toFixed(2)}`),
                display: (authState.roleId !== 1) ? 'excluded' : true
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => renderStatusChip(value),
                filterType: 'dropdown',
                filterOptions: {
                    names: ['draft', 'agreement', 'reviewed', 'financed', 'site visit', 'installing', 'cancelled', 'rejected', 'done'],
                    renderValue: v => capitalizeFirstLetter(v)
                },
                customFilterListOptions: {
                    render: v => capitalizeFirstLetter(v)
                }
            }
        },
        {
            name: 'created_at',
            label: 'Created at',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return `From: ${v[0]}, To: ${v[1]}`;
                        } else if (v[0]) {
                            return `From: ${v[0]}`;
                        } else if (v[1]) {
                            return `To: ${v[1]}`;
                        }
                        return false;
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        let newFilters = () => (filterList);
                        handleFilterSubmit(newFilters);

                        // return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <MDTypography variant="button" fontWeight="regular">Created At</MDTypography>
                            <FormGroup row style={{marginTop: 10}}>
                                <MDInput
                                    label="From"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{width: '45%', marginRight: '5%'}}
                                />
                                <MDInput
                                    label="To"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{width: '50%'}}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            }
        },
        {
            name: 'approved_at',
            label: 'Reviewed at',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => value && moment(value).format('YYYY-MM-DD'),
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return `From: ${v[0]}, To: ${v[1]}`;
                        } else if (v[0]) {
                            return `From: ${v[0]}`;
                        } else if (v[1]) {
                            return `To: ${v[1]}`;
                        }
                        return false;
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        let newFilters = () => (filterList);
                        handleFilterSubmit(newFilters);

                        // return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <MDTypography variant="button" fontWeight="regular">Reviewed At</MDTypography>
                            <FormGroup row style={{marginTop: 10}}>
                                <MDInput
                                    label="From"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{width: '45%', marginRight: '5%'}}
                                />
                                <MDInput
                                    label="To"
                                    type="date"
                                    variant="standard"
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{width: '50%'}}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            }
        }
    ]);

    useEffect(() => {
        getData(endpoint);
    }, []);

    const getData = async (url) => {

        setIsLoading(true);

        const response = await loadData(url);

        setData(response.data);
        setCount(response.total);
        setPage(response.page);

        setIsLoading(false);
    };

    const loadData = async (url, config = {}) => {

        let fullConfig = {
            page,
            sortOrder,
            rowsPerPage,
            search,
            filters
        };

        if (config.page)
            fullConfig.page = config.page;

        if (config.sortOrder)
            fullConfig.sortOrder = config.sortOrder;

        if (config.rowsPerPage)
            fullConfig.rowsPerPage = config.rowsPerPage;

        if (config.search !== undefined)
            fullConfig.search = config.search;

        if (config.filters)
            fullConfig.filters = config.filters;

        const options = {
            params: {
                sort: fullConfig.sortOrder.name,
                direction: fullConfig.sortOrder.direction,
                page: fullConfig.page,
                per_page: fullConfig.rowsPerPage,
                search: fullConfig.search ? fullConfig.search : undefined,
                filters: fullConfig.filters ? fullConfig.filters : undefined
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data.data,
            total: response.data.total,
            page: response.data.current_page,
        };

    };

    const sort = async (sortOrder) => {
        setIsLoading(true);

        let options = {
            sortOrder,
        };

        const response = await loadData(endpoint, options);

        setData(response.data);
        setSortOrder(sortOrder);

        setIsLoading(false);
    };

    const changePage = async (page) => {
        setIsLoading(true);

        let options = {
            page: page + 1
        };

        const response = await loadData(endpoint, options);

        setPage(response.page);
        setData(response.data);

        setIsLoading(false);
    };

    const changeRowsPerPage = async (rowsPerPage) => {
        setIsLoading(true);

        let options = {
            rowsPerPage
        };

        const response = await loadData(endpoint, options);

        setRowsPerPage(rowsPerPage);
        setData(response.data);

        setIsLoading(false);
    };

    const handleSearch = async (search) => {

        setIsLoading(true);

        let options = {
            search
        };

        const response = await loadData(endpoint, options);

        setSearch(search);
        setCount(response.total);
        setPage(response.page);
        setData(response.data);

        setIsLoading(false);

    };

    const handleFilterSubmit = async (applyFilters) => {
        let filterList = formatFilters(applyFilters(), columns);

        setIsLoading(true);

        let options = {
            filters: filterList,
        };

        const response = await loadData(endpoint, options);

        setCount(response.total);
        setPage(response.page);
        setData(response.data);
        setFilters(filterList);

        setIsLoading(false);

    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 'draft':
                return <Chip size="small" color="secondary" icon={<Icon fontSize="small">lock_open</Icon>} label="Draft"
                             variant="outlined"/>;
            case 'agreement':
                return <Chip size="small" color="warning" icon={<Icon fontSize="small">access_time</Icon>}
                             label="Agreement"
                             variant="outlined"/>;
            case 'reviewed':
                return <Chip size="small" color="primary" icon={<Icon fontSize="small">check</Icon>}
                             label="Reviewed" variant="outlined"/>;
            case 'financed':
                return <Chip size="small" color="primary" icon={<Icon fontSize="small">check</Icon>}
                             label="Financed" variant="outlined"/>;
            case 'site visit':
                return <Chip size="small" color="primary" icon={<Icon fontSize="small">check</Icon>}
                             label="Site Visit" variant="outlined"/>;
            case 'installing':
                return <Chip size="small" color="primary" icon={<Icon fontSize="small">check</Icon>}
                             label="Installing" variant="outlined"/>;
            case 'done':
                return <Chip size="small" color="primary" icon={<Icon fontSize="small">lock_closed</Icon>}
                             label="Done" variant="outlined"/>;
            case 'rejected':
                return <Chip size="small" color="warning" icon={<Icon fontSize="small">cancel</Icon>}
                             label="Rejected" variant="outlined"/>;
            case 'cancelled':
                return <Chip size="small" color="warning" icon={<Icon fontSize="small">cancel</Icon>}
                             label="Cancelled" variant="outlined"/>;
        }
    };

    const handleNewClick = () => {
        navigate("/sales/new-sale");
    };

    const handleCancel = async (id) => {
        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Cambiar estado
            await updateSale(`${endpoint}/${id}`, {status: 'cancelled'});

            setSnackbarConfig({
                message: 'Sale updated successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if (error.response.data.message) {
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'An error occurred cancelling one or more records.',
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleApproval = async (id) => {
        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            await updateSale(`${endpoint}/${id}`, {status: 'waiting'});

            setSnackbarConfig({
                message: 'Sale updated successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if (error.response.data.message) {
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'An error occurred updating one or more records.',
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleReject = async (id) => {
        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Cambiar estado
            await updateSale(`${endpoint}/${id}`, {
                status: 'rejected',
                approved_at: moment().format('YYYY-MM-DD HH:mm:ss')
            });

            setSnackbarConfig({
                message: 'Sale updated successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if (error.response.data.message) {
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'An error occurred cancelling one or more records.',
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleApprove = async (id) => {
        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            await updateSale(`${endpoint}/${id}`, {
                status: 'approved',
                approved_at: moment().format('YYYY-MM-DD HH:mm:ss')
            });

            setSnackbarConfig({
                message: 'Sale updated successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            // console.log(error);

            if (error.response.data.message) {
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'An error occurred updating one or more records.',
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleComplete = async (id) => {
        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            await updateSale(`${endpoint}/${id}`, {status: 'completed', completed_at: moment().format('YYYY-MM-DD')});

            setSnackbarConfig({
                message: 'Sale updated successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if (error.response.data.message) {
                setSnackbarConfig({
                    message: error.response.data.message,
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            } else {
                setSnackbarConfig({
                    message: 'An error occurred updating one or more records.',
                    icon: 'cancel',
                    title: 'Sales',
                    color: 'warning'
                });

                setShow(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const updateSale = async (url, data) => {

        const response = await axios.put(url, data);

        return {
            data: response.data
        };

    };

    const options = {
        elevation: 0,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 25, 50],
        sortOrder: sortOrder,
        download: false,
        print: false,
        enableNestedDataAccess: '.',
        filter: true,
        filterType: 'textField',
        confirmFilters: true,
        customToolbar: () => {
            return <CustomToolbar handleNewClick={handleNewClick}/>;
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect roleId={authState.roleId} selectedRows={selectedRows} displayData={displayData}
                                 setSelectedRows={setSelectedRows}
                                 handleEditClick={(id) => navigate(`/sales/${id}/edit-sale`)}
                                 handleCancelClick={handleCancel}
            />
        ),
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{marginTop: '40px'}}>
                    <MDButton variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Apply
                        Filters</MDButton>
                </div>
            );
        },
        onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
                let newFilters = () => (filterList);
                handleFilterSubmit(newFilters);
            }
        },
        onSearchChange: handleSearch,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    break;
                case 'sort':
                    sort(tableState.sortOrder);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2}>
                                    <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                null
                        }
                        <MUIDataTable
                            title={
                                <MDBox p={3} lineHeight={1}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Your Sales
                                    </MDTypography>
                                    <MDTypography variant="button" color="text">
                                        Monitor and manage your sales
                                    </MDTypography>
                                </MDBox>
                            }
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
};

export default Sales;
