/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// NewProduct page components
import {Form, Formik} from "formik";

import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import CircularProgress from "@mui/material/CircularProgress";
import MDSnackbar from "../../../../../components/MDSnackbar";
import NewMemberForm from "./components/NewMemberForm";
import axios from "axios";
import {API_URL} from "../../../../../config";

function NewMember() {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const {formId, formField} = form;
    const currentValidation = validations;

    const submitForm = async (values, actions) => {

        try {
            // Enviar datos a API
            await axios.post(
                `${API_URL}/members`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Invite sent successfully',
                icon: 'notifications',
                title: 'Members',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'notifications',
                title: 'Members',
                color: 'warning'
            });

            setShow(true);
        }

        actions.setSubmitting(false);
    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={3} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({values, errors, touched, isSubmitting}) => (
                                <Form id={formId} autoComplete="off">
                                    <MDBox mt={6} mb={8} textAlign="center">
                                        <MDBox mb={1}>
                                            <MDTypography variant="h3" fontWeight="bold">
                                                Invite New Member
                                            </MDTypography>
                                        </MDBox>
                                        <MDTypography variant="h5" fontWeight="regular" color="secondary">
                                            Enter the new member details
                                        </MDTypography>
                                    </MDBox>
                                    <Card>
                                        <MDBox p={3}>
                                            <MDBox>
                                                <NewMemberForm formData={{values, touched, formField, errors}} />
                                                <MDBox mt={3} width="100%">
                                                    <MDBox display="flex" alignItems="flex-end" justifyContent="flex-end">
                                                        <MDButton
                                                            disabled={isSubmitting}
                                                            variant="gradient"
                                                            color="secondary"
                                                            type="submit"
                                                        >
                                                            { isSubmitting && <CircularProgress size={18} color="white" sx={{marginRight: 1}} /> }
                                                            send invite
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
}

export default NewMember;
