/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import React, {useEffect, useState} from "react";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validations from "./schemas/validations";
import MDTypography from "../../../../components/MDTypography";
import {API_URL} from "../../../../config";
import axios from 'axios';
import {useParams} from 'react-router-dom';
import EditLeadForm from "./components/EditLeadForm";
import BaseLayout from "../../../../components/BaseLayout";
import moment from "moment";

// Settings page components

function EditLead() {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);
    const [cities, setCities] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadRecord();
            await loadCities();
            setIsLoading(false);
        };

        init();

    }, []);

    const loadRecord = async () => {

        try {

            const response = await axios.get(`${API_URL}/leads/${id}`);

            setValues(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the lead's details",
                icon: 'cancel',
                title: 'Leads',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadCities = async () => {

        try {

            const response = await axios.get(`${API_URL}/cities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setCities([{id: -1, label: 'Choose a city'}, ...response.data.map(city => ({id: city.id, label: city.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the cities",
                icon: 'cancel',
                title: 'Cities',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleSubmit = async (values, actions) => {

        let newValues = {...values};

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/leads/${id}`,
                {...newValues}
            );

            setSnackbarConfig({
                message: 'Lead updated successfully',
                icon: 'notifications',
                title: 'Leads',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Leads',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return (
        <BaseLayout>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <MDBox mb={3}>
                        <Card id="edit-lead" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Formik
                                        initialValues={values}
                                        validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDTypography variant="h5">Edit Lead</MDTypography>
                                                    <MDTypography variant="body2">Update the lead's
                                                        details.</MDTypography>
                                                </MDBox>
                                                <MDBox pb={3} px={3}>
                                                    <EditLeadForm formData={{values, touched, formField, errors}} cities={cities}/>
                                                    <MDBox mt={2} width="100%" display="flex"
                                                           justifyContent="flex-end">
                                                        <MDBox>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Update
                                                            </MDButton>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>

                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </Card>
                    </MDBox>
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
}

export default EditLead;
