/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import MDButton from "../../../../components/MDButton";

function ProfileInfoCard({ title, info, shadow, buttonLabel, onButtonClick, hasAction = false, hasPermission = false}) {
  // const labels = [];
  // const values = [];

  // // Convert this form `objectKey` of the object key in to this `object key`
  // Object.keys(info).forEach((el) => {
  //
  //
  //   if (el.match(/[A-Z\s]+/)) {
  //     const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
  //     const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);
  //
  //     labels.push(newElement);
  //   } else {
  //     labels.push(el);
  //   }
  // });
  //
  // // Push the object values into the values array
  // Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = info.map((item, key) => (
    <MDBox key={item.label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {item.label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{item.value}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
          {
              hasAction && hasPermission &&
              <MDButton variant="outlined" color="secondary" onClick={onButtonClick}>{buttonLabel}</MDButton>
          }
      </MDBox>
      <MDBox p={2}>
        <MDBox>
          {renderItems}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
