import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import Footer from "../../../../examples/Footer";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {API_URL} from "../../../../config";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import SupportCard from "../../../../examples/Cards/SupportCard";

const VideosLibrary = () => {

    const [endpoint, setEndpoint] = useState(`${API_URL}/support_videos`);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData(endpoint);
    }, []);

    const getData = async (url) => {

        setIsLoading(true);

        const response = await loadData(url);

        setData(response.data);

        setIsLoading(false);
    };

    const loadData = async (url) => {

        const options = {
            params: {
                sort: 'updated_at',
                direction: 'desc',
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data,
        };

    };

    const renderSupportVideos = () => {
        return data.map(video => <Grid key={video.title} item xs={12} md={6} xl={6}>
            <SupportCard
                label="Support video"
                title={video.title}
                description={video.excerp}
                action={{
                    type: "internal",
                    route: `/library/videos/${video.id}`,
                    color: "secondary",
                    label: "read more",
                }}
            />
        </Grid>)
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {
                    isLoading ?
                        <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                               alignItems="center">
                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                        </MDBox> :
                        <Grid container spacing={6}>
                            { renderSupportVideos() }
                        </Grid>
                }
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default VideosLibrary;
