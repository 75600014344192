/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import MDSnackbar from "../../../../../components/MDSnackbar";
import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import axios from "axios";
import {API_URL} from "../../../../../config";
import ChangePasswordForm from "./components/ChangePasswordForm";

function ChangePassword() {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const passwordRequirements = [
        "One special characters",
        "Min 6 characters",
        "One number (2 are recommended)",
        "Change it often",
    ];

    const submitForm = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/users/password`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Password updated successfully',
                icon: 'notifications',
                title: 'Profile',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Profile',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    const renderPasswordRequirements = passwordRequirements.map((item, key) => {
        const itemKey = `element-${key}`;

        return (
            <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
                    {item}
                </MDTypography>
            </MDBox>
        );
    });

    return (
        <>
            <Card id="change-password">
                <MDBox p={3}>
                    <MDTypography variant="h5">Change Password</MDTypography>
                </MDBox>
                <MDBox pb={3} px={3}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <ChangePasswordForm formData={{values, touched, formField, errors}}/>
                                <MDBox mt={3} mb={1}>
                                    <MDTypography variant="h5">Password recommendations</MDTypography>
                                </MDBox>
                                <MDBox mb={1}>
                                    <MDTypography variant="body2" color="text">
                                        Please follow this guide for a strong password
                                    </MDTypography>
                                </MDBox>
                                <MDBox display="flex" justifyContent="space-between" alignItems="flex-end"
                                       flexWrap="wrap">
                                    <MDBox component="ul" m={0} pl={3.25} mb={{xs: 8, sm: 0}}>
                                        {renderPasswordRequirements}
                                    </MDBox>
                                    <MDBox ml="auto">
                                        <MDButton variant="gradient" color="primary" size="small" type="submit" disabled={isSubmitting}>
                                            update password
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}
                    </Formik>
                </MDBox>
            </Card>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </>
    );
}

export default ChangePassword;
