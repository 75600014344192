import React from "react";
import Card from "@mui/material/Card";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Icon from "@mui/material/Icon";
import usePayments from "./usePayments";
import {API_URL} from "../../../../../../config";
import MDBox from "../../../../../../components/MDBox";
import DataTable from "../../../../../../examples/DataTable/DataTable";
import {renderPaymentMethod} from "../../../../../../helpers/helpers";

const Payments = () => {

    const {actions, filters, setFilters, renderStatusChip} = usePayments();

    return (

        <MDBox pt={2} pb={3}>
            <MDBox mb={3}>
                <Card>
                    <DataTable
                        endpoint={`${API_URL}/payments`}
                        title="Payments"
                        description="View and manage the invoice's payments."
                        sortBy="paid_at"
                        sortDirection="desc"
                        staticFilters
                        filters={filters}
                        actions={actions}
                        colData={[
                            {
                                name: 'id',
                                label: 'ID',
                                options: {
                                    display: 'hidden'
                                }
                            },
                            {
                                name: 'amount',
                                label: 'Amount',
                                options: {
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        return <Chip icon={<Icon style={{marginLeft: 8}}>paid</Icon>} size="small"
                                                     color="secondary" label={`$${value}`}
                                                     variant="outlined"/>
                                    }
                                }
                            },
                            {
                                name: 'payment_method',
                                label: 'Payment Method',
                                options: {
                                    customBodyRender: (value, tableMeta, updateValue) => renderPaymentMethod(value)
                                }
                            },
                            {
                                name: 'paid_at',
                                label: 'Paid At',
                                options: {
                                    customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD')
                                }
                            },
                            {
                                name: 'status',
                                label: 'Status',
                                options: {
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        return renderStatusChip(value)
                                    }
                                }
                            }
                        ]}
                    />
                </Card>
            </MDBox>
        </MDBox>
    );
};

export default Payments;
