/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "profile-form",
  formField: {
    name: {
      name: "name",
      label: "Full Name",
      type: "text",
      errorMsg: "Name is required."
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "john@email.com",
      errorMsg: "Email address is required.",
      invalidMsg: "Email address is invalid",
    },
    phone: {
      name: "phone",
      label: "Phone",
      placeholder: "9560000000",
      type: "text",
      errorMsg: "Phone is required."
    },
    address: {
      name: "address",
      label: "Address",
      placeholder: "4320 Southmost Blvd. Brownsville, Texas",
      type: "text",
      errorMsg: "Address is required."
    },
    social_security_number: {
      name: "social_security_number",
      label: "Social Security Number",
      type: "text",
      errorMsg: "Social security number is required."
    },
    id_number: {
      name: "id_number",
      label: "Official ID Number",
      type: "text",
      errorMsg: "Official ID number is required."
    },
    city_id: {
      name: "city_id",
      label: "City",
      type: 'select',
      errorMsg: "City is required."
    }
  },
};

export default form;
