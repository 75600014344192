import moment from "moment";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Header from "../Header";
import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../../../config";
import {useSelector} from "react-redux";
import MDBox from "../../../../../../components/MDBox";
import ProfileInfoCard from "../../../../../../examples/Cards/InfoCards/ProfileInfoCard";
import MDSnackbar from "../../../../../../components/MDSnackbar";
import {useNavigate} from "react-router-dom";
import {useParams} from 'react-router-dom';

const Overview = ({sale, isLoading, handleLoadingChange, handleReload}) => {

    const authState = useSelector((state) => state.authReducer);

    let navigate = useNavigate();

    const { id } = useParams();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const updateSaleStatus = async (status) => {
        // Mostrar el indicador de carga
        handleLoadingChange(true);

        try {

            const response = await axios.put(`${API_URL}/sales/${sale.id}`, {status: status});

            setSnackbarConfig({
                message: 'Sale updated successfully',
                icon: 'notifications',
                title: 'Sales',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {


        }

        // Esconder indicador de carga
        handleLoadingChange(false);

    };

    const handleNextClick = async () => {

        switch(sale.status) {
            case 'draft':

                if(authState.roleId !== 1 && authState.roleId !== 2){
                    return;
                }

                await updateSaleStatus('agreement');
                await handleReload();

                break;
            case 'agreement':
                if(authState.roleId !== 1){
                    return;
                }

                await updateSaleStatus('reviewed');
                await handleReload();

                break;
            case 'reviewed':

                if(authState.roleId !== 1 && authState.roleId !== 2){
                    return;
                }

                await updateSaleStatus('financed');
                await handleReload();

                break;
            case 'financed':

                if(authState.roleId !== 1 && authState.roleId !== 2){
                    return;
                }

                await updateSaleStatus('site visit');
                await handleReload();

                break;
            case 'site visit':

                if(authState.roleId !== 1 && authState.roleId !== 2){
                    return;
                }

                await updateSaleStatus('installing');
                await handleReload();

                break;
            case 'installing':

                if(authState.roleId !== 1 && authState.roleId !== 5){
                    return;
                }

                await updateSaleStatus('done');
                await handleReload();

                break;
        }
    };

    const renderStatusButtonLabel = () => {
        switch(sale.status){
            case 'draft':
                return 'Mark as Agreement';
            case 'agreement':
                return 'Review';
            case 'reviewed':
                return 'Mark as Financed';
            case 'financed':
                return 'Mark as Site Visit';
            case 'site visit':
                return 'Mark as Installing';
            case 'installing':
                return 'Mark as Done';
        }
    };

    const checkIfButtonShouldBeDisabled = () => {
        if(authState.roleId === 1)
            return false;
        if(authState.roleId === 2)
            return sale.status === 'agreement' || sale.status === 'installing'
        if(authState.roleId === 5)
            return sale.status !== 'installing';
    };

    const renderSecondarySeller = () => {
        return sale.secondary_user && sale.secondary_user.name;
    };

    return <Header statusButtonDisabled={checkIfButtonShouldBeDisabled()} statusButtonLabel={renderStatusButtonLabel()} handleNextClick={handleNextClick} name={`Sale #${sale.id}`} createdAt={moment(sale.created_at).format('MMM DD, YYYY')}
                   status={sale.status}
                   secondarySeller={renderSecondarySeller()}
    >
        <MDBox mt={0} mb={3}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={3}>
                    <ProfileInfoCard
                        title="Client information"
                        info={[
                            {
                                label: 'Client',
                                value: sale.lead.name
                            },
                            {
                                label: 'Email',
                                value: sale.lead.email
                            },
                            {
                                label: 'Phone',
                                value: sale.lead.phone
                            },
                            {
                                label: 'Address',
                                value: sale.lead.address
                            },
                            {
                                label: 'Utility Company',
                                value: `${sale.lead.power_company}`
                            },
                        ]}
                        shadow={false}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={3} sx={{display: "flex"}}>
                    <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                    <ProfileInfoCard
                        title="System information"
                        info={[
                            {
                                label: 'Module Size',
                                value: sale.module_size
                            },
                            {
                                label: 'Module Quantity',
                                value: sale.module_quantity
                            },
                            {
                                label: 'Total Cost',
                                value: `$${sale.total} USD`
                            },
                            {
                                label: 'Duration',
                                value: `${sale.duration}`
                            },
                            {
                                label: 'System Full Comission',
                                value: `$${sale.full_comission.toFixed(2)} USD`
                            },
                        ]}
                        shadow={false}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={3} sx={{display: "flex"}}>
                    <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                    <ProfileInfoCard
                        title="Finance information"
                        info={[
                            {
                                label: 'Down Payment',
                                value: `$${sale.down_payment} USD`
                            },
                            {
                                label: 'Utility Rebate',
                                value: `$${sale.utility_rebate} USD`
                            },
                            {
                                label: 'Bank',
                                value: `${sale.bank.name}`
                            },
                            {
                                label: 'Finance Fee',
                                value: `${sale.finance_fee}%`
                            },
                            {
                                label: 'EPC Rate',
                                value: `$${sale.epc_rate}`
                            },
                            {
                                label: 'APR Rate',
                                value: `${sale.apr_rate}%`
                            },
                            {
                                label: 'Ad Bonus',
                                value: `$${sale.ad_bonus} USD`
                            },
                        ]}
                        shadow={false}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={3} sx={{display: "flex"}}>
                    <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                    <ProfileInfoCard
                        hasAction={true}
                        hasPermission={authState.roleId === 1}
                        onButtonClick={() => navigate(`/sales/${id}/edit-adders`)}
                        buttonLabel="Edit"
                        title="Adders Information"
                        info={[
                            {
                                label: 'Total Cost With Adders',
                                value: `$${sale.total_with_adders} USD`
                            },
                            {
                                label: 'MPU',
                                value: `$${sale.adder_1} USD`
                            },
                            {
                                label: 'City Permits',
                                value: `$${sale.adder_2} USD`
                            },
                            {
                                label: 'Microinverter',
                                value: `$${sale.adder_3} USD`
                            },
                            {
                                label: 'Adder 4',
                                value: `$${sale.adder_4} USD`
                            },
                            {
                                label: 'Adders Comission',
                                value: sale.adders_comission !== null ? `$${sale.adders_comission} USD` : 'Pending'
                            },
                        ]}
                        shadow={false}
                    />
                </Grid>
            </Grid>
        </MDBox>
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </Header>
};

export default Overview;
