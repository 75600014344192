/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// NewProduct page components
import ContactInfo from "./components/ContactInfo";
import EnergyConsumptionInfo from "./components/EnergyConsumptionInfo";
import AdditionalInfo from "./components/AdditionalInfo";
import AppointmentInfo from "./components/AppointmentInfo";
import {Form, Formik} from "formik";

import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import CircularProgress from "@mui/material/CircularProgress";
import {storeLead} from "../../../../services/leadService";
import MDSnackbar from "../../../../components/MDSnackbar";
import moment from "moment";
import axios from "axios";
import {API_URL} from "../../../../config";

function getSteps() {
    return ["1. Contact Info", "2. Energy Consumption", "3. Additional Info", "4. Appointment"];
}

function getStepContent(stepIndex, formData, cities) {
    switch (stepIndex) {
        case 0:
            return <ContactInfo cities={cities} formData={formData}/>;
        case 1:
            return <EnergyConsumptionInfo formData={formData}/>;
        case 2:
            return <AdditionalInfo formData={formData}/>;
        case 3:
            return <AppointmentInfo formData={formData}/>;
        default:
            return null;
    }
}

function NewLead() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const {formId, formField} = form;
    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [cities, setCities] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadCities();
            setIsLoading(false);
        };

        init();

    }, []);

    const handleBack = () => setActiveStep(activeStep - 1);

    const loadCities = async () => {

        try {

            const response = await axios.get(`${API_URL}/cities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setCities([{id: -1, label: 'Choose a city'}, ...response.data.map(city => ({id: city.id, label: city.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the cities",
                icon: 'cancel',
                title: 'Cities',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const submitForm = async (values, actions) => {

        let newValues = {...values};

        if(newValues.without_appointment === true){
            delete newValues.due_at;
            delete newValues.notes;
        }

        try {
            await storeLead(null, newValues);

            actions.resetForm();

            setActiveStep(0);

            setSnackbarConfig({
                message: 'New lead saved successfully',
                icon: 'notifications',
                title: 'Leads',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {
            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Cities',
                color: 'warning'
            });
            setShow(true);
        }

        actions.setSubmitting(false);
    };

    const handleSubmit = (values, actions) => {

        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={3} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                       alignItems="center">
                                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={currentValidation}
                                    onSubmit={handleSubmit}
                                >
                                    {({values, errors, touched, isSubmitting}) => (
                                        <Form id={formId} autoComplete="off">
                                            <MDBox mt={6} mb={8} textAlign="center">
                                                <MDBox mb={1}>
                                                    <MDTypography variant="h3" fontWeight="bold">
                                                        New Lead
                                                    </MDTypography>
                                                </MDBox>
                                                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                                                    Enter your new lead details
                                                </MDTypography>
                                            </MDBox>
                                            <Card>
                                                <MDBox mt={-3} mb={3} mx={2}>
                                                    <Stepper activeStep={activeStep} alternativeLabel>
                                                        {steps.map((label) => (
                                                            <Step key={label}>
                                                                <StepLabel>{label}</StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </MDBox>
                                                <MDBox p={2}>
                                                    <MDBox>
                                                        {getStepContent(activeStep, {
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors,
                                                        }, cities)}
                                                        <MDBox mt={3} width="100%" display="flex"
                                                               justifyContent="space-between">
                                                            {activeStep === 0 ? (
                                                                <MDBox/>
                                                            ) : (
                                                                <MDButton variant="gradient" color="light"
                                                                          onClick={handleBack}>
                                                                    back
                                                                </MDButton>
                                                            )}
                                                            <MDBox>
                                                                <MDButton
                                                                    disabled={isSubmitting}
                                                                    variant="gradient"
                                                                    color="secondary"
                                                                    type="submit"
                                                                >
                                                                    {isSubmitting &&
                                                                    <CircularProgress size={18} color="white"
                                                                                      sx={{marginRight: 1}}/>}
                                                                    {isLastStep ? "submit" : "next"}
                                                                </MDButton>
                                                            </MDBox>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </Card>
                                        </Form>
                                    )}
                                </Formik>
                        }
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
}

export default NewLead;
