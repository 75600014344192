import React, {useState} from "react";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";
import Icon from "@mui/material/Icon";

const usePayments = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/payments/new-payment"),
        edit: false,
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 'Paid':
                return <Chip icon={<Icon style={{marginLeft: 8}}>check</Icon>} size="small" color="primary" label="Paid"
                             variant="outlined"/>;
            case 'Cancelled':
                return <Chip icon={<Icon style={{marginLeft: 8}}>close</Icon>} size="small" color="warning" label="Cancelled"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip
    }
};

export default usePayments;
