/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";
import moment from "moment";

const {
  formField: {
    payment_method,
      amount,
      paid_at,
      invoice_id,
  },
} = checkout();

const initialValues = {
  [payment_method.name]: -1,
  [amount.name]: "",
  [paid_at.name]: moment().format('YYYY-MM-DD'),
  [invoice_id.name]: -1
};

export default initialValues;
