/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useMemo, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
// Sales dashboard components
// Data
import axios from "axios";
import {API_URL} from "../../../config";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import EventCalendar from "examples/Calendar";
import DefaultItem from "../../../examples/Items/DefaultItem";
import MDAvatar from "../../../components/MDAvatar";
import {renderAvatarInitials} from "../../../helpers/helpers";

function SalesDashboard() {

    const [isCalendarLoading, setIsCalendarLoading] = useState(false);
    const [calendarData, setCalendarData] = useState([]);

    const [parentLoading, setParentLoading] = useState(true);
    const [parent, setParent] = useState(null);

    const [isNoticesLoading, setIsNoticesLoading] = useState(true);
    const [notices, setNotices] = useState([]);

    const [isComissionsLoading, setIsComissionsLoading] = useState(true);
    const [comissionsStats, setComissionsStats] = useState(null);
    const [comissionsDropdownValue, setComissionsDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [comissionsDropdown, setComissionsDropdown] = useState(null);

    const [isLeadsLoading, setIsLeadsLoading] = useState(true);
    const [leadsStats, setLeadsStats] = useState(null);
    const [leadsDropdownValue, setLeadsDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [leadsDropdown, setLeadsDropdown] = useState(null);

    const [isSalesLoading, setIsSalesLoading] = useState(true);
    const [salesStats, setSalesStats] = useState(null);
    const [salesDropdownValue, setSalesDropdownValue] = useState(`${moment().subtract(7, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`);
    const [salesDropdown, setSalesDropdown] = useState(null);

    useEffect(() => {

        const init = async () => {

            let to = moment();
            let from = moment().subtract(7, 'days');

            await loadParent();
            await loadNotices();
            await loadComissions(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
            await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
            await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));
        };

        init();
    }, []);

    const loadParent = async () => {
        setParentLoading(true);

        const response = await axios.get(`${API_URL}/users/parent`);

        setParent(response.data[0]);

        setParentLoading(false);
    };

    const loadNotices = async () => {
        setIsNoticesLoading(true);

        let options = {
            params: {
                sort: 'created_at',
                direction: 'desc',
                limit: 5
            }
        };

        const response = await axios.get(`${API_URL}/notices`, options);

        setNotices(response.data);

        setIsNoticesLoading(false);
    };

    const loadComissions = async (from, to) => {

        setIsComissionsLoading(true);

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/users/comissions`, options);

        setComissionsStats(response.data);

        setIsComissionsLoading(false);

    };

    const loadLeads = async (from, to) => {

        setIsLeadsLoading(true);

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/users/leads`, options);

        setLeadsStats(response.data);

        setIsLeadsLoading(false);

    };

    const loadSales = async (from, to) => {

        setIsSalesLoading(true);

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(`${API_URL}/users/sales`, options);

        setSalesStats(response.data);

        setIsSalesLoading(false);

    };

    const loadCalendarData = async (url, from, to) => {

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data
        };

    };

    // DefaultStatisticsCard handler for the dropdown action
    const openComissionsDropdown = ({currentTarget}) => setComissionsDropdown(currentTarget);

    const closeComissionsDropdown = async (option) => {
        setComissionsDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setComissionsDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadComissions(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setComissionsDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadComissions(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setComissionsDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadComissions(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }

    };

    const openLeadsDropdown = ({currentTarget}) => setLeadsDropdown(currentTarget);

    const closeLeadsDropdown = async (option) => {
        setLeadsDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setLeadsDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setLeadsDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setLeadsDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadLeads(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }

    };

    const openSalesDropdown = ({currentTarget}) => setSalesDropdown(currentTarget);

    const closeSalesDropdown = async (option) => {
        setSalesDropdown(null);

        let to = null;
        let from = null;

        switch (option) {
            case 1:
                to = moment();
                from = moment().subtract(7, 'days');

                setSalesDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 2:
                to = moment();
                from = moment().subtract(30, 'days');

                setSalesDropdownValue(`${from.format('DD MMM')} - ${to.format('DD MMM')}`);
                await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
            case 3:
                to = moment();
                from = moment().subtract(365, 'days');

                setSalesDropdownValue(`${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`);
                await loadSales(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD'));

                break;
        }
    };

    const handleDateChange = async (dateInfo) => {

        const startMoment = moment(dateInfo.start);
        const endMoment = moment(dateInfo.end);

        setIsCalendarLoading(true);

        const response = await loadCalendarData(`${API_URL}/appointments`, startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD'));

        setIsCalendarLoading(false);

        setCalendarData(formatCalendarData(response.data));

    };

    const formatCalendarData = (calendarData) => {
        return calendarData.map(event => ({
            id: event.id,
            title: `Meeting with ${event.lead.name}`,
            start: event.due_at,
            notes: event.notes,
            email: event.lead.email,
            phone: event.lead.phone,
            address: event.lead.address,
            status: event.status,
            className: getEventClass(event.status)
        }))
    };

    const getEventClass = (status) => {
        switch (status) {
            case 'pending':
                return 'primary';
            case 'cancelled':
                return 'warning';
            case 'done':
                return 'secondary';
        }
    };

    // Dropdown menu template for the DefaultStatisticsCard
    const renderMenu = (state, close) => (
        <Menu
            anchorEl={state}
            transformOrigin={{vertical: "top", horizontal: "center"}}
            open={Boolean(state)}
            onClose={close}
            keepMounted
            disableAutoFocusItem
        >
            <MenuItem onClick={() => close(1)}>Last 7 days</MenuItem>
            <MenuItem onClick={() => close(2)}>Last 30 days</MenuItem>
            <MenuItem onClick={() => close(3)}>Last 365 days</MenuItem>
        </Menu>
    );

    const renderNotices = () => {

        return notices.map(nw => (
            <DefaultItem
                color="primary"
                icon="announcement"
                title={nw.title}
                description={nw.summary}
            />
        ))
    };

    const calculatePercentage = (newValue, previousValue) => {


        let percentage = (newValue - previousValue) / previousValue * 100;

        return {
            color: newValue > previousValue ? "success" : "error",
            value: previousValue && `${percentage.toFixed(2)}%`,
            label: previousValue ? "since last week" : "Nothing to compare",
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={3}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            {
                                isComissionsLoading ?
                                    <Card>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card> :
                                    <DefaultStatisticsCard
                                        title="Earnings"
                                        count={`$${comissionsStats.comissions}`}
                                        percentage={calculatePercentage(comissionsStats.comissions, comissionsStats.previous_comissions)}
                                        dropdown={{
                                            action: openComissionsDropdown,
                                            menu: renderMenu(comissionsDropdown, closeComissionsDropdown),
                                            value: comissionsDropdownValue,
                                        }}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                isLeadsLoading ?
                                    <Card>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card> :
                                    <DefaultStatisticsCard
                                        title="Leads"
                                        count={leadsStats.leads}
                                        percentage={calculatePercentage(leadsStats.leads, leadsStats.previous_leads)}
                                        dropdown={{
                                            action: openLeadsDropdown,
                                            menu: renderMenu(leadsDropdown, closeLeadsDropdown),
                                            value: leadsDropdownValue,
                                        }}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                isSalesLoading ?
                                    <Card>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card> :
                                    <DefaultStatisticsCard
                                        title="Sales"
                                        count={salesStats.sales}
                                        percentage={calculatePercentage(salesStats.sales, salesStats.previous_sales)}
                                        dropdown={{
                                            action: openSalesDropdown,
                                            menu: renderMenu(salesDropdown, closeSalesDropdown),
                                            value: salesDropdownValue,
                                        }}
                                    />
                            }
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={4}>

                            {
                                parentLoading ?
                                    <Card sx={{marginBottom: 3}}>
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox>
                                    </Card>
                                    :
                                    parent &&
                                    <Card style={{marginBottom: 16}}>
                                        <MDBox py={2} px={2}>
                                            <MDTypography
                                                variant="h6"
                                                fontWeight="medium"
                                                color="text"
                                                textTransform="capitalize"
                                                sx={{marginBottom: 1}}
                                            >
                                                Your Mentor
                                            </MDTypography>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <MDAvatar bgColor="primary" alt="profile-image" size="md"
                                                              shadow="sm">{renderAvatarInitials(parent.name)}</MDAvatar>
                                                </Grid>
                                                <Grid item>
                                                    <MDBox display="flex" flexDirection="column"
                                                           justifyContent="flex-start" mt={0.5} lineHeight={1}>
                                                        <MDTypography variant="button" fontWeight="medium">
                                                            {parent.name}
                                                        </MDTypography>
                                                        <MDTypography variant="caption" color="text">
                                                            {parent.email}
                                                        </MDTypography>
                                                    </MDBox>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </Card>
                            }
                            <Card>
                                {
                                    isNoticesLoading ?
                                        <MDBox p={4.3} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox> :
                                        <MDBox py={2} px={2}>
                                            <MDTypography
                                                variant="h6"
                                                fontWeight="medium"
                                                color="text"
                                                textTransform="capitalize"
                                                sx={{marginBottom: 1}}
                                            >
                                                Recent News
                                            </MDTypography>
                                            {
                                                notices.length > 0 ?
                                                    renderNotices() :
                                                    <MDTypography variant="body2">No recent notices</MDTypography>
                                            }
                                        </MDBox>

                                }
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8}>
                            {useMemo(
                                () => (
                                    <EventCalendar
                                        isLoading={isCalendarLoading}
                                        initialView="dayGridMonth"
                                        initialDate={moment().format('YYYY-MM-DD')}
                                        datesSet={handleDateChange}
                                        events={calendarData}
                                        selectable
                                        editable
                                    />
                                ),
                                [calendarData, isCalendarLoading]
                            )}
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default SalesDashboard;
