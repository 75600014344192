/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-invoice-form",
  formField: {
    folio: {
      name: "folio",
      label: "Folio",
      type: "text",
      errorMsg: "Folio is required.",
    },
    type: {
      name: "type",
      label: "Type of Invoice",
      type: "select",
      options: [
        {id: -1, label: 'Choose an option'},
        {id: 1, label: 'Type A'},
        {id: 2, label: 'Type B'},
        {id: 3, label: 'Type C'}
      ],
      errorMsg: "Type is required.",
    },
    client_name: {
      name: "client_name",
      label: "Client",
      type: "text",
      errorMsg: "Client is required.",
    },
    service_address: {
      name: "service_address",
      label: "Service Address",
      type: "text",
      errorMsg: "Service address is required.",
    },
    loan_id: {
      name: "loan_id",
      label: "Loan ID",
      type: "text",
    },
    fob_point: {
      name: "fob_point",
      label: "FOB Point",
      type: "text",
    },
    sales_person: {
      name: "sales_person",
      label: "Sales Person",
      type: "text",
    },
    terms: {
      name: "terms",
      label: "Terms",
      type: "text",
      errorMsg: "Terms is required.",
    },
    due_at: {
      name: "due_at",
      label: "Due At",
      type: "date",
      errorMsg: "Due date is required.",
    },
    company_id: {
      name: "company_id",
      label: "Company",
      type: "select",
      errorMsg: "The company is required.",
      invalidMsg: "The company is required."
    },
    invoice_items: {
      name: "invoice_items",
      type: "array",
      errorMsg: "You must add at least one item.",
      formField: {
        invoice_item_id: {
          name: "invoice_item_id",
          label: "Item",
          type: "select",
          errorMsg: "Item is required.",
          invalidMsg: "Item is required.",
          variant: 'standard'
        },
        description: {
          name: "description",
          label: "Description",
          type: "text",
          errorMsg: "Description is required.",
          variant: 'standard'
        },
        amount: {
          name: "amount",
          label: "Amount",
          type: "number",
          errorMsg: "Amount is required.",
          variant: 'standard'
        },
        unit_price: {
          name: "unit_price",
          label: "Unit Price",
          type: "number",
          errorMsg: "Unit price is required.",
          variant: 'standard'
        },
      }
    }
  },
};

export default form;
