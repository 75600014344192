/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState} from "react";
// react-router-dom components
import {Link} from "react-router-dom";
// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
// Image
import bgImage from "assets/images/people-power-plant.jpg";
import {Form, Formik} from "formik";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import form from "./schemas/form";
import RecoverPasswordForm from "./components/RecoverPasswordForm";
import {API_URL, APP_URL} from "../../../config";
import axios from "axios";
import MDSnackbar from "../../../components/MDSnackbar";

function RecoverPassword() {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const submitForm = async (values, actions) => {

        try {

            await axios.get(`${APP_URL}/sanctum/csrf-cookie`);

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/recover_password`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Recover password process started successfully',
                icon: 'notifications',
                title: 'Authentication',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Authentication',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    return (
        <IllustrationLayout
            title="Recover Password"
            description="Enter your email and submit the form to receive an email with the instructions to recover your account"
            illustration={bgImage}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, isSubmitting}) => (
                    <Form id={formId} autoComplete="off">
                        <RecoverPasswordForm formData={{values, touched, formField, errors}}/>
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                disabled={isSubmitting}
                                variant="gradient"
                                color="secondary"
                                size="large"
                                fullWidth
                                type="submit"
                            >
                                {isSubmitting && <CircularProgress size={18} color="white" sx={{marginRight: 1}}/>}
                                Recover account
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Remembered your password?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/authentication/sign-in"
                                    variant="button"
                                    color="secondary"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Sign in
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </Form>
                )}
            </Formik>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </IllustrationLayout>
    );
}

export default RecoverPassword;
