/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import {Provider} from "react-redux";
import axios from 'axios';
import {applyMiddleware, createStore} from "redux";
import rootReducer from "./store/reducers/rootReducer";
import reduxThunk from "redux-thunk";

axios.defaults.withCredentials = true;

// Crear store de Redux
const store = createStore(
    rootReducer,
    {},
    applyMiddleware(reduxThunk)
);

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
        <Provider store={ store }>
            <App />
        </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
