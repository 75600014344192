/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// FileDetails page components
import YoutubeEmbed from "../../../../../components/YoutubeEmbed";
import Divider from "@mui/material/Divider";
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {API_URL, APP_URL} from "../../../../../config";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import parse from "html-react-parser";
import moment from "moment";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../../components/MDButton";
import Link from "@mui/material/Link";


function FileDetails() {

    const { id } = useParams();

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getVideoData(`${API_URL}/support_files/${id}`);
    }, []);

    const loadVideoData = async (url) => {

        const response = await axios.get(url);

        return {
            data: response.data
        };

    };

    const getVideoData = async (url) => {

        setIsLoading(true);

        const response = await loadVideoData(url);

        setData(response.data);

        setIsLoading(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={3}>
                <Card sx={{overflow: "visible"}}>
                    <MDBox p={3}>
                        <MDBox mb={3}>
                            <MDTypography variant="h5" fontWeight="medium">
                                Support File
                            </MDTypography>
                        </MDBox>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                       alignItems="center">
                                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                <Grid container spacing={3}>
                                    <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} xl={12}>
                                        <MDBox>
                                            <MDBox>
                                                <MDTypography variant="h3">
                                                    {data.title}
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={2}>
                                                <MDTypography variant="button" color="secondary">
                                                    <Icon sx={{mr: 1}}>calendar_today</Icon>
                                                    {moment(data.updated_at).format('YYYY-MM-DD')}
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={3} ml={0.5}>
                                                <MDTypography variant="subtitle2" color="text">
                                                    {data.excerp}
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={1} ml={0.5}>
                                                <Link
                                                    href={`${APP_URL}/storage/support_files/${data.file}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    sx={{ textDecoration: "none" }}
                                                >
                                                    <MDButton variant="gradient" color="secondary">
                                                        <Icon>download</Icon>&nbsp;
                                                        Download File
                                                    </MDButton>
                                                </Link>
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} xl={12}>
                                        <Divider />
                                        <MDBox my={3}>
                                            {parse(data.content)}
                                        </MDBox>
                                    </Grid>
                                </Grid>

                        }
                    </MDBox>
                </Card>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default FileDetails;
