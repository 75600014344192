import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {withStyles} from "tss-react/mui";
import Icon from "@mui/material/Icon";

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

const CustomToolbarSelect = ({classes, displayData, selectedRows, setSelectedRows, handleEditClick, handleDoneClick, handleCancelClick}) => {

    console.log(displayData);
    return (
        <div className={classes.iconContainer}>
            <Tooltip title={"Edit Record"}>
                <IconButton disabled={selectedRows.data.length !== 1 || displayData[selectedRows.data[0].index].data[5].props.label !== 'Pending'} className={classes.iconButton} onClick={() => handleEditClick(displayData[selectedRows.data[0].index].data[0])}>
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title={"Mark As Done"}>
                <IconButton disabled={selectedRows.data.length !== 1 || displayData[selectedRows.data[0].index].data[5].props.label !== 'Pending'} className={classes.iconButton} onClick={() => handleDoneClick(displayData[selectedRows.data[0].index].data[0])}>
                    <Icon>done</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title={"Cancel Appointment"}>
                <IconButton disabled={selectedRows.data.length !== 1 || displayData[selectedRows.data[0].index].data[5].props.label !== 'Pending'} className={classes.iconButton} onClick={() => handleCancelClick(displayData[selectedRows.data[0].index].data[0])}>
                    <Icon>close</Icon>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, {name: "CustomToolbarSelect"});
