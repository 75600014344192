/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "reschedule-appointment-form",
  formField: {
    due_at: {
      name: "due_at",
      label: "Appointment Date And Time",
      type: "date",
      errorMsg: "Appointment date and time is required.",
      invalidMsg: "Must be a date in the future."
    }
  },
};

export default form;
