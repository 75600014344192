/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-sale-form",
  formField: {
    lead_id: {
      name: "lead_id",
      label: "Lead",
      type: "select",
      errorMsg: "Lead is required.",
    },
    module_size: {
      name: "module_size",
      label: "Module Size",
      type: "number",
      errorMsg: "Module size is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    module_quantity: {
      name: "module_quantity",
      label: "Module Quantity",
      type: "number",
      errorMsg: "Module quantity is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    total: {
      name: "total",
      label: "Total Without Adders",
      type: "number",
      errorMsg: "Total is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    has_secondary_seller: {
      name: "has_secondary_seller",
      label: "Add a secondary seller?",
      type: "checkbox",
    },
    user_id: {
      name: "user_id",
      label: "Secondary Seller",
      type: "select",
      errorMsg: "Secondary seller is required.",
    },
    down_payment: {
      name: "down_payment",
      label: "Down Payment",
      type: "number",
      errorMsg: "Down payment is required.",
    },
    utility_rebate: {
      name: "utility_rebate",
      label: "Utility Rebate",
      type: "number",
      errorMsg: "Utility rebate is required.",
    },
    ad_bonus: {
      name: "ad_bonus",
      label: "Ad Bonus",
      type: "number",
      errorMsg: "Ad bonus is required.",
    },
    finance_fee: {
      name: "finance_fee",
      label: "Finance Fee",
      type: "number",
      errorMsg: "Finance fee is required.",
      invalidMsg: "Must be a percentage greater than 0"
    },
    bank_id: {
      name: "bank_id",
      label: "Bank",
      type: "select",
      errorMsg: "Bank is required.",
    },
    invoice_item_id: {
      name: "invoice_item_id",
      label: "Adder",
      type: "select",
      errorMsg: "Adder is required.",
    },
    apr_rate: {
      name: "apr_rate",
      label: "APR Rate",
      type: "number",
      errorMsg: "APR rate is required.",
      invalidMsg: "Must be a percentage greater than 0"
    },
    total_with_adders: {
      name: "total_with_adders",
      label: "Total With Adders",
      type: "number",
      errorMsg: "Total is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    adder_1: {
      name: "adder_1",
      label: "MPU",
      type: "number",
      errorMsg: "Adder 1 is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    adder_2: {
      name: "adder_2",
      label: "City Permit",
      type: "number",
      errorMsg: "Adder 2 is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    adder_3: {
      name: "adder_3",
      label: "Microinverter",
      type: "number",
      errorMsg: "Adder 3 is required.",
      invalidMsg: "Must be a number greater than 0"
    },
    adder_4: {
      name: "adder_4",
      label: "Adder Cost",
      type: "number",
      errorMsg: "Adder cost is required.",
    }
  },
};

export default form;
