/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";
import React from "react";


function EditLeadForm({formData, cities}) {

    const {formField, values, errors, touched} = formData;

    const {name, email, address, city_id, phone, power_company, years_in_property, highest_monthly_bill, lowest_monthly_bill, credit_score, moved_in_at, is_home_owner} = formField;
    const {
        name: nameV,
        email: emailV,
        address: addressV,
        city_id: cityIdV,
        phone: phoneV,
        power_company: power_companyV,
        years_in_property: years_in_propertyV,
        highest_monthly_bill: highest_monthly_billV,
        lowest_monthly_bill: lowest_monthly_billV,
        credit_score: credit_scoreV,
        moved_in_at: moved_in_atV,
        is_home_owner: is_home_ownerV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        placeholder={name.placeholder}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={address.type}
                        label={address.label}
                        name={address.name}
                        value={addressV}
                        placeholder={address.placeholder}
                        error={errors.address && touched.address}
                        success={addressV && addressV.length > 0 && !errors.address}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={city_id.type}
                        label={city_id.label}
                        name={city_id.name}
                        value={cityIdV}
                        options={cities}
                        error={errors.city_id && touched.city_id}
                        success={cityIdV && cityIdV.length > 0 && !errors.city_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        placeholder={email.placeholder}
                        error={errors.email && touched.email}
                        success={emailV && emailV.length > 0 && !errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={phone.type}
                        label={phone.label}
                        name={phone.name}
                        value={phoneV}
                        placeholder={phone.placeholder}
                        error={errors.phone && touched.phone}
                        success={phoneV && phoneV.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={power_company.type}
                        label={power_company.label}
                        name={power_company.name}
                        value={power_companyV}
                        placeholder={power_company.placeholder}
                        error={errors.power_company && touched.power_company}
                        success={power_companyV && power_companyV.length > 0 && !errors.power_company}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={years_in_property.type}
                        label={years_in_property.label}
                        name={years_in_property.name}
                        value={years_in_propertyV}
                        placeholder={years_in_property.placeholder}
                        error={errors.years_in_property && touched.years_in_property}
                        success={years_in_propertyV && years_in_propertyV.length > 0 && !errors.years_in_property}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={lowest_monthly_bill.type}
                        label={lowest_monthly_bill.label}
                        name={lowest_monthly_bill.name}
                        value={lowest_monthly_billV}
                        placeholder={lowest_monthly_bill.placeholder}
                        error={errors.lowest_monthly_bill && touched.lowest_monthly_bill}
                        success={lowest_monthly_billV && lowest_monthly_billV.length > 0 && !errors.lowest_monthly_bill}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={highest_monthly_bill.type}
                        label={highest_monthly_bill.label}
                        name={highest_monthly_bill.name}
                        value={highest_monthly_billV}
                        placeholder={highest_monthly_bill.placeholder}
                        error={errors.highest_monthly_bill && touched.highest_monthly_bill}
                        success={highest_monthly_billV && highest_monthly_billV.length > 0 && !errors.highest_monthly_bill}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={credit_score.type}
                        label={credit_score.label}
                        name={credit_score.name}
                        value={credit_scoreV}
                        placeholder={credit_score.placeholder}
                        error={errors.credit_score && touched.credit_score}
                        success={credit_scoreV && credit_scoreV && !errors.credit_score}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditLeadForm;
