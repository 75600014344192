import React, {useRef, useState} from "react";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import axios from "axios";
import {API_URL} from "../../../../../../config";
import TableRow from "@mui/material/TableRow";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import logoCT from "assets/images/logo.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDSnackbar from "../../../../../../components/MDSnackbar";
import moment from "moment";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Comissions = ({sale}) => {

    let navigate = useNavigate();

    const authState = useSelector((state) => state.authReducer);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const printRef = useRef();

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('sale_' + sale.id + '.pdf');
    };

    const handleSendPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');


        try {
            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/sales/${sale.id}/send`,
                {
                    pdf: data
                }
            );

            setSnackbarConfig({
                message: 'Comissions sent successfully',
                icon: 'notifications',
                title: 'Comissions',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Comissions',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const borderBottom = {
        borderBottom: ({borders: {borderWidth}, palette: {light}}) =>
            `${borderWidth[1]} solid ${light.main}`,
    };

    const renderComissionDeductions = (id) => {

        return sale.comission_deductions.filter(deduction => deduction.seller_id == id).map(deduction => (
            <MDTypography variant="button" style={{display: 'block'}}>{deduction.motive}: ${deduction.amount}</MDTypography>
        ))
    };

    return <Card>
        <MDBox ref={printRef}>
            {/* SaleDetails header */}
            <MDBox p={3}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center"
                          flexDirection="row">
                        <MDBox
                            component="img"
                            src={logoCT}
                            width="30%"
                            p={1}
                            mb={1}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={6}>
                        <MDTypography variant="h6" fontWeight="regular">
                            <b>Seller:</b> {sale.lead.user.name}
                        </MDTypography>
                        {
                            sale.user_id &&
                            <MDTypography variant="h6" fontWeight="regular">
                                <b>Seller 2:</b> {sale.secondary_user && sale.secondary_user.name}
                            </MDTypography>
                        }
                        <MDTypography variant="h6" fontWeight="regular">
                            <b>Client's Name:</b> {sale.lead.name}
                        </MDTypography>
                        <MDBox mb={1} mt={2}>
                            <MDTypography variant="button" style={{display: 'block'}}>System
                                Unit: {sale.module_quantity * sale.module_size / 1000}kW</MDTypography>
                            <MDTypography variant="button" style={{display: 'block'}}>Price:
                                ${sale.total}</MDTypography>
                            <MDTypography variant="button" style={{display: 'block', marginTop: 20}}>Bank
                                Fee: {sale.finance_fee}%</MDTypography>
                            <MDTypography variant="button" style={{display: 'block', marginTop: 20}}>Comission:
                                ${sale.full_comission.toFixed(2)}</MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox width="100%" textAlign={{xs: "left", md: "right"}} mt={1}>
                            <MDBox mb={1}>
                                <MDTypography variant="h6"
                                              fontWeight="regular"><b>Date:</b> {moment(sale.created_at).format('YYYY-MM-DD')}</MDTypography>
                                <MDTypography variant="h6"
                                              fontWeight="regular" mt={2}>{sale.module_quantity * sale.module_size} kw/h</MDTypography>
                                <MDTypography variant="button" style={{display: 'block'}} mt={2}>Price p/kW:
                                    ${(sale.total / (sale.module_quantity * sale.module_size)).toFixed(2)}</MDTypography>
                                <MDTypography variant="button" style={{display: 'block'}}>Comission Rate:
                                    ${sale.epc_rate}</MDTypography>

                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox mt={3} mb={2}>
                            <MDTypography variant="h6">Adders</MDTypography>
                            <MDBox sx={{borderStyle: 'solid', borderWidth: 1, borderColor: 'black', padding: 2}}>
                                <MDTypography variant="button" fontWeight="regular" style={{display: 'block'}}>MPU:
                                    ${sale.adder_1}</MDTypography>
                                <MDTypography variant="button" fontWeight="regular" style={{display: 'block'}}>City Permit:
                                    ${sale.adder_2}</MDTypography>
                                <MDTypography variant="button" fontWeight="regular" style={{display: 'block'}}>IQ8:
                                    ${sale.adder_3}</MDTypography>
                                <MDTypography variant="button" fontWeight="regular" style={{display: 'block'}}>Adder 4:
                                    ${sale.adder_4}</MDTypography>
                            </MDBox>
                            <MDTypography variant="h6" mt={2}>Sellers Comission:
                                ${sale.full_comission.toFixed(2)}</MDTypography>
                        </MDBox>
                        <MDBox sx={{borderStyle: 'solid', borderWidth: 1, borderColor: 'black', padding: 2}}>
                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <MDTypography variant="h6" fontWeight="regular">First Seller</MDTypography>
                                    <MDTypography variant="button" style={{display: 'block'}}>${parseFloat(sale.first_user_full_comission).toFixed(2)}</MDTypography>

                                    <MDTypography variant="h6" fontWeight="regular" mt={2}>Deductions</MDTypography>
                                    {renderComissionDeductions(sale.lead.user_id)}
                                </Grid>
                                <Grid item sm={6}>
                                    <MDTypography variant="h6" fontWeight="regular">Second Seller</MDTypography>
                                    <MDTypography variant="button" style={{display: 'block'}}>{sale.user_id && `$${parseFloat(sale.second_user_full_comission).toFixed(2)}`}</MDTypography>
                                    <MDTypography variant="h6" fontWeight="regular" mt={2}>Deductions</MDTypography>
                                    {renderComissionDeductions(sale.user_id)}
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox mt={3} mb={2}>
                            <MDTypography variant="h6">First Seller Final Comission Amount:
                                ${sale.first_user_comission.toFixed(2)}</MDTypography>
                        </MDBox>
                        <MDBox sx={{borderStyle: 'solid', borderWidth: 1, borderColor: 'black', padding: 2}}>
                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <MDTypography variant="h6" fontWeight="regular">First Payment</MDTypography>
                                    <MDTypography variant="h6" fontWeight="regular">Second Payment</MDTypography>
                                </Grid>
                                <Grid item sm={6}>
                                    <MDTypography variant="button" style={{display: 'block'}}>${parseFloat(sale.first_user_first_comission).toFixed(2)}</MDTypography>
                                    <MDTypography variant="button" style={{display: 'block'}}>${parseFloat(sale.first_user_second_comission).toFixed(2)}</MDTypography>
                                </Grid>
                            </Grid>
                        </MDBox>
                        {
                            sale.user_id &&
                                <>
                                    <MDBox mt={3} mb={2}>
                                        <MDTypography variant="h6">Second Seller Final Comission Amount:
                                            ${parseFloat(sale.second_user_comission).toFixed(2)}</MDTypography>
                                    </MDBox>
                                    <MDBox sx={{borderStyle: 'solid', borderWidth: 1, borderColor: 'black', padding: 2}}>
                                        <Grid container spacing={3}>
                                            <Grid item sm={6}>
                                                <MDTypography variant="h6" fontWeight="regular">First Payment</MDTypography>
                                                <MDTypography variant="h6" fontWeight="regular">Second Payment</MDTypography>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <MDTypography variant="button" style={{display: 'block'}}>${parseFloat(sale.second_user_first_comission).toFixed(2)}</MDTypography>
                                                <MDTypography variant="button" style={{display: 'block'}}>${parseFloat(sale.second_user_second_comission).toFixed(2)}</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </>
                        }
                    </Grid>
                </Grid>
            </MDBox>

        </MDBox>
        {/* SaleDetails footer */}
        {
            authState.roleId === 1 &&
            <MDBox p={3} mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <MDBox
                            width="100%"
                            height={{xs: "auto", md: "100%"}}
                            display="flex"
                            justifyContent={{xs: "flex-start", md: "flex-end"}}
                            alignItems="flex-end"
                            mt={{xs: 2, md: 0}}
                        >
                            <MDButton fullWidth variant="gradient" color="primary"
                                      onClick={() => navigate(`/sales/${sale.id}/new-deduction`)}>
                                <Icon style={{marginRight: 8}}>add</Icon>
                                add deduction
                            </MDButton>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MDBox
                            width="100%"
                            height={{xs: "auto", md: "100%"}}
                            display="flex"
                            justifyContent={{xs: "flex-start", md: "flex-end"}}
                            alignItems="flex-end"
                            mt={{xs: 2, md: 0}}
                        >
                            <MDButton fullWidth variant="gradient" color="primary"
                                      onClick={() => handleDownloadPdf()}>
                                <Icon style={{marginRight: 8}}>print</Icon>
                                print
                            </MDButton>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MDBox
                            width="100%"
                            height={{xs: "auto", md: "100%"}}
                            display="flex"
                            justifyContent={{xs: "flex-start", md: "flex-end"}}
                            alignItems="flex-end"
                            mt={{xs: 2, md: 0}}
                        >
                            <MDButton fullWidth variant="gradient" color="secondary"
                                      onClick={() => handleSendPdf()}>
                                <Icon style={{marginRight: 8}}>email</Icon>
                                send
                            </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        }
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </Card>;
};

export default Comissions;
