/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    company_name,
      contact_name,
      contact_email,
      contact_phone,
      address,
      state,
      city,
      zip_code
  },
} = checkout;

const initialValues = {
  [company_name.name]: "",
  [contact_name.name]: "",
  [contact_email.name]: "",
  [contact_phone.name]: "",
  [address.name]: "",
  [state.name]: "",
  [city.name]: "",
  [zip_code.name]: "",
};

export default initialValues;
