/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// NewProduct page components
import {Form, Formik} from "formik";

import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import CircularProgress from "@mui/material/CircularProgress";
import NewAppointmentForm from "./components/NewAppointmentForm";
import axios from "axios";
import {API_URL} from "../../../../config";
import MDSnackbar from "../../../../components/MDSnackbar";
import moment from "moment";

function NewAppointment() {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const {formId, formField} = form;
    const currentValidation = validations;

    const [leads, setLeads] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const init = async () => {
            await loadLeads();
        };

        init();

    }, []);

    const loadLeads = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/leads`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setLeads(response.data.map(lead => ({id: lead.id, label: lead.name})));

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading your leads",
                icon: 'cancel',
                title: 'Leads',
                color: 'warning'
            });

            setShow(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const submitForm = async (values, actions) => {

        let newValues = {...values};

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/appointments`,
                {...newValues}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'New appointment saved successfully',
                icon: 'notifications',
                title: 'Appointments',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'notifications',
                title: 'Appointments',
                color: 'warning'
            });

            setShow(true);
        }

        actions.setSubmitting(false);
    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={3} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        {
                            isLoading ?
                                <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                </MDBox> :
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={currentValidation}
                                    onSubmit={handleSubmit}
                                >
                                    {({values, errors, touched, isSubmitting}) => (
                                        <Form id={formId} autoComplete="off">
                                            <MDBox mt={6} mb={8} textAlign="center">
                                                <MDBox mb={1}>
                                                    <MDTypography variant="h3" fontWeight="bold">
                                                        New Appointment
                                                    </MDTypography>
                                                </MDBox>
                                                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                                                    Enter the new appointment details
                                                </MDTypography>
                                            </MDBox>
                                            <Card>
                                                <MDBox p={3}>
                                                    <MDBox>
                                                        <NewAppointmentForm leads={leads} formData={{
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors
                                                        }}/>
                                                        <MDBox mt={3} width="100%">
                                                            <MDBox display="flex" alignItems="flex-end"
                                                                   justifyContent="flex-end">
                                                                <MDButton
                                                                    disabled={isSubmitting}
                                                                    variant="gradient"
                                                                    color="secondary"
                                                                    type="submit"
                                                                >
                                                                    {isSubmitting &&
                                                                    <CircularProgress size={18} color="white"
                                                                                      sx={{marginRight: 1}}/>}
                                                                    submit
                                                                </MDButton>
                                                            </MDBox>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </Card>
                                        </Form>
                                    )}
                                </Formik>
                        }
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
}

export default NewAppointment;
