/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import {ErrorMessage, Field, FieldArray, useFormikContext} from "formik";
import {Icon} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "../../../../../../components/MDBox";
import FormField from "../../../../../../components/FormField";
import MDTypography from "../../../../../../components/MDTypography";
import colors from "../../../../../../assets/theme/base/colors";
import MDButton from "../../../../../../components/MDButton";

// Material Dashboard 2 PRO React components


function EditInvoiceForm({formData, companies, invoiceItems}) {

    const formikProps = useFormikContext();

    const {formField, values, errors, touched} = formData;

    const {type, folio, client_name, service_address, loan_id, fob_point, sales_person, terms, due_at, company_id, invoice_items} = formField;
    const {
        type: typeV,
        folio: folioV,
        client_name: clientNameV,
        service_address: serviceAddressV,
        loan_id: loanIdV,
        fob_point: fobPointV,
        sales_person: salesPersonV,
        terms: termsV,
        due_at: dueAtV,
        company_id: companyIdV,
        invoice_items: invoiceItemsV
    } = values;

    const handleInvoiceItemBlur = (id, index) => {

        let invoiceItem = invoiceItems.find(invoiceItem => invoiceItem.id === id);

        formikProps.setFieldValue(`invoice_items.${index}.description`, invoiceItem.description);
        formikProps.setFieldValue(`invoice_items.${index}.unit_price`, invoiceItem.price);
    };

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={company_id.type}
                        label={company_id.label}
                        name={company_id.name}
                        value={companyIdV}
                        options={companies}
                        error={errors.company_id && touched.company_id}
                        success={companyIdV && companyIdV.length > 0 && !errors.company_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={type.type}
                        label={type.label}
                        name={type.name}
                        value={typeV}
                        options={type.options}
                        error={errors.type && touched.type}
                        success={typeV && typeV.length > 0 && !errors.type}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={folio.type}
                        label={folio.label}
                        name={folio.name}
                        value={folioV}
                        error={errors.folio && touched.folio}
                        success={folioV && folioV.length > 0 && !errors.folio}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={client_name.type}
                        label={client_name.label}
                        name={client_name.name}
                        value={clientNameV}
                        error={errors.client_name && touched.client_name}
                        success={clientNameV && clientNameV.length > 0 && !errors.client_name}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={service_address.type}
                        label={service_address.label}
                        name={service_address.name}
                        value={serviceAddressV}
                        error={errors.service_address && touched.service_address}
                        success={serviceAddressV && serviceAddressV.length > 0 && !errors.service_address}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={due_at.type}
                        label={due_at.label}
                        name={due_at.name}
                        value={dueAtV}
                        options={{disableMobile: true, dateFormat: 'Y-m-d'}}
                        error={errors.due_at && touched.due_at}
                        success={dueAtV.length > 0 && !errors.due_at}
                        InputProps={{startAdornment: <InputAdornment disableTypography position="start"><Icon>calendar_today</Icon></InputAdornment>}}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormField
                        type={loan_id.type}
                        label={loan_id.label}
                        name={loan_id.name}
                        value={loanIdV}
                        error={errors.loan_id && touched.loan_id}
                        success={loanIdV && loanIdV.length > 0 && !errors.loan_id}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormField
                        type={fob_point.type}
                        label={fob_point.label}
                        name={fob_point.name}
                        value={fobPointV}
                        error={errors.fob_point && touched.fob_point}
                        success={fobPointV && fobPointV.length > 0 && !errors.fob_point}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormField
                        type={sales_person.type}
                        label={sales_person.label}
                        name={sales_person.name}
                        value={salesPersonV}
                        error={errors.sales_person && touched.sales_person}
                        success={salesPersonV && salesPersonV.length > 0 && !errors.sales_person}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormField
                        type={terms.type}
                        label={terms.label}
                        name={terms.name}
                        value={termsV}
                        error={errors.terms && touched.terms}
                        success={termsV && termsV.length > 0 && !errors.terms}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <MDTypography variant="h6">Items</MDTypography>
                    <MDTypography variant="body2" mb={2}>Add the invoice line items.</MDTypography>
                    <FieldArray name={invoice_items.name}>
                        {({insert, remove, push}) => (
                            <MDBox sx={{borderWidth: 1, borderColor: colors.grey['400'], borderStyle: 'solid', padding: 2}}>
                                {values.invoice_items.length > 0 &&
                                values.invoice_items.map((saleItem, index) => (
                                    <Grid container spacing={3} alignItems="center" justifyContent="center" key={index} mb={2}>
                                        <Grid item xs={12} sm={3}>
                                            <FormField
                                                type={invoice_items.formField.invoice_item_id.type}
                                                label={invoice_items.formField.invoice_item_id.label + ' #' + (index + 1)}
                                                name={`${invoice_items.name}.${index}.${invoice_items.formField.invoice_item_id.name}`}
                                                value={invoiceItemsV[index].invoice_item_id}
                                                options={invoiceItems}
                                                variant={invoice_items.formField.invoice_item_id.variant}
                                                onBlur={() => handleInvoiceItemBlur(invoiceItemsV[index].invoice_item_id, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                type={invoice_items.formField.description.type}
                                                label={invoice_items.formField.description.label + ' #' + (index + 1)}
                                                name={`${invoice_items.name}.${index}.${invoice_items.formField.description.name}`}
                                                value={invoiceItemsV[index].description}
                                                variant={invoice_items.formField.description.variant}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <FormField
                                                type={invoice_items.formField.unit_price.type}
                                                label={invoice_items.formField.unit_price.label + ' #' + (index + 1)}
                                                name={`${invoice_items.name}.${index}.${invoice_items.formField.unit_price.name}`}
                                                value={invoiceItemsV[index].unit_price}
                                                variant={invoice_items.formField.unit_price.variant}
                                                InputProps={{startAdornment: <InputAdornment disableTypography position="start">$</InputAdornment>}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <FormField
                                                type={invoice_items.formField.amount.type}
                                                label={invoice_items.formField.amount.label + ' #' + (index + 1)}
                                                name={`${invoice_items.name}.${index}.${invoice_items.formField.amount.name}`}
                                                value={invoiceItemsV[index].amount}
                                                variant={invoice_items.formField.amount.variant}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <MDButton
                                                type="button"
                                                color="error"
                                                variant="outlined"
                                                size="small"
                                                iconOnly={true}
                                                onClick={() => remove(index)}
                                            >
                                                <Icon>close</Icon>
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <MDBox display="flex" justifyContent="center" alignItems="center">
                                    <MDButton
                                        type="button"
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => push({invoice_item_id: -1, fob_point: '', price: ''})}
                                    >
                                        <Icon>add</Icon>
                                        Add Item
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        )}
                    </FieldArray>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditInvoiceForm;
