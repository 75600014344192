import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../config";
import {useParams} from 'react-router-dom';
import {capitalizeFirstLetter} from "../../../../helpers/helpers";
import initialValues from "./schemas/initialValues";

const useEditInvoice = () => {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);

    const [isLoading, setIsLoading] = useState(true);

    const [companies, setCompanies] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadRecord();
            await loadCompanies();
            await loadInvoiceItems();
            setIsLoading(false);
        };

        init();

    }, []);

    const loadRecord = async () => {

        try {

            const response = await axios.get(`${API_URL}/invoices/${id}`);

            let data = response.data;

            data.invoice_items = data.invoice_items.map(invoiceItem => ({
                invoice_item_id: invoiceItem.pivot.invoice_item_id,
                unit_price: invoiceItem.pivot.unit_price,
                amount: invoiceItem.pivot.amount,
                description: invoiceItem.pivot.description
            }));

            setValues(data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the invoice",
                icon: 'cancel',
                title: 'Invoices',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadCompanies = async () => {

        try {

            const response = await axios.get(`${API_URL}/companies`, {
                params: {
                    sort: 'company_name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setCompanies([{id: -1, label: "Choose an option"}, ...response.data.map(company => ({id: company.id, label: `${capitalizeFirstLetter(company.company_name)}`}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the companies",
                icon: 'cancel',
                title: 'Companies',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadInvoiceItems = async () => {

        try {

            const response = await axios.get(`${API_URL}/invoice_items`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setInvoiceItems([{id: -1, label: "Choose an option"}, ...response.data.map(invoiceItem => ({id: invoiceItem.id, price: invoiceItem.price, description: invoiceItem.description, label: `${capitalizeFirstLetter(invoiceItem.name)}`}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the items",
                icon: 'cancel',
                title: 'Items',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/invoices/${id}`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Invoice updated successfully',
                icon: 'notifications',
                title: 'Invoices',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Invoices',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        companies,
        invoiceItems,
        currentValidation,
        values,
        formId,
        formField,
        isLoading,
        show,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useEditInvoice;
