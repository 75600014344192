/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = (id) => {

    if(id){
        return {
            formId: "new-payment-form",
            formField: {
                payment_method: {
                    name: "payment_method",
                    label: "Payment Method",
                    type: "select",
                    options: [
                        {id: -1, label: 'Choose an option'},
                        {id: 1, label: 'Check'},
                        {id: 2, label: 'Cash'},
                        {id: 3, label: 'Credit Card'},
                        {id: 4, label: 'Debit Card'},
                        {id: 5, label: 'Electronic Transfer'},
                    ],
                    errorMsg: "Payment method is required.",
                },
                amount: {
                    name: "amount",
                    label: "Amount",
                    type: "number",
                    errorMsg: "Amount is required.",
                },
                paid_at: {
                    name: "paid_at",
                    label: "Paid At",
                    type: "date",
                    errorMsg: "Paid at is required.",
                }
            },
        }
    } else {
        return {
            formId: "new-payment-form",
            formField: {
                payment_method: {
                    name: "payment_method",
                    label: "Payment Method",
                    type: "select",
                    options: [
                        {id: -1, label: 'Choose an option'},
                        {id: 1, label: 'Check'},
                        {id: 2, label: 'Cash'},
                        {id: 3, label: 'Credit Card'},
                        {id: 4, label: 'Debit Card'},
                        {id: 5, label: 'Electronic Transfer'},
                    ],
                    errorMsg: "Payment method is required.",
                },
                amount: {
                    name: "amount",
                    label: "Amount",
                    type: "number",
                    errorMsg: "Amount is required.",
                },
                paid_at: {
                    name: "paid_at",
                    label: "Paid At",
                    type: "date",
                    errorMsg: "Paid at is required.",
                },
                invoice_id: {
                    name: "invoice_id",
                    label: "Invoice",
                    type: "select",
                    errorMsg: "Invoice is required.",
                    invalidMsg: "Invoice is required.",
                }
            },
        }
    }

};

export default form;
