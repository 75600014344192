/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState, useEffect, useMemo} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

// Layouts

// Material Dashboard 2 PRO React routes
import {salesRoutes, adminRoutes, recruitmentRoutes, installerRoutes} from "routes";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController, setMiniSidenav, setOpenConfigurator} from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";
import NewLead from "./layouts/pages/leads/new-lead";
import LeadDetails from "./layouts/pages/leads/details";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "./config";
import axios from "axios";
import {signIn} from "./store/actions/auth";
import Loading from "./layouts/loading";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import NewCity from "./layouts/pages/cities/new-city";
import EditCity from "./layouts/pages/cities/edit-city";
import NewVideo from "./layouts/pages/videos/new-video";
import EditVideo from "./layouts/pages/videos/edit-video";
import NewFile from "./layouts/pages/files/new-file";
import EditFile from "./layouts/pages/files/edit-file";
import EditBank from "./layouts/pages/bank";
import VideoDetails from "./layouts/pages/library/videos/video-details";
import FileDetails from "./layouts/pages/library/files/file-details";
import RecoverPassword from "./layouts/authentication/recover-password";
import ResetPassword from "./layouts/authentication/reset-password";
import NewMember from "./layouts/pages/team/members/new-member";
import NewAppointment from "./layouts/pages/appointments/new-appointment";
import EditAppointment from "./layouts/pages/appointments/edit-appointment";
import NewSale from "./layouts/pages/sales/new-sale";
import NewNotice from "./layouts/pages/notices/new-notice";
import EditNotice from "./layouts/pages/notices/edit-notice";
import SaleDetails from "./layouts/pages/sales/details";
import NewReference from "./layouts/pages/references/new-reference";
import EditLead from "./layouts/pages/leads/edit-lead";
import NewUserFromAdmin from "./layouts/pages/users/new-user-from-admin";
import EditUser from "./layouts/pages/users/edit-user";
import {accountingRoutes} from "./routes";
import NewCompany from "./layouts/pages/companies/new-company/NewCompany";
import EditCompany from "./layouts/pages/companies/edit-company/EditCompany";
import NewInvoiceItem from "./layouts/pages/invoice_items/new-invoice-item/NewInvoiceItem";
import EditInvoiceItem from "./layouts/pages/invoice_items/edit-invoice-item/EditInvoiceItem";
import NewInvoice from "./layouts/pages/invoices/new-invoice/NewInvoice";
import NewPayment from "./layouts/pages/payments/new-payment/NewPayment";
import InvoiceDetails from "./layouts/pages/invoices/details/InvoiceDetails";
import EditSale from "./layouts/pages/sales/edit-sale";
import EditAdders from "./layouts/pages/sales/edit_adders/EditAdders";
import EditInvoice from "./layouts/pages/invoices/edit-invoice/EditInvoice";
import NewCountry from "./layouts/pages/countries/new-country";
import EditCountry from "./layouts/pages/countries/edit-country";
import NewBank from "./layouts/pages/banks/new-bank";
import EditBnk from "./layouts/pages/banks/edit-bank";
import NewDeduction from "./layouts/pages/comission_deductions/new-deduction";

export default function App() {
    const [controller, materialDispatch] = useMaterialUIController();

    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const {pathname} = useLocation();

    const dispatch = useDispatch();

    const authState = useSelector((state) => state.authReducer);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const checkUser = async () => {
            setIsLoading(true);

            try {
                const response = await axios.get(`${API_URL}/user`);

                dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id));

            } catch (error) {
            }

            setIsLoading(false);
        };

        checkUser();

    }, []);

    // Cache for the rtl
    useMemo(() => {
      const cacheRtl = createCache({
        key: "rtl",
        stylisPlugins: [rtlPlugin],
      });

      setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(materialDispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(materialDispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    const getRoleRoutes = (roleId) => {
        switch (roleId) {
            case 1: return adminRoutes;
            case 2: return salesRoutes;
            case 3: return recruitmentRoutes;
            case 4: return accountingRoutes;
            case 5: return installerRoutes;
        }
    };

    return <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline/>
        {
            isLoading ?
                <Loading/> :
                <>
                    {
                        authState.isLoggedIn ?
                            <>
                                {layout === "dashboard" && (
                                    <>
                                        <Sidenav
                                            color={sidenavColor}
                                            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                            routes={getRoleRoutes(authState.roleId)}
                                            onMouseEnter={handleOnMouseEnter}
                                            onMouseLeave={handleOnMouseLeave}
                                        />
                                    </>
                                )}
                                {
                                    authState.roleId === 1 &&
                                    <Routes>
                                        {getRoutes(adminRoutes)}
                                        <Route exact path="/users/new-user" element={<NewUserFromAdmin />} key="new-user"/>
                                        <Route exact path="/users/:id/edit-user" element={<EditUser />} key="edit-user"/>
                                        <Route exact path="/invoices/new-invoice" element={<NewInvoice />} key="new-invoice"/>
                                        <Route exact path="/invoices/:id" element={<InvoiceDetails />} key="invoice-details"/>
                                        <Route exact path="/invoices/:id/edit-invoice" element={<EditInvoice />} key="edit-invoice"/>
                                        <Route exact path="/payments/new-payment" element={<NewPayment />} key="new-payment"/>
                                        <Route exact path="/invoice-items/new-invoice-item" element={<NewInvoiceItem />} key="new-invoice-item"/>
                                        <Route exact path="/invoice-items/:id/edit-invoice-item" element={<EditInvoiceItem />} key="edit-invoice-item"/>
                                        <Route exact path="/companies/new-company" element={<NewCompany />} key="new-company"/>
                                        <Route exact path="/companies/:id/edit-company" element={<EditCompany />} key="edit-company"/>
                                        <Route exact path="/cities/new-city" element={<NewCity />} key="new-city"/>
                                        <Route exact path="/cities/:id/edit-city" element={<EditCity />} key="edit-city"/>
                                        <Route exact path="/countries/new-country" element={<NewCountry />} key="new-country"/>
                                        <Route exact path="/countries/:id/edit-country" element={<EditCountry />} key="edit-country"/>
                                        <Route exact path="/banks/new-bank" element={<NewBank />} key="new-bank"/>
                                        <Route exact path="/banks/:id/edit-bank" element={<EditBnk />} key="edit-bank"/>
                                        <Route exact path="/leads/new-lead" element={<NewLead/>} key="new-lead"/>
                                        <Route exact path="/leads/:id" element={<LeadDetails/>} key="lead-details"/>
                                        <Route exact path="/leads/:id/edit-lead" element={<EditLead />} key="edit-lead"/>
                                        <Route exact path="/leads/:id/edit-lead" element={<EditLead />} key="edit-lead"/>
                                        <Route exact path="/appointments/new-appointment" element={<NewAppointment/>} key="new-appointment"/>
                                        <Route exact path="/appointments/:id/edit-appointment" element={<EditAppointment/>} key="edit-appointment"/>
                                        <Route exact path="/sales/:id" element={<SaleDetails/>} key="sale-details"/>
                                        <Route exact path="/sales/new-sale" element={<NewSale/>} key="new-sale"/>
                                        <Route exact path="/sales/:id/new-deduction" element={<NewDeduction/>} key="new-deduction"/>
                                        <Route exact path="/sales/:id/edit-sale" element={<EditSale />} key="edit-sale"/>
                                        <Route exact path="/sales/:id/edit-adders" element={<EditAdders />} key="edit-adders"/>
                                        <Route exact path="/notices/new-notice" element={<NewNotice />} key="new-notice"/>
                                        <Route exact path="/notices/:id/edit-notice" element={<EditNotice />} key="edit-notice"/>
                                        <Route exact path="/videos/new-video" element={<NewVideo />} key="new-video"/>
                                        <Route exact path="/videos/:id/edit-video" element={<EditVideo />} key="edit-video"/>
                                        <Route exact path="/files/new-file" element={<NewFile />} key="new-file"/>
                                        <Route exact path="/files/:id/edit-file" element={<EditFile />} key="edit-file"/>
                                        <Route path="*" element={<Navigate to="/dashboard"/>}/>
                                    </Routes>
                                }
                                {
                                    authState.roleId === 2 &&
                                    <Routes>
                                        {getRoutes(salesRoutes)}
                                        <Route exact path="/team/new-member" element={<NewMember/>} key="new-member"/>
                                        <Route exact path="/leads/new-lead" element={<NewLead/>} key="new-lead"/>
                                        <Route exact path="/leads/:id" element={<LeadDetails/>} key="lead-details"/>
                                        <Route exact path="/leads/:id/edit-lead" element={<EditLead />} key="edit-lead"/>
                                        <Route exact path="/sales/new-sale" element={<NewSale/>} key="new-sale"/>
                                        <Route exact path="/sales/:id" element={<SaleDetails/>} key="sale-details"/>
                                        <Route exact path="/sales/:id/edit-sale" element={<EditSale />} key="edit-sale"/>
                                        <Route exact path="/appointments/new-appointment" element={<NewAppointment/>} key="new-appointment"/>
                                        <Route exact path="/appointments/:id/edit-appointment" element={<EditAppointment/>} key="edit-appointment"/>
                                        <Route exact path="/profile/bank-account" element={<EditBank/>} key="bank-account"/>
                                        <Route exact path="/library/videos/:id" element={<VideoDetails/>} key="video-details"/>
                                        <Route exact path="/library/files/:id" element={<FileDetails/>} key="file-details"/>
                                        <Route path="*" element={<Navigate to="/dashboard"/>}/>
                                    </Routes>
                                }
                                {
                                    authState.roleId === 3 &&
                                    <Routes>
                                        {getRoutes(recruitmentRoutes)}
                                        <Route exact path="/references/new-reference" element={<NewReference/>} key="new-reference"/>
                                        <Route exact path="/profile/bank-account" element={<EditBank/>} key="bank-account"/>
                                        <Route exact path="/library/videos/:id" element={<VideoDetails/>} key="video-details"/>
                                        <Route exact path="/library/files/:id" element={<FileDetails/>} key="file-details"/>
                                        <Route path="*" element={<Navigate to="/profile"/>}/>
                                    </Routes>
                                }
                                {
                                    authState.roleId === 4 &&
                                    <Routes>
                                        {getRoutes(accountingRoutes)}
                                        <Route exact path="/sales/:id" element={<SaleDetails/>} key="sale-details"/>
                                        <Route path="*" element={<Navigate to="/sales"/>}/>
                                    </Routes>
                                }
                                {
                                    authState.roleId === 5 &&
                                    <Routes>
                                        {getRoutes(installerRoutes)}
                                        <Route exact path="/sales/:id" element={<SaleDetails/>} key="sale-details"/>
                                        <Route path="*" element={<Navigate to="/sales"/>}/>
                                    </Routes>
                                }
                            </> :
                            <Routes>
                                <Route exact path="/authentication/sign-in" element={<SignIn/>}
                                       key="sign-in"/>
                                <Route exact path="/authentication/sign-up" element={<SignUp/>}
                                       key="sign-up"/>
                                <Route exact path="/authentication/recover-password" element={<RecoverPassword/>}
                                       key="recover-password"/>
                                <Route exact path="/authentication/reset-password" element={<ResetPassword />}
                                       key="reset-password"/>
                                <Route path="*" element={<Navigate to="/authentication/sign-in"/>}/>
                            </Routes>
                    }
                </>
        }
    </ThemeProvider>
}
