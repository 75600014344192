import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {withStyles} from "tss-react/mui";
import Icon from "@mui/material/Icon";

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

const CustomToolbarSelect = ({classes, roleId, displayData, selectedRows, setSelectedRows, handleCancelClick, handleEditClick }) => {

    const renderActions = (roleId) => {
        if(roleId === 2 || roleId === 1) {

            return  <>
                <Tooltip title={"Edit"}>
                    <IconButton disabled={selectedRows.data.length !== 1 || displayData[selectedRows.data[0].index].data[6].props.label !== 'Draft'} className={classes.iconButton} onClick={() => handleEditClick(displayData[selectedRows.data[0].index].data[0])}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Cancel"}>
                    <IconButton disabled={selectedRows.data.length !== 1 || displayData[selectedRows.data[0].index].data[6].props.label !== 'Draft'} className={classes.iconButton} onClick={() => handleCancelClick(displayData[selectedRows.data[0].index].data[0])}>
                        <Icon>close</Icon>
                    </IconButton>
                </Tooltip>
            </>
        } else {
            return null;
        }
    };

    return (
        <div className={classes.iconContainer}>
            {
                displayData.length > 0 &&
                    renderActions(roleId)
            }
        </div>
    );
};

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, {name: "CustomToolbarSelect"});
