/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";
import React from "react";


function NewUserForm({formData, roles, users, cities, countries}) {

    const {formField, values, errors, touched} = formData;

    const {name, email, role_id, parent_id, city_id, country_id, password, password_confirmation} = formField;
    const {
        name: nameV,
        email: emailV,
        password: passwordV,
        password_confirmation: passwordConfirmationV,
        role_id: roleIdV,
        parent_id: parentIdV,
        city_id: cityIdV,
        country_id: countryIdV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        error={errors.email && touched.email}
                        success={emailV.length > 0 && !errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={role_id.type}
                        label={role_id.label}
                        name={role_id.name}
                        value={roleIdV}
                        options={roles}
                        error={errors.role_id && touched.role_id}
                        success={roleIdV && roleIdV.length > 0 && !errors.role_id}
                    />
                </Grid>
                {
                    (roleIdV == 2 || roleIdV == 3) &&
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={parent_id.type}
                            label={parent_id.label}
                            name={parent_id.name}
                            value={parentIdV}
                            options={users}
                            error={errors.parent_id && touched.parent_id}
                            success={parentIdV && parentIdV.length > 0 && !errors.parent_id}
                        />
                    </Grid>

                }
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={city_id.type}
                        label={city_id.label}
                        name={city_id.name}
                        value={cityIdV}
                        options={cities}
                        error={errors.city_id && touched.city_id}
                        success={cityIdV && cityIdV.length > 0 && !errors.city_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={country_id.type}
                        label={country_id.label}
                        name={country_id.name}
                        value={countryIdV}
                        options={countries}
                        error={errors.country_id && touched.country_id}
                        success={countryIdV && countryIdV.length > 0 && !errors.country_id}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormField
                        type={password.type}
                        label={password.label}
                        name={password.name}
                        value={passwordV}
                        error={errors.password && touched.password}
                        success={passwordV.length > 0 && !errors.password}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormField
                        type={password_confirmation.type}
                        label={password_confirmation.label}
                        name={password_confirmation.name}
                        value={passwordConfirmationV}
                        error={errors.password_confirmation && touched.password_confirmation}
                        success={passwordConfirmationV.length > 0 && !errors.password_confirmation}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewUserForm;
