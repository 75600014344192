/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";
import React from "react";


function EditAddersForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {adders_comission, adder_1_used, adder_2_used, adder_3_used, adder_4_used} = formField;
    const {
        adders_comission: addersComissionV,
        adder_1_used: adder1UsedV,
        adder_2_used: adder2UsedV,
        adder_3_used: adder3UsedV,
        adder_4_used: adder4UsedV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={adders_comission.type}
                        label={adders_comission.label}
                        name={adders_comission.name}
                        value={addersComissionV}
                        error={errors.adders_comission && touched.adders_comission}
                        success={addersComissionV && !errors.adders_comission}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={adder_1_used.type}
                        label={adder_1_used.label}
                        name={adder_1_used.name}
                        value={adder1UsedV}
                        error={errors.adder_1_used && touched.adder_1_used}
                        success={adder1UsedV && !errors.adder_1_used}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={adder_2_used.type}
                        label={adder_2_used.label}
                        name={adder_2_used.name}
                        value={adder2UsedV}
                        error={errors.adder_2_used && touched.adder_2_used}
                        success={adder2UsedV && !errors.adder_2_used}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={adder_3_used.type}
                        label={adder_3_used.label}
                        name={adder_3_used.name}
                        value={adder3UsedV}
                        error={errors.adder_3_used && touched.adder_3_used}
                        success={adder3UsedV && !errors.adder_3_used}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={adder_4_used.type}
                        label={adder_4_used.label}
                        name={adder_4_used.name}
                        value={adder4UsedV}
                        error={errors.adder_4_used && touched.adder_4_used}
                        success={adder4UsedV && !errors.adder_4_used}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditAddersForm;
