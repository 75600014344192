/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";
import {useState} from "react";


const AddersInfo = ({formData, invoiceItems}) => {

    const {formField, values, errors, touched} = formData;
    const {total_with_adders, adder_1, adder_2, adder_3, adder_4, invoice_item_id} = formField;
    const {
        adder_1: adder1V,
        adder_2: adder2V,
        adder_3: adder3V,
        adder_4: adder4V,
        total_with_adders: totalWithAddersV,
        invoice_item_id: invoiceItemIdV,
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Adders Information</MDTypography>
            <MDTypography variant="body2">Enter the optional adders information of the sale.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={total_with_adders.type}
                            label={total_with_adders.label}
                            name={total_with_adders.name}
                            value={totalWithAddersV}
                            error={errors.total_with_adders && touched.total_with_adders}
                            success={totalWithAddersV && totalWithAddersV.length > 0 && !errors.total_with_adders}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormField
                            type={adder_1.type}
                            label={adder_1.label}
                            name={adder_1.name}
                            value={adder1V}
                            error={errors.adder_1 && touched.adder_1}
                            success={adder1V && adder1V.length > 0 && !errors.adder_1}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormField
                            type={adder_2.type}
                            label={adder_2.label}
                            name={adder_2.name}
                            value={adder2V}
                            error={errors.adder_2 && touched.adder_2}
                            success={adder2V && adder2V.length > 0 && !errors.adder_2}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormField
                            type={adder_3.type}
                            label={adder_3.label}
                            name={adder_3.name}
                            value={adder3V}
                            error={errors.adder_3 && touched.adder_3}
                            success={adder3V && adder3V.length > 0 && !errors.adder_3}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={invoice_item_id.type}
                            label={invoice_item_id.label}
                            name={invoice_item_id.name}
                            value={invoiceItemIdV}
                            options={invoiceItems}
                            error={errors.invoice_item_id && touched.invoice_item_id}
                            success={invoiceItemIdV && invoiceItemIdV.length > 0 && !errors.invoice_item_id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={adder_4.type}
                            label={adder_4.label}
                            name={adder_4.name}
                            value={adder4V}
                            error={errors.adder_4 && touched.adder_4}
                            success={adder4V && adder4V.length > 0 && !errors.adder_4}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
};

export default AddersInfo;
