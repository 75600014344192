
import MUIDataTable from "mui-datatables";
import React from "react";
import useDataTable from "./useDataTable";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDSnackbar from "../../components/MDSnackbar";

const DataTable = ({endpoint, title, description, colData, sortDirection, sortBy, filters, actions, filter = true, search = true, loadAtStart = true, onSortChange = null}) => {

    const {isLoading, options, columns, data, show, snackbarConfig, renderTitle, renderIntro, toggleSnackbar} = useDataTable({
        endpoint,
        title,
        description,
        sortDirection,
        sortBy,
        colData,
        parentFilters: filters,
        actions,
        filter,
        canSearch: search,
        loadAtStart,
        onSortChange
    });

    return <>
        {
            isLoading ?
                <MDBox mt={2} mx={2}>
                    <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                </MDBox> :
                null
        }
        <MUIDataTable
        title={
            <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    {renderTitle()}
                </MDTypography>
                <MDTypography variant="button" color="text">
                    {renderIntro()}
                </MDTypography>
            </MDBox>
        }
        data={data}
        columns={columns}
        options={options}
    />
        <MDSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </>
};

export default DataTable;
