import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {withStyles} from "tss-react/mui";
import Icon from "@mui/material/Icon";

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

const CustomToolbarSelect = ({classes, displayData, selectedRows, setSelectedRows, canEdit = true, handleEditClick, handleStatusToggle}) => {

    const formatStatusData = (rows, data) => {

        let ids = [];

        for(let i = 0; i < rows.data.length; i++){
            ids.push(data[rows.data[i].index].data[0]);
        }

        return ids;
    };

    return (
        <div className={classes.iconContainer}>
            {
                canEdit &&
                <Tooltip title={"Edit Record"}>
                    <IconButton disabled={selectedRows.data.length !== 1} className={classes.iconButton} onClick={() => handleEditClick(displayData[selectedRows.data[0].index].data[0])}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title={"Toggle Status"}>
                <IconButton className={classes.iconButton} onClick={() => handleStatusToggle(formatStatusData(selectedRows, displayData))}>
                    <Icon>history_toggle</Icon>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, {name: "CustomToolbarSelect"});
