/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import React, {useEffect, useState} from "react";
import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validations from "./schemas/validations";
import MDTypography from "../../../../components/MDTypography";
import NewDeductionForm from "./components/NewDeductionForm";
import {API_URL} from "../../../../config";
import axios from 'axios';
import BaseLayout from "../../../../components/BaseLayout";
import {useParams} from 'react-router-dom';

// Settings page components

function NewDeduction() {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [isLoading, setIsLoading] = useState(true);
    const [sellers, setSellers] = useState(null);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            await loadSale();
            setIsLoading(false);
        };

        init();
    }, []);

    const loadSale = async () => {
        try {

            const response = await axios.get(`${API_URL}/sales/${id}`);

            let sellersData = [{id: -1, label: 'Choose an option'}, {id: response.data.lead.user_id, label: response.data.lead.user.name}];

            if(response.data.user_id !== null){
                sellersData.push({id: response.data.user_id, label: response.data.secondary_user.name});
            }

            setSellers(sellersData);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the sale's details",
                icon: 'cancel',
                title: 'Sales',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const handleSubmit = async (values, actions) => {

        try {

            let data = {...values};
            data.sale_id = id;

            // Enviar datos a API
            await axios.post(
                `${API_URL}/sales/deductions`,
                data
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Deductions saved successfully',
                icon: 'notifications',
                title: 'Deductions',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Deductions',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return (
        <BaseLayout>
            {/*<MDBox mt={4}>*/}
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <MDBox mb={3}>
                            <Card id="new-deduction" sx={{overflow: "visible"}}>
                                {
                                    isLoading ?
                                        <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                               alignItems="center">
                                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                        </MDBox> :
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={currentValidation}
                                            onSubmit={handleSubmit}
                                        >
                                            {({values, errors, touched, isSubmitting}) => (
                                                <Form id={formId} autoComplete="off">
                                                    <MDBox p={3}>
                                                        <MDTypography variant="h5">New Deduction</MDTypography>
                                                        <MDTypography variant="body2">Enter the new comission deduction
                                                            details.</MDTypography>
                                                    </MDBox>
                                                    <MDBox pb={3} px={3}>
                                                        <NewDeductionForm
                                                            formData={{values, touched, formField, errors}}
                                                            sellers={sellers}/>
                                                        <MDBox mt={2} width="100%" display="flex"
                                                               justifyContent="flex-end">
                                                            <MDBox>
                                                                <MDButton
                                                                    disabled={isSubmitting}
                                                                    variant="gradient"
                                                                    color="secondary"
                                                                    type="submit"
                                                                >
                                                                    {isSubmitting &&
                                                                    <CircularProgress size={18} color="white"
                                                                                      sx={{marginRight: 1}}/>}
                                                                    Create
                                                                </MDButton>
                                                            </MDBox>
                                                        </MDBox>
                                                    </MDBox>

                                                </Form>
                                            )}
                                        </Formik>
                                }
                            </Card>
                        </MDBox>
                    </Grid>
                </Grid>
            {/*</MDBox>*/}
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </BaseLayout>
    );
}

export default NewDeduction;
