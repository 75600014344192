import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from 'react-router-dom';
import {API_URL} from "../../../../config";
import {capitalizeFirstLetter} from "../../../../helpers/helpers";

const useNewPayment = () => {

    const { id } = useParams();

    const {formId, formField} = form(id);
    const currentValidation = validations(id);

    const [isLoading, setIsLoading] = useState(true);

    const [values, setValues] = useState(initialValues);

    const [invoices, setInvoices] = useState([]);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);

            if(!id){
                await loadInvoices();
            }

            setIsLoading(false);
        };

        init();

    }, []);

    const loadInvoices = async () => {

        try {

            const response = await axios.get(`${API_URL}/invoices`, {
                params: {
                    sort: 'id',
                    direction: 'desc',
                    filters: {status:["Open"]}
                }
            });

            setInvoices([{id: -1, label: "Choose an option"}, ...response.data.map(invoice => ({id: invoice.id, pending: invoice.pending, label: `[${invoice.folio}] ${capitalizeFirstLetter(invoice.company.company_name)}`}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the invoices",
                icon: 'cancel',
                title: 'Invoices',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleSubmit = async (values, actions) => {

        try {

            let data = {...values};

            if(id){
                data.invoice_id = id;
            }

            // Enviar datos a API
            await axios.post(
                `${API_URL}/payments`,
                data
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Payment saved successfully',
                icon: 'notifications',
                title: 'Payments',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Payments',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        id,
        invoices,
        currentValidation,
        values,
        formId,
        formField,
        isLoading,
        show,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useNewPayment;
