/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import {ErrorMessage, Field, FieldArray, useFormikContext} from "formik";
import {Icon} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "../../../../../../components/MDBox";
import FormField from "../../../../../../components/FormField";
import MDTypography from "../../../../../../components/MDTypography";
import colors from "../../../../../../assets/theme/base/colors";
import MDButton from "../../../../../../components/MDButton";

// Material Dashboard 2 PRO React components


function NewEventForm({formData, companies, invoiceItems}) {

    const {formField, values, errors, touched} = formData;

    const {description} = formField;
    const {
        description: descriptionV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={description.type}
                        label={description.label}
                        name={description.name}
                        value={descriptionV}
                        error={errors.description && touched.description}
                        success={descriptionV.length > 0 && !errors.description}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewEventForm;
