/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useEffect, useMemo, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";
import Header from "./components/Header";
import NextEvents from "./components/NextEvents";
import moment from "moment";
import {API_URL} from "../../../../config";
import axios from "axios";
import MDSnackbar from "../../../../components/MDSnackbar";
import AppointmentDetails from "./components/AppointmentDetails";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "../../../../components/MDButton";
import TextField from "@mui/material/TextField";
import initialValues from "../../cities/new-city/schemas/initialValues";
import {Form, Formik} from "formik";
import RescheduleDialog from "./components/RescheduleDialog";
import {useSelector} from "react-redux";

// Calendar application components

// Data

const Calendar = () => {

    const authState = useSelector((state) => state.authReducer);

    const [isCalendarLoading, setIsCalendarLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [calendarData, setCalendarData] = useState([]);

    const [upcomingData, setUpcomingData] = useState([]);

    const [selectedAppointment, setSelectedAppointment] = useState(null);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [open, setOpen] = useState(false);

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        authState.roleId !== 1 &&
        getUpcomingAppointmentsData(`${API_URL}/appointments/upcoming`);

    }, []);

    const getUpcomingAppointmentsData = async (url) => {

        // setIsSubmitting(true);

        const response = await loadUpcomingData(url);

        setUpcomingData(response.data);
        // setIsSubmitting(false);
    };

    const updateAppointment = async (url, data) => {

        const response = await axios.put(url, data);

        return {
            data: response.data
        };

    };

    const loadCalendarData = async (url, from, to) => {

        const options = {
            params: {
                from,
                to
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data
        };

    };

    const loadUpcomingData = async (url) => {

        const response = await axios.get(url);

        return {
            data: response.data
        };

    };

    const getEventClass = (status) => {
        switch (status) {
            case 'pending':
                return 'primary';
            case 'cancelled':
                return 'warning';
            case 'done':
                return 'secondary';
        }
    };

    const formatCalendarData = (calendarData) => {
        return calendarData.map(event => ({
            id: event.id,
            title: `Meeting with ${event.lead.name}`,
            start: event.due_at,
            notes: event.notes,
            email: event.lead.email,
            phone: event.lead.phone,
            address: event.lead.address,
            status: event.status,
            className: getEventClass(event.status)
        }))
    };

    const onRescheduleSubmit = async (values, actions) => {

        let newValues = {...values};

        setIsSubmitting(true);
        setIsCalendarLoading(true);

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/appointments/${selectedAppointment.id}`,
                {...newValues}
            );

            let newCalendarData = [...calendarData];

            const appointmentIndex = newCalendarData.findIndex((appointment) => appointment.id === selectedAppointment.id);

            newCalendarData[appointmentIndex].start = newValues.due_at;

            setCalendarData(newCalendarData);

            handleDialogClose();

            actions.resetForm();

            setSnackbarConfig({
                message: 'Appointment updated successfully',
                icon: 'notifications',
                title: 'Appointments',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'notifications',
                title: 'Appointments',
                color: 'warning'
            });

            setShow(true);
        }

        actions.setSubmitting(false);

        setIsSubmitting(false);
        setIsCalendarLoading(false);

    };

    const handleDateChange = async (dateInfo) => {

        const startMoment = moment(dateInfo.start);
        const endMoment = moment(dateInfo.end);

        setIsCalendarLoading(true);

        const response = await loadCalendarData(`${API_URL}/appointments`, startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD'));

        setIsCalendarLoading(false);

        setCalendarData(formatCalendarData(response.data));

    };

    const handleEventClick = (eventInfo) => {

        let event = calendarData.find(appointment => appointment.id == eventInfo.event.id);

        if (event)
            setSelectedAppointment(event);
    };

    const handleCancel = async (id) => {
        setIsSubmitting(true);
        setIsCalendarLoading(true);

        const response = await updateAppointment(`${API_URL}/appointments/${id}`, {status: 'cancelled'});

        let newCalendarData = [...calendarData];

        const appointmentIndex = newCalendarData.findIndex((appointment) => appointment.id === id);

        newCalendarData[appointmentIndex].status = 'cancelled';
        newCalendarData[appointmentIndex].className = 'warning';

        setCalendarData(newCalendarData);

        setIsSubmitting(false);
        setIsCalendarLoading(false);

        // Notification state
        setSnackbarConfig({
            message: 'The appointment has been cancelled successfully.',
            icon: 'notifications',
            title: 'Appointments',
            color: 'secondary'
        });

        setShow(true);
    };

    const handleReschedule = async (id) => {
        handleDialogOpen();
    };

    const handleDone = async (id) => {

        setIsSubmitting(true);
        setIsCalendarLoading(true);

        await updateAppointment(`${API_URL}/appointments/${id}`, {status: 'done'});

        let newCalendarData = [...calendarData];

        const appointmentIndex = newCalendarData.findIndex((appointment) => appointment.id === id);

        newCalendarData[appointmentIndex].status = 'done';
        newCalendarData[appointmentIndex].className = 'secondary';

        setCalendarData(newCalendarData);

        setIsSubmitting(false);
        setIsCalendarLoading(false);

        // Notification state
        setSnackbarConfig({
            message: 'The appointment has been updated successfully.',
            icon: 'notifications',
            title: 'Appointments',
            color: 'secondary'
        });

        setShow(true);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>

            <MDBox pt={2}>
                {
                    authState.roleId !== 1 &&
                    <MDBox display="flex" justifyContent="flex-end" mt={1} mb={4} mx={2}>
                        <Header/>
                    </MDBox>
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} xl={9}>
                        {useMemo(
                            () => (
                                <EventCalendar
                                    isLoading={isCalendarLoading}
                                    initialView="dayGridMonth"
                                    initialDate={moment().format('YYYY-MM-DD')}
                                    datesSet={handleDateChange}
                                    events={calendarData}
                                    eventClick={handleEventClick}
                                    selectable
                                    editable
                                />
                            ),
                            [calendarData, isCalendarLoading]
                        )}
                    </Grid>

                        <Grid item xs={12} xl={3}>
                            {
                                selectedAppointment !== null &&
                                <MDBox mb={3}>
                                    <AppointmentDetails data={selectedAppointment}
                                                        isSubmitting={isSubmitting}
                                                        onCancelClick={() => handleCancel(selectedAppointment.id)}
                                                        onDoneClick={() => handleDone(selectedAppointment.id)}
                                                        onRescheduleClick={() => handleReschedule(selectedAppointment.id)}
                                    />
                                </MDBox>
                            }
                            {
                                authState.roleId !== 1 &&
                                <MDBox mb={3}>
                                    <NextEvents data={upcomingData}/>
                                </MDBox>
                            }
                        </Grid>

                </Grid>
            </MDBox>
            <Footer/>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
            <RescheduleDialog open={open} onClose={handleDialogClose} onSubmit={onRescheduleSubmit}/>
        </DashboardLayout>
    );
}

export default Calendar;
