/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../../../../../../components/FormField";


function AdditionalInfo({ formData }) {

    const {formField, values, errors, touched} = formData;
    const {credit_score, moved_in_at, is_home_owner} = formField;
    const {
        credit_score: credit_scoreV,
        moved_in_at: moved_in_atV,
        is_home_owner: is_home_ownerV,
    } = values;

    return (
        <MDBox>
            <MDTypography variant="h5">Additional Information</MDTypography>
            <MDTypography variant="body2">Almost there, lets add the required additional information.</MDTypography>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={credit_score.type}
                            label={credit_score.label}
                            name={credit_score.name}
                            value={credit_scoreV}
                            placeholder={credit_score.placeholder}
                            error={errors.credit_score && touched.credit_score}
                            success={credit_scoreV && !errors.credit_score}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={is_home_owner.type}
                            label={is_home_owner.label}
                            name={is_home_owner.name}
                            value={is_home_ownerV}
                            error={errors.is_home_owner && touched.is_home_owner}
                            success={is_home_ownerV && !errors.is_home_owner}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default AdditionalInfo;
