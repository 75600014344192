/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "../../../../../components/MDButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {API_URL} from "../../../../../config";
import MDSnackbar from "../../../../../components/MDSnackbar";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

function BankDetails() {
    const actionButtonStyles = {
        "& .material-icons-round": {
            transform: `translateX(0)`,
            transition: "all 200ms cubic-bezier(0.34,1.61,0.7,1.3)",
        },

        "&:hover .material-icons-round, &:focus .material-icons-round": {
            transform: `translateX(4px)`,
        },
    };

    const [bankAccount, setBankAccount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            await getBankAccountData(`${API_URL}/users/bank_account`);
        };

        init();
    }, []);

    const getBankAccountData = async (url) => {

        setIsLoading(true);

        try {

            const response = await axios.get(url);

            setBankAccount(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the bank account details",
                icon: 'cancel',
                title: 'Bank Account',
                color: 'warning'
            });

            setShow(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    return (
        <>
            <Card id="bank-account">
                {
                    isLoading ?
                        <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                        </MDBox> :
                        <>
                            <MDBox p={3} lineHeight={1}>
                                <MDBox mb={1}>
                                    <MDTypography variant="h5">Payout Account Details</MDTypography>
                                </MDBox>
                                {
                                    !!bankAccount ?
                                        <MDTypography variant="button" color="text" fontWeight="regular">
                                            This is the bank account to which all of your earnings will be deposited.
                                        </MDTypography> :
                                        <MDBox
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width={{xs: "max-content", sm: "100%"}}
                                        >
                                            <MDTypography variant="button" color="text" fontWeight="regular">
                                                Add your bank account details to be able to receive your payouts on time.
                                            </MDTypography>
                                            <MDBox display="flex" alignItems="center">
                                                <NavLink to="/profile/bank-account" key={'bank'}>
                                                    <MDButton variant="contained" color="secondary">Add bank account</MDButton>
                                                </NavLink>
                                            </MDBox>
                                        </MDBox>
                                }
                            </MDBox>
                            {
                                bankAccount &&
                                <MDBox pb={3} px={3} sx={{overflow: "auto"}}>
                                    <MDBox
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width={{xs: "max-content", sm: "100%"}}
                                    >
                                        <MDBox display="flex" alignItems="center">
                                            <MDBox textAlign="center" color="text" px={{xs: 0, md: 1.5}} opacity={0.6}>
                                                <Icon fontSize="default">account_balance</Icon>
                                            </MDBox>
                                            <MDBox height="100%" ml={2} lineHeight={1} mr={2}>
                                                <MDTypography display="block" variant="button" fontWeight="regular"
                                                              color="text">
                                                    {bankAccount.bank}
                                                </MDTypography>
                                                <MDTypography variant="caption" color="text">
                                                    {bankAccount.account_number}
                                                </MDTypography>
                                            </MDBox>
                                        </MDBox>
                                        <MDBox display="flex" alignItems="center">
                                            <NavLink to="/profile/bank-account" key={'bank'}>
                                                <MDButton variant="text" color="secondary">Edit</MDButton>
                                            </NavLink>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            }
                        </>
                }

            </Card>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </>
    );
}

export default BankDetails;
