/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "../../../../../../components/FormField";


function NewDeductionForm({formData, sellers}) {

    const {formField, values, errors, touched} = formData;

    const {motive, amount, seller_id} = formField;
    const {
        motive: motiveV,
        amount: amountV,
        seller_id: sellerIdV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={seller_id.type}
                        label={seller_id.label}
                        name={seller_id.name}
                        value={sellerIdV}
                        options={sellers}
                        error={errors.seller_id && touched.seller_id}
                        success={sellerIdV.length > 0 && !errors.seller_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={motive.type}
                        label={motive.label}
                        name={motive.name}
                        value={motiveV}
                        error={errors.motive && touched.motive}
                        success={motiveV.length > 0 && !errors.motive}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={amount.type}
                        label={amount.label}
                        name={amount.name}
                        value={amountV}
                        error={errors.amount && touched.amount}
                        success={amountV.length > 0 && !errors.amount}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewDeductionForm;
