/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// @mui icons
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// Images
import Header from "./components/Header";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../../config";
import DefaultItem from "../../../../examples/Items/DefaultItem";
import moment from "moment";
import {useParams} from 'react-router-dom';
import MDSnackbar from "../../../../components/MDSnackbar";
import {Form, Formik} from "formik";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import NewEventForm from "../../sales/details/components/NewEventForm/NewEventForm";
import MDButton from "../../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import form from "./schemas/form";
import MDAvatar from "../../../../components/MDAvatar";
import {renderAvatarInitials} from "../../../../helpers/helpers";

const EventItem = ({user, content, date}) => {

    return <MDBox>
        <MDBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <MDAvatar bgColor="primary" alt="profile-image" size="md"
                      shadow="sm">{renderAvatarInitials(user)}</MDAvatar>
            <MDBox ml={2}>
                <MDTypography variant="h6" mb={-1}>{user}</MDTypography>
                <MDTypography variant="button" fontWeight="regular">{date.fromNow()}</MDTypography>
            </MDBox>
        </MDBox>
        <MDTypography variant="button" ml={8}>{content}</MDTypography>
        <Divider/>
    </MDBox>
};

function LeadDetails() {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [events, setEvents] = useState([]);

    const init = async () => {
        setIsLoading(true);
        await getProfileData(`${API_URL}/leads/${id}`);
        await getLeadEvents(`${API_URL}/events`);
        setIsLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    const loadProfileData = async (url) => {

        const response = await axios.get(url);

        return {
            data: response.data
        };

    };

    const getProfileData = async (url) => {

        const response = await loadProfileData(url);

        setProfile(response.data);

    };

    const handleRecruitInvite = async () => {

        setIsSubmitting(true);

        try {
            // Enviar datos a API
            await axios.post(
                `${API_URL}/recruiters`,
                {
                    lead_id: profile.id
                }
            );

            setSnackbarConfig({
                message: 'Invite sent successfully',
                icon: 'notifications',
                title: 'Recruiters',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'notifications',
                title: 'Recruiters',
                color: 'warning'
            });

            setShow(true);
        }

        setIsSubmitting(false);
    };

    const getLeadEvents = async (url) => {


        const response = await axios.get(url, {
            params: {
                id: id,
                type: 'lead'
            }
        });

        setEvents(response.data);

    };

    const handleEventSubmit = async (values, actions) => {

        try {

            let newValues = {...values};
            newValues.type = 'lead';
            newValues.id = id;

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/events`,
                newValues
            );

            actions.resetForm();

            let newEvents = [...events];
            newEvents.unshift(response.data);
            setEvents(newEvents);

        } catch (error) {
            console.log(error);

        }

        actions.setSubmitting(false);

    };

    const renderEvents = () => {
        return events.map(event => <EventItem user={event.user ? event.user.name : 'System'} content={event.description}
                                              date={moment(event.created_at)}/>)
    };

    const renderAppointments = (data) => {
        return data.map((appointment, index) => (
            <MDBox mb={index !== data.length - 1 ? 3.5 : 0} key={appointment.id}>
                <DefaultItem
                    color="primary"
                    icon="event"
                    title={`Meeting with ${profile.name}`}
                    description={moment(appointment.due_at, 'YYYY-MM-DD HH:mm:ss').format('MMM D [at] HH:mm A')}
                />
            </MDBox>
        ));
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            {
                isLoading !== true &&
                <Header name={profile.name} createdAt={moment(profile.created_at).format('MMM DD, YYYY')} status={profile.status} handleRecruitInvite={handleRecruitInvite} submitting={isSubmitting}>
                    <MDBox mt={0} mb={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} xl={4}>
                                <ProfileInfoCard
                                    title="Contact information"
                                    info={[
                                        {
                                            label: 'Name',
                                            value: profile.name
                                        },
                                        {
                                            label: 'Phone',
                                            value: profile.phone
                                        },
                                        {
                                            label: 'Email',
                                            value: profile.email
                                        },
                                        {
                                            label: 'Address',
                                            value: profile.address
                                        },
                                        {
                                            label: 'Credit score',
                                            value: profile.credit_score
                                        }
                                    ]}
                                    shadow={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} sx={{display: "flex"}}>
                                <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                                <ProfileInfoCard
                                    title="Property information"
                                    info={[
                                        {
                                            label: 'Power Company',
                                            value: profile.power_company
                                        },
                                        {
                                            label: 'Highest Bill',
                                            value: profile.highest_monthly_bill && `$${profile.highest_monthly_bill} USD`
                                        },
                                        {
                                            label: 'Lowest Bill',
                                            value: profile.lowest_monthly_bill && `$${profile.lowest_monthly_bill} USD`
                                        },
                                        {
                                            label: 'Years Living in Property',
                                            value: profile.years_in_property && (profile.years_in_property + ' years')
                                        },
                                        {
                                            label: 'Is home owner?',
                                            value: profile.is_home_owner ? 'Yes' : 'No'
                                        },
                                    ]}
                                    shadow={false}
                                />
                            </Grid>
                            <Grid item xs={12} xl={4} sx={{display: "flex"}}>
                                <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                                <MDBox>
                                    <MDBox pt={2} px={2}>
                                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                            Latest Appointments
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox p={2}>
                                        {
                                            renderAppointments(profile.appointments)
                                        }
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Header>
            }
            <MDBox sx={{
                position: "relative",
                mt: 0,
                mx: 3,
                mb: 5,
                py: 0,
                px: 2,
            }}>
                <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h5" mb={2}>Timeline</MDTypography>
                </MDBox>
                <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                    onSubmit={handleEventSubmit}
                >
                    {({values, errors, touched, isSubmitting}) => (
                        <Form id={formId} autoComplete="off">
                            <MDBox>
                                <NewEventForm formData={{values, touched, formField, errors}} />
                                <MDBox mt={2} width="100%" display="flex"
                                       justifyContent="flex-end">
                                    <MDBox>
                                        <MDButton
                                            disabled={isSubmitting}
                                            variant="gradient"
                                            color="secondary"
                                            type="submit"
                                        >
                                            {isSubmitting &&
                                            <CircularProgress size={18} color="white"
                                                              sx={{marginRight: 1}}/>}
                                            Add Note
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </MDBox>

                        </Form>
                    )}
                </Formik>
                {
                    renderEvents()
                }
            </MDBox>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
            <Footer/>
        </DashboardLayout>
    );
}

export default LeadDetails;
