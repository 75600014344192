/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Images
// Material Dashboard 2 PRO React context
import React from "react";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Material Dashboard 2 PRO React base styles
import useInvoiceDetails from "./useInvoiceDetails";
import BaseLayout from "../../../../components/BaseLayout";
import Overview from "./components/Overview/Overview";
import Payments from "./components/Payments/Payments";

const InvoiceDetails = () => {

    const {invoice, tabValue, tabsOrientation, isLoading, snackbarConfig, show, toggleSnackbar, handleSetTabValue} = useInvoiceDetails();

    return (
        <BaseLayout stickyNavbar>
            <Grid container>
                <Grid item xs={12} sm={8} lg={4}>
                    <AppBar position="static">
                        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                            <Tab label="Overview"/>
                            <Tab label="Payments"/>
                        </Tabs>
                    </AppBar>
                </Grid>
            </Grid>
            <MDBox mt={{xs: 4, md: 4}} mb={{xs: 4, md: 4}}>
                {
                    isLoading ?
                        <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                               alignItems="center">
                            <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                        </MDBox> :
                        <MDBox>
                            {
                                tabValue === 0 &&
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10} md={10}>
                                        <Overview invoice={invoice}/>
                                    </Grid>
                                </Grid>
                            }
                            {
                                tabValue === 1 &&
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10} md={10}>
                                        <Payments />
                                    </Grid>
                                </Grid>
                            }
                        </MDBox>
                }
            </MDBox>
        </BaseLayout>
    );
};

export default InvoiceDetails;
