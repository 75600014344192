import React from "react";
import Card from "@mui/material/Card";
import useInvoices from "./useInvoices";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Icon from "@mui/material/Icon";
import {NavLink} from "react-router-dom";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import Footer from "../../../../examples/Footer";
import {API_URL} from "../../../../config";
import DataTable from "../../../../examples/DataTable/DataTable";

const Invoices = () => {

    const {actions, filters, setFilters, renderStatusChip, renderType} = useInvoices();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        <DataTable
                            endpoint={`${API_URL}/invoices`}
                            title="Invoices"
                            description="View and manage the invoices data."
                            sortBy="id"
                            sortDirection="desc"
                            filters={filters}
                            actions={actions}
                            colData={[
                                {
                                    name: 'id',
                                    label: 'ID',
                                    options: {
                                        display: 'excluded'
                                    }
                                },
                                {
                                    name: 'folio',
                                    label: 'Folio',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <NavLink to={`/invoices/${tableMeta.rowData[0]}`}
                                                     key={`invoice-${tableMeta.rowData[0]}`}
                                                     style={{fontWeight: 500}}
                                            >
                                                {value}
                                            </NavLink>
                                        )
                                    }
                                },
                                {
                                    name: 'company.company_name',
                                    label: 'Company',
                                },
                                {
                                    name: 'client_name',
                                    label: 'Client',
                                },
                                {
                                    name: 'type',
                                    label: 'Type',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return renderType(value);
                                        },
                                        filterType: 'dropdown',
                                        filterOptions: {
                                            names: [1, 2, 3],
                                            renderValue: value => {
                                                switch(value){
                                                    case 1: return 'Type A';
                                                    case 2: return 'Type B';
                                                    case 3: return 'Type C';
                                                }
                                            }
                                        },
                                        customFilterListOptions: {
                                            render: value => {
                                                switch(value){
                                                    case 1: return 'Type A';
                                                    case 2: return 'Type B';
                                                    case 3: return 'Type C';
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    name: 'total',
                                    label: 'Total',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return <Chip icon={<Icon style={{marginLeft: 8}}>paid</Icon>} size="small" color="secondary" label={`$${value}`}
                                                         variant="outlined"/>
                                        },
                                        filter: false
                                    }
                                },
                                {
                                    name: 'pending',
                                    label: 'Pending',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return <Chip icon={<Icon style={{marginLeft: 8}}>money_off</Icon>} size="small" color="warning" label={`$${value}`}
                                                         variant="outlined"/>
                                        },
                                        filter: false
                                    }
                                },
                                {
                                    name: 'loan_id',
                                    label: 'Loan ID',
                                    options: {
                                        display: 'hidden',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'fob_point',
                                    label: 'FOB Point',
                                    options: {
                                        display: 'hidden',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'requisitioner',
                                    label: 'Requisitioner',
                                    options: {
                                        display: 'hidden',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'sales_person',
                                    label: 'Sales Person',
                                    options: {
                                        display: 'hidden'
                                    }
                                },
                                {
                                    name: 'terms',
                                    label: 'Terms',
                                    options: {
                                        display: 'hidden',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'due_at',
                                    label: 'Due At',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => moment(value).format('YYYY-MM-DD'),
                                        filter: true,
                                        filterType: 'custom',
                                        customFilterListOptions: {
                                            render: v => {
                                                if (v[0] && v[1]) {
                                                    return `From: ${v[0]}, To: ${v[1]}`;
                                                } else if (v[0]) {
                                                    return `From: ${v[0]}`;
                                                } else if (v[1]) {
                                                    return `To: ${v[1]}`;
                                                }
                                                return false;
                                            },
                                            update: (filterList, filterPos, index) => {

                                                if (filterPos === 0) {
                                                    filterList[index].splice(filterPos, 1, '');
                                                } else if (filterPos === 1) {
                                                    filterList[index].splice(filterPos, 1);
                                                } else if (filterPos === -1) {
                                                    filterList[index] = [];
                                                }

                                                let newFilters = () => (filterList);
                                                setFilters(newFilters);

                                                // return filterList;
                                            },
                                        },
                                        filterOptions: {
                                            names: [],
                                            logic(date, filters) {
                                                if (filters[0] && filters[1]) {
                                                    return date < filters[0] || date > filters[1];
                                                } else if (filters[0]) {
                                                    return date < filters[0];
                                                } else if (filters[1]) {
                                                    return date > filters[1];
                                                }
                                                return false;
                                            },
                                            display: (filterList, onChange, index, column) => (
                                                <div>
                                                    <MDTypography variant="button"
                                                                  fontWeight="regular">Due At</MDTypography>
                                                    <FormGroup row style={{marginTop: 10}}>
                                                        <MDInput
                                                            label="From"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][0] || ''}
                                                            onChange={event => {
                                                                filterList[index][0] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '45%', marginRight: '5%'}}
                                                        />
                                                        <MDInput
                                                            label="To"
                                                            type="date"
                                                            variant="standard"
                                                            value={filterList[index][1] || ''}
                                                            onChange={event => {
                                                                filterList[index][1] = event.target.value;
                                                                onChange(filterList[index], index, column);
                                                            }}
                                                            style={{width: '50%'}}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ),
                                        },
                                    },
                                },
                                {
                                    name: 'status',
                                    label: 'Status',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return renderStatusChip(value)
                                        }
                                    }
                                }
                            ]}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
};

export default Invoices;
